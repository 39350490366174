import React, {FC} from "react";
import SectionListItem from "../SectionListItem/SectionListItem";
import {useNavigate, useParams} from "react-router-dom";
import ModuleListItem from "../ModuleListItem/ModuleListItem";
import {ISection} from "../../../../../types/ISection";
import {IAcademy} from "../../../../../types/IAcademy";
import {IModule} from "../../../../../types/IModule";
import {Draggable, Droppable} from "react-beautiful-dnd";
import InfoIcon from "@mui/icons-material/Info";

interface IProps {
	index: number,
	section: ISection,
	academy: IAcademy,
	modules: IModule[],
	onEditAction: () => void,
	onDeleteAction: () => void,
	onDeleteModuleAction: (e: IModule) => void,
	onChange: (e: ISection) => void
}

export const CurriculumSection: FC<IProps> = ({index, section, academy, modules, onEditAction, onDeleteAction, onDeleteModuleAction, onChange}) => {
	const navigate = useNavigate();
	const {slug} = useParams();

	return (
		<Draggable draggableId={"drag_" + section.slug} key={"drag_" + section.slug} index={index}>
			{provided => (
				<section className="container_section" ref={provided.innerRef} {...provided.draggableProps}>
					<SectionListItem
						dragHandleProps={provided.dragHandleProps}
						section={section}
						onEditAction={onEditAction}
						onDeleteAction={onDeleteAction}
						onChange={onChange}
					/>
					<Droppable droppableId={section.slug} type={"droppableModule"}>
						{provided => (
							<div className="modules_list" ref={provided.innerRef} {...provided.droppableProps}>
								{section && modules?.map((module, i) =>
									<ModuleListItem
										index={i}
										key={i}
										module={module}
										academy={academy}
										section={section}
										onDeleteAction={() => onDeleteModuleAction(module)}
									/>
								)}
								{provided.placeholder}
								{section && modules?.length === 0 && (
									<div className="curriculum-no-section-modules">
										<InfoIcon /> Use the buttons below or drag here to add modules and webinars
									</div>
								)}
							</div>)}
					</Droppable>

					<div className="button_wrapper" style={{marginBottom: 30}}>
						<button
							className="module_button"
							onClick={() => navigate(`/module/${slug}/${section.slug}`)}
						>
						+ Module
						</button>

						<button
							className="webinar_button"
							onClick={() => navigate(`/webinar/${slug}/${section.slug}`)}
						>
						+ Webinar
						</button>
					</div>
				</section>
			)}
		</Draggable>
	);
}

export default CurriculumSection;
