import React, {FC, useContext} from "react";
import ConfirmationPopUp from "../../components/ConfirmationPopUp/ConfirmationPopUp";
import {NotifyContext} from "../../context/NotifyContext";
import Modal from "../../Modal";
import coreService from "../../services/core.service";
import {IAcademy} from "../../types/IAcademy";
import {Severity} from "../../types/INotify";

interface IProps {
	open: boolean,
	onClose: () => void,
	collection: any, //TODO type
	academy: IAcademy, //TODO type
	onChange: (academy: IAcademy) => void
}

export const DeleteAcademyModal: FC<IProps> = ({open, onClose, academy, onChange}) => {
	const {notify} = useContext(NotifyContext);

	const handleAcademyDelete = async (): Promise<void> => {
		await coreService
			.deleteAcademy(academy.slug, {})
			.then(() => {
				onChange(academy);
				onClose();
				notify(`${academy.name} deleted.`, "Delete Academy");
			})
			.catch((err) => {
				notify(err.response.data.message, "Delete Academy", Severity.error);
			});
	};

	if(!academy) return <></>
	return (
		<Modal isOpen={open} onClose={() => onClose()}>
			<ConfirmationPopUp
				message={`Are you sure you want to delete "${academy.name}"?`}
				set_modal={() => onClose()}
				button_title="Delete Academy"
				action={handleAcademyDelete}
			/>
		</Modal>
	)
}

export default DeleteAcademyModal;
