import React, {FC, useContext} from "react";
import ConfirmationPopUp from "../../components/ConfirmationPopUp/ConfirmationPopUp";
import {NotifyContext} from "../../context/NotifyContext";
import Modal from "../../Modal";
import coreService from "../../services/core.service";
import {Severity} from "../../types/INotify";

interface IProps {
	open: boolean,
	onClose: () => void,
	collection: any, //TODO type
	onChange: (collection: any) => void
}

export const DeleteAcademyCollectionModal: FC<IProps> = ({open, onClose, collection, onChange}) => {
	const {notify} = useContext(NotifyContext);

	const handleCollectionDelete = async (): Promise<void> => {
		const result = await coreService.deleteCollection(collection.slug, {});
		const success = result.status >= 200 && result.status < 300;
		const message = success ? `Successfully removed ${collection.name}` : "Something when wrong";
		notify(message, "Delete Academy Collection", success ? Severity.success : Severity.error);
		onChange(collection)
		onClose();
	};
	if(!collection) return <></>
	return (
		<Modal isOpen={open} onClose={() => onClose()}>
			<ConfirmationPopUp
				message={`Are you sure you want to delete "${collection.name}"?`}
				set_modal={() => onClose()}
				button_title="Delete collection"
				action={handleCollectionDelete}
			/>
		</Modal>
	)
}

export default DeleteAcademyCollectionModal;
