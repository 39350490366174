import {validateDate} from "../../../helper/validate";
import {ValidationDoc} from "../../IValidation";
import {IWebinarSessionNewRequest} from "../../IWebinar";
import {required} from "../common";

export const webinarSessionNewRequestValidationDoc: ValidationDoc<IWebinarSessionNewRequest> = {
	webinar: [
		required("webinar")
	],
	date: [
		required("date"),
		{
			errorMessage: "Please enter a valid date",
			validate: (e) => validateDate(e.date)
		},
		{
			errorMessage: "Date must be in the future",
			validate: (e) => new Date(e.date) > new Date()
		}
	],
	status: [
		required("status")
	],
	timezone: [
		required("timezone")
	]
}

