import React, {FC, useContext} from "react";
import ConfirmationPopUp from "../../components/ConfirmationPopUp/ConfirmationPopUp";
import {NotifyContext} from "../../context/NotifyContext";
import Modal from "../../Modal";
import coreService from "../../services/core.service";
import {IModule} from "../../types/IModule";
import {Severity} from "../../types/INotify";
import {ISection} from "../../types/ISection";

interface IProps {
	open: boolean,
	onClose: () => void,
	section: ISection,
	module: IModule,
	onChange: (section: ISection, module: IModule) => void
}

export const DeleteModuleModal: FC<IProps> = ({open, onClose, section, module, onChange}) => {
	const {notify} = useContext(NotifyContext);
	const title = module.type === "module" ? "Module" : "Webinar";

	const handleModuleDelete = async (): Promise<void> => {
		await coreService
			.deleteModule(module.slug, {})
			.then(() => {
				onChange(section, module);
				onClose();
				notify(`${module.name} deleted.`, `Delete ${title}`);
			})
			.catch((err) => {
				notify(err.response.data.message, `Delete ${title}`, Severity.error);
			});
	};

	if(!section) return <></>
	return (
		<Modal isOpen={open} onClose={() => onClose()}>
			<ConfirmationPopUp
				message={`Are you sure you want to delete "${module.name}"?`}
				set_modal={() => onClose()}
				button_title={`Delete ${title}`}
				action={handleModuleDelete}
			/>
		</Modal>
	)
}

export default DeleteModuleModal;
