import {ISectionNewRequest} from "../../ISection";
import {ValidationDoc} from "../../IValidation";
import {required} from "../common";

export const sectionCreateRequestValidationDoc: ValidationDoc<ISectionNewRequest> = {
	name: [
		required("name")
	],
	status: [
		required("status")
	],
	label: [
		required("label")
	]
}

