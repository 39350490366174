import React, {FC, useEffect, useRef, useState, useContext} from "react";
import CurriculumSidebar from "./components/CurriculumSidebar/CurriculumSidebar";
import {useParams} from "react-router-dom";
import coreService from "../../services/core.service";
import {IAcademy, IAcademyReorderRequest} from "../../types/IAcademy";
import {ISection} from "../../types/ISection";
import {useModulesData} from "../../api/useModulesData";
import {IModule, IModulesBySectionMap} from "../../types/IModule";
import {useStyles} from "./style";
import AddSectionModal from "../../features/AddSectionModal/AddSectionModal";
import DeleteModuleModal from "../../features/DeleteModuleModal/DeleteModuleModal";
import DeleteSectionModal from "../../features/DeleteSectionModal/DeleteSectionModal";
import EditSectionModal from "../../features/EditSectionModal/EditSectionModal";
import CurriculumSectionList from "./components/AcademyCollection/CurriculumSectionList/CurriculumSectionList";
import {CurriculumHeader} from "./components/header/curiculumHeader";
import {CuriculumFooter} from "./components/footer/curiculumFooter";
import {modulesToSectionMap} from "../../types/mappers/module";
import {deepEqual} from "../../common/JsonObjManipulation";
import {NotifyContext} from "../../context/NotifyContext";
import useDepLog from "../../hooks/useDepLog";

export const Curriculum: FC = () => {
	const classes = useStyles();
	const {slug} = useParams();
	const {notify} = useContext(NotifyContext);
	const [sections, setSections] = useState<ISection[]>([]);
	const [modules, setModules] = useState<IModule[]>([]);
	const [academy, setAcademy] = useState<IAcademy>();
	const [modulesBySection, setModulesBySection] = useState<IModulesBySectionMap>({});
	const [activeSection, setActiveSection] = useState<ISection>();
	const [activeModule, setActiveModule] = useState<IModule>();
	const [addSectionModalOpen, setAddSectionModalOpen] = useState(false);
	const [editSectionModalOpen, setEditSectionModalOpen] = useState(false);
	const [deleteSectionModalOpen, setDeleteSectionModalOpen] = useState(false);
	const [deleteModuleModalOpen, setDeleteModuleModalOpen] = useState(false);

	useEffect(() => {
		coreService.getModules({academy: slug, limit: 1000}, {} ).then((res) => {
			setModules(res.data.records);
		});
		coreService.getSections({academy: slug, limit: 1000}, {} ).then((res) => {
			setSections(res.data.records);
		});
		coreService.getAcademy(slug, {limit: 1000}, {}).then((res) => {
			setAcademy(res.data);
		});
	}, []);

	useDepLog("sections", sections);
	useDepLog("modules", modules);
	useDepLog("modulesBySection", modulesBySection);

	useEffect(() => {
		if(modules) {
			setModulesBySection(modulesToSectionMap(modules))
		}
	}, [modules]);

	//listen for updated module/section order to update api
	const prevOrder = useRef<IAcademyReorderRequest>();
	useEffect(() => {
		if(!academy?.slug || !sections || !modulesBySection || sections.length === 0 || Object.keys(modulesBySection).length === 0)
			return;

		const getOrderRequest = (): IAcademyReorderRequest => ({
			sections: sections.map((e, i) => ({
				slug: e.slug,
				ordering: i,
				modules: modulesBySection[e.slug]?.map((m, j) => ({slug: m.slug, ordering: j})) ?? []
			}))
		});

		if(!prevOrder.current){
			prevOrder.current = getOrderRequest()
			return;
		}

		const orderRequest: IAcademyReorderRequest = getOrderRequest();
		if(!deepEqual(orderRequest, prevOrder.current)){
			coreService.reorderAcademy(academy?.slug, orderRequest).then(e => {
				notify("Successfully saved new order", "Curriculum");
				prevOrder.current = orderRequest;
			})
		}

	}, [sections, modulesBySection])

	const handleAddSection = (newSection: ISection): void => {
		setSections(prev => ([...prev ?? [], newSection]))
	}

	const handleEditSection = (editSection: ISection): void => {
		setSections(prev => {
			const section = prev?.find(e => e.slug === editSection.slug);
			if(!section) return prev;
			Object.assign(section, editSection);
			return [...prev ?? []];
		})
	}

	const handleDeleteSection = (deleteSection: ISection): void => {
		setSections(prev => {
			const index = prev?.findIndex(e => e.slug === deleteSection.slug);
			if (!index || index === -1) return prev;
			prev?.splice(index, 1);
			return [...prev ?? []];
		})
	}

	const handleDeleteModule = (section: ISection, deleteModule: IModule): void => {
		setModulesBySection(prev => {
			if(prev[section.slug].length === 1) delete prev[section.slug];
			else prev[section.slug] = prev[section.slug].filter(e => e.slug !== deleteModule.slug);
			return {...prev};
		});
	}

	const openModal = (stateFtn: Function, section: ISection, module?: IModule): void => {
		stateFtn(true);
		setActiveSection(section);
		setActiveModule(module)
	}

	return (
		<div className="curriculum">
			<CurriculumSidebar sections={sections} IModulesBySectionMap={modulesBySection}/>
			{(sections && modulesBySection && academy) &&
				<div className={classes.curriculumDashboard}>
					<div className={classes.dashboardContainer}>
						<div className={classes.curriculumHome}>
							<CurriculumHeader
								academyName={academy?.name ?? ""}
								academyLanguage={academy?.language ?? ""}
							/>
							{sections.length > 0 ? (
								<CurriculumSectionList
									sections={sections}
									modulesBySection={modulesBySection}
									academy={academy}
									onSectionsReorder={e => setSections(e)}
									onEditSectionAction={(section: ISection) => openModal(setEditSectionModalOpen, section)}
									onDeleteSectionAction={(section: ISection) => openModal(setDeleteSectionModalOpen, section)}
									onModulesReorder={setModulesBySection}
									onDeleteModule={(section: ISection, module: IModule) => openModal(setDeleteModuleModalOpen, section, module)}
									onChange={e => handleEditSection(e)}
								/>
							) : <h2 className={classes.noneToShow}>This academy has no lessons.</h2>}
							<CuriculumFooter onAddSectionRequest={() => setAddSectionModalOpen(true)}/>

							<AddSectionModal
								open={addSectionModalOpen}
								onClose={() => setAddSectionModalOpen(false)}
								academy={academy}
								onChange={section => handleAddSection(section)}
							/>
							{activeSection && <>
								<EditSectionModal
									open={editSectionModalOpen}
									section={activeSection}
									onClose={() => setEditSectionModalOpen(false)}
									academy={academy}
									onChange={section => handleEditSection(section)}
								/>
								<DeleteSectionModal
									open={deleteSectionModalOpen}
									section={activeSection}
									onClose={() => setDeleteSectionModalOpen(false)}
									onChange={section => handleDeleteSection(section)}
								/>
								{activeModule && <DeleteModuleModal
									open={deleteModuleModalOpen}
									section={activeSection}
									module={activeModule}
									onClose={() => setDeleteModuleModalOpen(false)}
									onChange={(section, module) => handleDeleteModule(section, module)}
								/>}
							</>}
						</div>
					</div>
				</div>
			}
		</div>
	);
}

export default Curriculum;
