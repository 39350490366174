import axiosClient from "../helper/axios";
import {endpoints} from "./endpoints";

class UserService {
	baseUrl = process.env.REACT_APP_API_URL;
	client = axiosClient;

	/* Gets users data from local storage */
	getUser() {
		const user = localStorage.getItem("user");
		if (user) return JSON.parse(localStorage.getItem("user"));
		return null;
	}

	setUser(user) {
		localStorage.setItem("user", JSON.stringify(user));
	}

	isAdmin = () =>
		JSON.parse(localStorage.getItem("user"))?.role.includes("admin") ?? false;

	/* Returns a sso url for the user to login */
	login(email, password) {
		const url = `${this.baseUrl}/${endpoints.login}`;
		const data = {
			email,
			password
		};
		return this.client.post(url, data);
	}

	logout = () => {
		localStorage.removeItem("user");
		window.location.href = "/";
	};

	/* Returns the current user information */
	manage() {
		const url = `${this.baseUrl}/${endpoints.manage}`;
		return this.client.get(url);
	}

	/* Update the User"s password */
	updatePassword(current_password, new_password, headers = {}) {
		const url = `${this.baseUrl}/${endpoints.reset_password}`;
		return this.client.post(
			url,
			{
				current_password,
				new_password
			},
			headers
		);
	}

	/* Update the current user information !Not TESTED */
	async updateUser(userData) {
		const url = `${this.baseUrl}/${endpoints.manage}`;
		const {first_name, last_name, role, active, email, slug} = userData;
		return this.client.put(url, {
			first_name,
			last_name,
			role,
			active,
			email,
			slug
		});
	}

	/* Returns a jwt token when a user posts a valid token */
	retrieveToken(payload) {
		const url = `${this.baseUrl}/${endpoints.access_token}`;
		return this.client.post(url, payload);
	}

	/* Refreshes the access token when a valid refresh has been given */
	refreshToken(payload) {
		const url = `${this.baseUrl}/${endpoints.refresh}`;
		return this.client.post(url, payload);
	}
}

const userService = new UserService();
export default userService;
