import React, {useState, useEffect, FC} from "react";
import AcademyCollection from "./components/AcademyCollection/AcademyCollection";
import Footer from "./components/Footer/Footer";
import coreService from "../../services/core.service";
import AddAcademyModal from "../../features/AddAcademyModal/AddAcademyModal";
import DeleteAcademyCollectionModal from "../../features/DeleteAcademyCollectionModal/DeleteAcademyCollectionModal";
import EditAcademyCollectionModal from "../../features/EditAcademyCollectionModal/EditAcademyCollectionModal";
import DeleteAcademyModal from "../../features/DeleteAcademyModal/DeleteAcademyModal";
import EditAcademyModal from "../../features/EditAcademyModal/EditAcademyModal";
import {PageBase} from "../../components/PageBase/PageBase";
import CircularProgress from "@mui/material/CircularProgress";
import {AddAcademyCollectionModal} from "../../features/AddAcademyCollectionModal/AddAcademyCollectionModal";
import userService from "../../services/user.service";
import {useNavigate} from "react-router-dom";
import {IAcademyCollection} from "../../types/IAcademyCollection";
import {IAcademy} from "../../types/IAcademy";

export const Dashboard: FC = () => {
	const navigate = useNavigate();
	const [collections, setcollections] = useState<IAcademyCollection[]>([]);
	const [isLoading, setIsLoading] = useState(true);
	const [activeCollection, setActiveCollection] = useState<IAcademyCollection>();
	const [activeAcademy, setActiveAcademy] = useState<IAcademy>();

	const [addCollectionModalOpen, setAddCollectionModalOpen] = useState(false);
	const [editCollectionModalOpen, setEditCollectionModalOpen] = useState(false);
	const [deleteCollectionModalOpen, setDeleteCollectionModalOpen] = useState(false);

	const [addAcademyModalOpen, setAddAcademyModalOpen] = useState(false);
	const [editAcademyModalOpen, setEditAcademyModalOpen] = useState(false);
	const [deleteAcademyModalOpen, setDeleteAcademyModalOpen] = useState(false);

	useEffect(() => {
		if(!userService.getUser()){
			navigate("/");
		}
	})

	useEffect(() => {
		coreService.getCollections().then((res) => {
			setcollections(res.data.records);
			setIsLoading(false);
		});
	}, []);

	const handleNewCollection = (collection: IAcademyCollection): void =>
		setcollections([collection, ...collections]);

	const handleEditCollection = (collectionEdit: IAcademyCollection): void =>
		setcollections(prev => {
			const collection = prev.find(e => e.slug === activeCollection?.slug);
			if(!collection) return prev;
			Object.assign(collection, collectionEdit)
			return [...prev];
		});

	const handleDeleteCollection = (collection: IAcademyCollection): void => {
		setcollections(prev => {
			const index = prev.findIndex(e => e.slug === collection.slug);
			if (index === -1) return prev;
			prev.splice(index, 1);
			return [...prev];
		})
	}

	const handleAddAcademy = (academy: IAcademy): void => {
		setcollections(prev => {
			const collection = prev.find(e => e.slug === activeCollection?.slug);
			if(!collection) return prev;
			collection.academies.push(academy);
			return [...prev];
		})
	}

	const handleEditAcademy = (academyEdit: IAcademy): void => {
		const collectionSlug = academyEdit?.academy_collection ?? activeCollection?.slug;
		setcollections(prev => {
			const academy = prev.find(e => e.slug === collectionSlug)?.academies?.find(e => e.slug === academyEdit.slug);
			if (!academy) return prev;
			Object.assign(academy, academyEdit)
			return [...prev];
		})
	}

	const handleDeleteAcademy = (academy: IAcademy): void => {
		setcollections(prev => {
			const collection = prev.find(e => e.slug === activeCollection?.slug);
			if (!collection) return prev;
			const academyIndex = collection.academies.findIndex(e => e.slug === academy.slug);
			if (academyIndex == -1 || !collection.academies[academyIndex]) return prev;
			collection.academies.splice(academyIndex, 1);
			return [...prev];
		})
	}

	const openModal = (stateFtn: Function, collection: IAcademyCollection, academy?: IAcademy): void => {
		stateFtn(true);
		setActiveCollection(collection);
		if(academy) setActiveAcademy(academy);
	}

	return (
		<PageBase>
			<section className="home_dashboard__dashboard_container">
				<header>
					<h1>Dashboard</h1>
					{isLoading && <CircularProgress style={{color: "#21273c"}} />}
				</header>
				{collections?.length > 0 && (
					<div className="academy_collections_container">
						{collections.map((collection, i) =>
							<AcademyCollection
								key={"academy_collection_" + i}
								collection={collection}
								onEditAction={() => openModal(setEditCollectionModalOpen, collection)}
								onDeleteAction={() => openModal(setDeleteCollectionModalOpen, collection)}
								onAddAcademyAction={() => openModal(setAddAcademyModalOpen, collection)}
								onEditAcademyAction={(academy) => openModal(setEditAcademyModalOpen, collection, academy)}
								onDeleteAcademyAction={(academy) => openModal(setDeleteAcademyModalOpen, collection, academy)}
								onAcademyChange={(academy) => handleEditAcademy(academy)}
							/>
						)}
					</div>
				)}
				{collections?.length === 0 && (
					<div className="academy_collections_container" style={{marginTop: 50}}>
						<div className="empty"> No academy collections available </div>
					</div>
				)}
				<Footer
					onAddAcademyAction={() => setAddCollectionModalOpen(true)}
				/>
			</section>

			<AddAcademyCollectionModal
				open={addCollectionModalOpen}
				onClose={() => setAddCollectionModalOpen(false)}
				onChange={collection => handleNewCollection(collection)}
			/>

			{activeCollection && <EditAcademyCollectionModal
				open={editCollectionModalOpen}
				collection={activeCollection}
				onClose={() => setEditCollectionModalOpen(false)}
				onChange={collection => handleEditCollection(collection)}
			/>}

			<DeleteAcademyCollectionModal
				open={deleteCollectionModalOpen}
				collection={activeCollection}
				onClose={() => setDeleteCollectionModalOpen(false)}
				onChange={collection => handleDeleteCollection(collection)}
			/>

			{activeCollection && <AddAcademyModal
				open={addAcademyModalOpen}
				onClose={() => setAddAcademyModalOpen(false)}
				collection={activeCollection}
				onChange={academy => handleAddAcademy(academy)}
			/>}

			{activeAcademy && activeCollection && <EditAcademyModal
				open={editAcademyModalOpen}
				collection={activeCollection}
				academy={activeAcademy}
				onClose={() => setEditAcademyModalOpen(false)}
				onChange={academy => handleEditAcademy(academy)}
				onMasterSelectChange={e =>
					handleEditCollection({
						...activeCollection,
						master_academy: e ? activeAcademy.slug : null
					})}
			/>}

			{activeAcademy && <DeleteAcademyModal
				open={deleteAcademyModalOpen}
				collection={activeCollection}
				academy={activeAcademy}
				onClose={() => setDeleteAcademyModalOpen(false)}
				onChange={academy => handleDeleteAcademy(academy)}
			/>}
		</PageBase>
	);
}

export default Dashboard;
