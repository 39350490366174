import {ValidationDoc} from "../../IValidation";
import {IWebinarEditRequest} from "../../IWebinar";
import {required} from "../common";

export const webinarEditRequestValidationDoc: ValidationDoc<IWebinarEditRequest> = {
	name: [
		required("name")
	]
}

