import React, {FC, useState, useEffect} from "react";
import {TimezoneTimeToStringUI, TimezoneDateToStringUI, getTimeZoneUI} from "../../../../../common/dateManipulation";
import {Container} from "../../../../../components/Common/Container/Container";
import {IWebinarSession} from "../../../../../types/IWebinar";
import {useStyles} from "./style";

const BinIcon = "/assets/bin.svg";
const PenPaperIcon = "/assets/users-edit.svg";
interface IProps {
	session: IWebinarSession,
	onEditRequest: () => void,
	onDeleteRequest: () => void
}


export const SessionListItem: FC<IProps> = ({session, onEditRequest, onDeleteRequest}) => {
	const classes = useStyles();
	const [isUpcoming, setIsUpcoming] = useState<boolean>(new Date(session.date) > new Date());

	useEffect(() => {
		setIsUpcoming(new Date(session.date) > new Date())
	}, [session.date])

	return (
		<Container style={{width: "100%", padding: "20px 0 20px 20px", margin: "25px 0 25px 0"}}>
			<div className={classes.sessionListItem}>
				<div className={classes.leftContainer}>
					<div>{session.live_url}</div>
				</div>
				<div className={classes.rightContainer}>
					{TimezoneDateToStringUI(new Date(session.date), session.timezone)}
					&nbsp;&nbsp;&nbsp;&nbsp;
					{TimezoneTimeToStringUI(new Date(session.date), session.timezone)}
					<div className={classes.timezone}>{getTimeZoneUI(session.timezone)}</div>
					{isUpcoming && <button className={classes.deleteBtn} onClick={() => onEditRequest()}>
						<img src={PenPaperIcon} alt="" />
					</button>}
					<button className={classes.deleteBtn} onClick={() => onDeleteRequest()}>
						<img src={BinIcon} alt="" />
					</button>
				</div>
				{isUpcoming && <div className={classes.upcommingInfoContainer}>
					<br/>
					<div><span>Streaming Link:</span> {session.streaming_link}</div>
					<div><span>Streaming Key:</span> {session.streaming_key}</div>
				</div>}
			</div>
		</Container>
	);
}

export default SessionListItem;
