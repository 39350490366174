import axios from "axios";
import userService from "../services/user.service";

const apiClient = axios.create({
	baseURL: process.env.REACT_APP_API_URL
});

const requestAccessToken = async () =>
	apiClient.post("/users/token/refresh/", {
		refresh: userService.getUser().token.refresh
	});

const refreshAccessToken = async () => {
	const {data} = await requestAccessToken();
	const userData = userService.getUser();
	userData.token.access = data.token.access;
	userData.token.refresh = data.token.refresh;
	userService.setUser(userData);
	localStorage.setItem("user", JSON.stringify(userData));
	const {access, refresh} = data.token;
	return {access, refresh};
};

apiClient.interceptors.request.use(
	async (config) => {
		const user = userService.getUser();
		if (user) {
			config.headers = {
				Authorization: `Bearer ${user.token.access}`
			};
		}
		return config;
	},
	(error) => {
		Promise.reject(error);
	}
);

apiClient.interceptors.response.use(
	(response) => {
		return response;
	},
	async (error) => {
		const originalRequest = error.config;
		try {
			const isTokenExpired =
				error.response.data.code === "token_not_valid" &&
				error.response.data.messages[0].token_type === "access";
			if (
				error.response.status === 401 &&
				!originalRequest._retry &&
				isTokenExpired
			) {
				originalRequest._retry = true;
				const {access} = await refreshAccessToken();
				apiClient.defaults.headers.common["Authorization"] = "Bearer " + access;
				return apiClient(originalRequest);
			}
			return Promise.reject(error);
		} catch (err) {
			originalRequest._retry = false;
			userService.logout();
			return Promise.reject(err);
		}
	}
);

export default apiClient;
