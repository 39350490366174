import React, {FC, useState, useCallback, useEffect, ReactElement, useContext} from "react";
import {TextInput} from "../form/textInput/textInput";
import {IUser, IUserInfoUpdateRequest} from "../../types/IUser";
import Modal from "../../Modal";
import {Button} from "../Common/Button/Button";
import {useEditUser} from "../../api/useUsersData";
import {userToEditUserRequest} from "../../types/mappers/user";
import RoleSelect from "../../pages/Users/components/RoleSelect/roleSelect";
import {useValidate} from "../../hooks/useValidate";
import {userInfoUpdateRequestDoc} from "../../types/validationDocs/user/userInfoUpdateRequestDoc";
import {NotifyContext} from "../../context/NotifyContext";
import ConfirmationPopUp from "../ConfirmationPopUp/ConfirmationPopUp";

interface IProps {
    user: IUser,
    onSuccess?: (user: IUser) => void,
    enableRoleSelection?: boolean,
}

export const EditUserInfoForm: FC<IProps> = ({user, onSuccess, enableRoleSelection}) => {
	const [formData, setFormData] = useState<IUserInfoUpdateRequest>(userToEditUserRequest(user));
	const {mutate: editUser, isSuccess, data} = useEditUser(user.slug);
	const [confirmModalOpen, setConfirmModalOpen] = useState<boolean>(false);
	const [validationResult, runValidate, hasErrors, clearValidation] = useValidate<IUserInfoUpdateRequest>(userInfoUpdateRequestDoc, formData);
	const {notify} = useContext(NotifyContext);

	useEffect(() => {
		setFormData(userToEditUserRequest(user));
		clearValidation()
	}, [user]);

	const handleSubmit = useCallback(() => {
		setConfirmModalOpen(false);
		if(!runValidate()) return;
		editUser(formData);
	}, [formData, editUser]);

	useEffect(() => {
		if(isSuccess) {
			notify("Your details have been successfully changed", "Settings");
			if(onSuccess) onSuccess(data?.data as unknown as IUser);
		}
	}, [isSuccess, data]);

	const inputText = (label: string, prop: keyof IUserInfoUpdateRequest, type = "text"): ReactElement => <TextInput
		label={label}
		type={type}
		error={validationResult[prop]}
		value={formData[prop]}
		onChange={e => setFormData(prev => ({...prev, [prop]: e.target.value}))}
		onValidate={() => runValidate(prop)}
	/>

	return (
		<>
			{inputText("First Name", "first_name")}
			{inputText("Last Name", "last_name")}

			{enableRoleSelection && <>
				<label htmlFor="">Role</label>
				<RoleSelect value={formData.role} onSelect={(e: any) => setFormData(prev => ({...prev, role: e.value}))} />
			</>}

			<div style={{textAlign: "right", marginTop: 35}}>
				<Button
					onClick={() => setConfirmModalOpen(true)}
					disabled={hasErrors}
				>
                    Submit
				</Button>
			</div>

			<Modal isOpen={confirmModalOpen} onClose={() => setConfirmModalOpen(false)}>
				<ConfirmationPopUp
					set_modal={setConfirmModalOpen}
					message="Are you sure you want to change the user information?"
					button_title="Confirm"
					action={handleSubmit}
				/>
			</Modal>
		</>

	);
};
