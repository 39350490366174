import React, {useEffect, useState, FC} from "react";
import {Close} from "@mui/icons-material";
import {IUser} from "../../../../types/IUser";
import {EditUserInfoForm} from "../../../../components/EditUserInfoForm/EditUserInfoForm";
import PasswordIcon from "@mui/icons-material/Password";
import PersonIcon from "@mui/icons-material/Person";
import {Tabs} from "../../../../components/Common/Tabs/Tabs";
import {EditUserPasswordForm} from "./EditUserPasswordForm/EditUserPasswordForm";
import Modal from "../../../../Modal";

export type tab = "info" | "password";

interface IProps {
    open: boolean,
	onClose: () => void,
    user?: IUser,
	defaultTab?: tab
}

export const EditUserModal: FC<IProps> = ({user, open, onClose, defaultTab}) => {
	const [tabSelected, setTabSelected] = useState<tab>(defaultTab ?? "info");

	useEffect(() => setTabSelected(defaultTab ?? "info"), [defaultTab]);

	if (!user) return <></>;

	return (
		<Modal isOpen={open} onClose={onClose}>
			<div className="main_user__new_user" >
				<header>
					<span className="text_30">Edit User</span>
					<button
						className="close_btn"
						onClick={onClose}
					>
						<Close style={{width: "100%"}} />
					</button>
				</header>

				<br />
				<Tabs<tab>
					tabs={[
						{value: "info", label: "Change Info", icon: <PersonIcon/>},
						{value: "password", label: "Change Password", icon: <PasswordIcon/>}
					]}
					value={tabSelected}
					onChange={e => setTabSelected(e)}/>


				{tabSelected === "info" && <EditUserInfoForm user={user} enableRoleSelection onSuccess={() => onClose()}/>}
				{tabSelected === "password" && <EditUserPasswordForm user={user}/>}
			</div>
		</Modal>
	);
}