import React, {FC, useState, useEffect, useRef} from "react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import SideBarSection from "../SidebarSection/SideBarSection";
import {useNavigate, useParams} from "react-router-dom";
import {ISection} from "../../../../types/ISection";
import {IModulesBySectionMap} from "../../../../types/IModule";

const HatImage = "/assets/hat.svg";
const Logo = "/assets/logo.svg";

interface IProps {
	sections?: ISection[],
	IModulesBySectionMap?: IModulesBySectionMap
}

const getAcademySlug = (sections?: ISection[]): string | null => {
	if(sections && sections.length > 0 && !window.location.href.includes("curriculum")){
		return sections[0].academy;
	}
	return null;
}

export const CurriculumSidebar: FC<IProps> = ({sections, IModulesBySectionMap}) => {
	const navigate = useNavigate();
	const [academySlug, setAcademySlug] = useState<string | null>();
	const awaitingAcademySlugConfirmation = academySlug === undefined;

	useEffect(() => {
		if(sections)
			setAcademySlug(getAcademySlug(sections))
	}, [sections])

	return (
		<div className="curriculum__sidebar">
			<img
				src={Logo}
				style={{cursor: "pointer"}}
				alt=""
				className="logo"
				onClick={() => navigate("/dashboard")}
			/>
			<div style={{height: 45, overflow: "none"}}>
				{!awaitingAcademySlugConfirmation && <>
					{!academySlug && <button onClick={() => navigate("/dashboard")}>
						<ArrowBackIcon style={{color: "#6AC7D0"}} />
						Back to dashboard
					</button>}

					{academySlug && <button onClick={() => navigate("/curriculum/" + academySlug)}>
						<ArrowBackIcon style={{color: "#6AC7D0"}} />
						Back to curriculum
					</button>}
				</>}
			</div>
			<header
				className="curriculum__sidebar__header"
				style={{cursor: "pointer"}}
				onClick={() => academySlug && navigate("/curriculum/" + academySlug)}
			>
				<img src={HatImage} alt="" />
				<h2>Curriculum</h2>
			</header>

			{sections && IModulesBySectionMap &&
				sections.map((section, i) => {
					return <SideBarSection key={i} section={section} modules={IModulesBySectionMap[section.slug]}/>;
				})}
			<div className="curriculum__sidebar__contentplaceholder" style={{background: "url(\"/assets/background-hero.svg\") 100% 30px / auto no-repeat fixed rgb(241, 242, 242)"}}>

			</div>
		</div>
	);
}

export default CurriculumSidebar;
