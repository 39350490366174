import {validateEmail} from "../../../helper/validate";
import {IUserCreateRequest} from "../../IUser";
import {ValidationDoc} from "../../IValidation";
import {required} from "../common";

export const UserCreateRequestValidationDoc: ValidationDoc<IUserCreateRequest> = {
	first_name: [
		required("first_name")
	],
	last_name: [
		required("last_name")
	],
	email: [
		required("email"),
		{
			errorMessage: "please enter a valid email",
			validate: (e) => Object.keys(validateEmail(e.email)).length === 0
		}
	],
	role: [
		required("role")
	]
}

