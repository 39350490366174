import React, {useEffect, useState, FC, useContext, ReactElement} from "react";
import CloseIcon from "@mui/icons-material/Close";
import Select from "../../components/Select/Select";
import {TextInput} from "../../components/form/textInput/textInput";
import coreService from "../../services/core.service";
import {ISection, ISectionEditRequest} from "../../types/ISection";
import {IAcademy} from "../../types/IAcademy";
import {sectionToEditSectionRequest} from "../../types/mappers/section";
import Modal from "../../Modal";
import {Button} from "../../components/Common/Button/Button";
import {NotifyContext} from "../../context/NotifyContext";
import {Severity} from "../../types/INotify";
import {useValidate} from "../../hooks/useValidate";
import {sectionEditRequestValidationDoc} from "../../types/validationDocs/section/sectionEditRequestValidationDoc";
import {publishedOptions} from "../../constants";

interface IProps {
	open: boolean,
	onClose: () => void,
	onChange: (section: any) => void,
	academy: IAcademy,
	section: ISection
}

export const EditSectionModal: FC<IProps> = ({open, onClose, onChange, academy, section}) => {
	const [formData, setFormData] = useState(sectionToEditSectionRequest(section));
	const [validationResult, runValidate, hasErrors, clearValidation] = useValidate<ISectionEditRequest>(sectionEditRequestValidationDoc, formData);
	const {notify} = useContext(NotifyContext);

	useEffect(() => {
		setFormData(sectionToEditSectionRequest(section));
		clearValidation();
	}, [section, open]);

	const handleEditSection = async (): Promise<void> => {
		if(!runValidate()) return;
		const result = await coreService.updateSection(section.slug, formData);
		const success = result.status >= 200 && result.status < 300;
		if(success) onChange(result.data);

		const message = success ? `Successfully modified ${formData.name}` : "Something when wrong";
		notify(message, "Section", success ? Severity.success : Severity.error);
		onClose();
	};

	const inputText = (label: string, prop: keyof ISectionEditRequest, type = "text"): ReactElement => <TextInput
		label={label}
		type={type}
		error={validationResult[prop]}
		value={formData[prop] as string}
		onChange={e => setFormData(prev => ({...prev, [prop]: e.target.value}))}
		onValidate={() => runValidate(prop)}
	/>

	if(!section || !academy) return <></>;
	return (
		<Modal isOpen={open} onClose={() => onClose()}>
			<div className="add_academy_collection">
				<span className="text_30">Edit Section</span>
				<button
					className="close_btn"
					type="button"
					onClick={() => onClose()}
				>
					<CloseIcon />
				</button>

				{inputText("Title", "name")}

				<Select
					label="Status"
					options={publishedOptions}
					error={validationResult["status"]}
					onValidate={() => runValidate("status")}
					style={{height: "40px"}}
					value={formData.status}
					onSelect={(option) => setFormData(prev => ({...prev, status: option.value}))}
				/>

				{inputText("Label", "label")}

				<div style={{textAlign: "center", marginTop: 35}}>
					<Button disabled={hasErrors} onClick={() => handleEditSection()}>
						Save
					</Button>
				</div>
			</div>
		</Modal>
	);
}

export default EditSectionModal;
