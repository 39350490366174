import React, {useEffect, useState, FC} from "react";
import {DeleteUserModal} from "./components/DeleteUserModal/DeleteUserModal";
import {EditUserModal, tab} from "./components/EditUser/EditUserModal";
import UserFooter from "./components/UserFooter/UserFooter";
import UserItem from "./components/UserItem/UserItem";
import {isAdmin} from "../../common/isAdmin";
import {useNavigate} from "react-router-dom";
import {useUsersData} from "../../api/useUsersData";
import {PageBase} from "../../components/PageBase/PageBase";
import {IUser} from "../../types/IUser";

export const Users: FC = () => {
	const navigate = useNavigate();
	const [activeUser, setActiveUser] = useState<IUser>();

	const [deleteUserModalOpen, setDeleteUserModalOpen] = useState(false);
	const [editUserModalOpen, setEditUserModalOpen] = useState(false);
	const [defaultModalTab, setDefaultModalTab] = useState<tab>();

	const {data: userdata} = useUsersData({limit: 10000, role: "administrator,academy_manager"});
	const users = (userdata ?? []) as IUser[];

	useEffect(() => {
		if (!isAdmin()) navigate("/dashboard");
	}, [navigate]);

	const openModal = (stateFtn: Function, user?: IUser, tab?: tab): void => {
		stateFtn(true);
		if(user) setActiveUser(user);
		if(tab) setDefaultModalTab(tab);
	}

	if(!users) return <></>;
	return (
		<PageBase>
			<div className="home_user">
				<header>
					<h1>Users</h1>
				</header>

				<ul className="field_name">
					<li className="field_name__name">name</li>
					<li className="field_name__email">email</li>
					<li className="field_name__role">role</li>
				</ul>
				{
					users.map((user, i) =>
						<UserItem
							key={"user-" + i}
							onDeleteUserAction={() => openModal(setDeleteUserModalOpen, user)}
							onEditUserInfoAction={() => openModal(setEditUserModalOpen, user, "info")}
							onEditUserPasswordAction={() => openModal(setEditUserModalOpen, user, "password")}
							user={user}
						/>
					)
				}
				<DeleteUserModal
					user={activeUser}
					open={deleteUserModalOpen}
					onClose={() => setDeleteUserModalOpen(false)}
				/>

				<EditUserModal
					user={activeUser}
					open={editUserModalOpen}
					onClose={() => setEditUserModalOpen(false)}
					defaultTab={defaultModalTab}
				/>

				<UserFooter />
			</div>
		</PageBase>
	)
}

export default Users;
