import React, {FC, useRef} from "react";
import {DragDropContext, DraggableLocation, Droppable, DropResult} from "react-beautiful-dnd";
import {IAcademy} from "../../../../../types/IAcademy";
import {IModule, IModulesBySectionMap} from "../../../../../types/IModule";
import {ISection} from "../../../../../types/ISection";
import CurriculumSection from "../CurriculumSection/CurriculumSection";

interface IProps {
	sections: ISection[],
	academy: IAcademy,
	modulesBySection: IModulesBySectionMap,
	onModulesReorder: React.Dispatch<React.SetStateAction<IModulesBySectionMap>>,
	onSectionsReorder: React.Dispatch<React.SetStateAction<ISection[]>>,
	onEditSectionAction: (section: ISection) => void,
	onDeleteSectionAction: (section: ISection) => void,
	onDeleteModule: (section: ISection, module: IModule) => void,
	onChange: (section: ISection) => void
}

const reorder = <T,>(list: T[], startIndex: number, endIndex: number):T[] => {
	if(startIndex === endIndex || !list) return list;

	const result = Array.from(list);
	const [removed] = result.splice(startIndex, 1);
	result.splice(endIndex, 0, removed);

	return result;
};

const move = (source: IModule[], destination: IModule[], droppableSource: DraggableLocation, droppableDestination: DraggableLocation): IModulesBySectionMap => {
	const sourceClone = Array.from(source);
	const destClone = destination ? Array.from(destination) : [];
	const [removed] = sourceClone.splice(droppableSource.index, 1);

	destClone.splice(droppableDestination.index, 0, removed);

	const result:IModulesBySectionMap = {};
	result[droppableSource.droppableId] = sourceClone;
	result[droppableDestination.droppableId] = destClone;

	return result;
};

export const CurriculumSectionList: FC<IProps> = ({
	sections, academy, modulesBySection, onModulesReorder, onSectionsReorder,
	onEditSectionAction, onDeleteSectionAction, onDeleteModule, onChange
}) => {

	const handleDragEnd = (result: DropResult): void => {
		const {source, destination} = result;

		//dropped outside list
		if (!destination) return;

		const sId = source.droppableId;
		const dId = destination.droppableId;
		//section reorder
		if (result.type === "droppableSection") {
			onSectionsReorder(prev => reorder<ISection>(prev, source.index, destination.index));
		}

		onModulesReorder(prev => {
			//same list
			if (sId === dId) {
				prev[sId] = reorder<IModule>(prev[sId], source.index, destination.index);
			}
			//different list 
			else {
				const result = move(prev[sId], prev[dId], source, destination);

				prev[sId] = result[sId];
				prev[dId] = result[dId];
			}
			return {...prev};
		});
	}

	return (
		<DragDropContext onDragEnd={handleDragEnd}>
			<Droppable droppableId={academy.slug} type="droppableSection">
				{provided => (
					<div className="curriculum__home__section_dnd_container" style={{marginBottom: 30}} ref={provided.innerRef} >
						{sections.map((section, i) =>
							<CurriculumSection
								key={i}
								index={i}
								section={section}
								modules={modulesBySection[section.slug] ?? []}
								academy={academy}
								onEditAction={() => onEditSectionAction(section)}
								onDeleteAction={() => onDeleteSectionAction(section)}
								onDeleteModuleAction={module => onDeleteModule(section, module)}
								onChange={e => onChange(e)}
							/>
						)}
						{provided.placeholder}
					</div>
				)}
			</Droppable>
		</DragDropContext>
	);
}

export default CurriculumSectionList;
