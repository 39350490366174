import React, {FC, useEffect, useState} from "react";
import userService from "../../services/user.service";
import {IUser} from "../../types/IUser";
import {ChangeSessionPasswordForm} from "./ChangeSessionPasswordForm/ChangeSessionPasswordForm";
import {EditUserInfoForm} from "../../components/EditUserInfoForm/EditUserInfoForm";
import {PageBase} from "../../components/PageBase/PageBase";
import {Container} from "../../components/Common/Container/Container";
import {Tabs} from "../../components/Common/Tabs/Tabs";
import PersonIcon from "@mui/icons-material/Person";
import PasswordIcon from "@mui/icons-material/Password";

type tab = "info" | "password";

export const Settings: FC = () => {
	const [user, setUser] = useState(userService.getUser() as unknown as IUser);
	const [tabSelected, setTabSelected] = useState<tab>("info");

	useEffect(() => userService.setUser(user), [user]);

	return (
		<PageBase>
			<div className="home_settings__settings_container">
				<h1>Settings</h1>

				<Container style={{width: 600}}>
					<Tabs<tab>
						tabs={[
							{value: "info", label: "Change Info", icon: <PersonIcon/>},
							{value: "password", label: "Change Password", icon: <PasswordIcon/>}
						]}
						value={tabSelected}
						onChange={e => setTabSelected(e)}
					/>

					{tabSelected === "info" && <EditUserInfoForm user={user} onSuccess={u => setUser(prev => ({...prev, ...u}))} />}
					{tabSelected === "password" && <ChangeSessionPasswordForm />}
				</Container>
			</div>
		</PageBase>
	);
};

export default Settings;