import {createUseStyles} from "react-jss";

export const useStyles = createUseStyles({
	moduleFileUpload: {
		paddingTop: 25
	},
	uploadedFiles: {
		display: "flex",
		flexDirection: "column",
		marginTop: 22,
		"& a": {
			textDecoration: "none",
			textOverflow: "ellipsis",
			color: "#444444",
			whiteSpace: "nowrap",
			overflow: "hidden"
		},
		"& li": {
			borderRadius: "6px",
			padding: "0 12px",
			border: "1px solid #21273D10",
			listStyleType: "none",
			display: "flex",
			alignItems: "center",
			justifyContent: "space-between",
			height: 45,
			marginBottom: 6,
			"& img": {
				marginRight: 10
			},
			"& .left_container": {
				display: "flex",
				alignItems: "center"
			}
		}
	},
	closeIcon: {
		cursor: "pointer"
	},
	uploadButton: {
		"& input": {
			display: "none"
		},
		"& .loading-text": {
			display: "none"
		}
	},
	loading: {
		opacity: 0.5,
		pointerEvents: "none",
		cursor: "not-allowed",
		animation: "flashing 1s infinite",
		"& .default-text": {
			display: "none"
		},
		"& .loading-text": {
			display: "block"
		},
		"& img": {
			display: "none"
		}
	}
})
