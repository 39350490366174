import React, {FC, useContext, useEffect} from "react";
import CloseIcon from "@mui/icons-material/Close";
import InfoIcon from "@mui/icons-material/Info";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ErrorIcon from "@mui/icons-material/Error";
import {ProgressBar} from "../Notifications";
import {NotifyContext} from "../../../../context/NotifyContext";
import {IMessage, Severity} from "../../../../types/INotify";

const styles:any = {
	notificationMessage: {
		overflow: "hidden",
		width: 335,
		float: "right",
		right: 15,
		marginTop: 15,
		borderRadius: 10,
		padding: 15,
		position: "relative",
		boxSizing: "border-box",
		border: "1px solid #d1d1d1",
		zIndex: 5
	},
	icon: {
		position: "absolute",
		top: 25,
		left: 15,
		color: "black",
		opacity: 0.5,
		fontSize: 30
	},
	close: {
		position: "absolute",
		top: 10,
		right: 10,
		color: "grey",
		cursor: "pointer",
		"& :hover": {
			opacity: 1,
			color: "#f05a28"
		}
	},
	title: {
		position: "absolute",
		top: 7,
		left: 70,
		fontSize: 15,
		fontWeight: 900
	},
	body: {
		marginTop: 30,
		marginLeft: 55
	},
	progressBar: {
		position: "absolute",
		left: 0,
		right: 0
	}
};

export const NotificationMessage: FC<IMessage> = (props) => {
	const {notifyDispatch} = useContext(NotifyContext);
	const id = "progressBar" + ((props.id ?? -1).toString()).replace(".","-");

	const close = (): void => {
		notifyDispatch({type: "remove", payload: props});
	};

	useEffect(() => {
		if(props.lifeTime){
			const bar = new ProgressBar.Line("#" + id, {
				strokeWidth: 1,
				duration: props.lifeTime,
				color: "#d1d1d1",
				trailWidth: 0.1,
				svgStyle: {width: "100%", height: "100%"}
			});

			bar.animate(1.0);
		}
	}, [props.lifeTime, id]);

	let backgroundColor = "#cce8f4";
	let icon = <InfoIcon />;

	if (props.severity === Severity.success) {
		backgroundColor = "#def2d5";
		icon = <CheckCircleIcon />;
	}
	else if (props.severity === Severity.warning) {
		backgroundColor = "#f8f3d6";
		icon = <ErrorIcon />;
	}
	else if (props.severity === Severity.error) {
		backgroundColor = "#ebc8c2";
		icon = <ErrorIcon />;
	}

	return (
		<div style={{...styles.notificationMessage, backgroundColor}}>
			<div style={styles.close} onClick={close}><CloseIcon /></div>
			<div style={styles.icon}>{icon}</div>
			<div style={styles.title}>
				<p>{props.title}</p>
			</div>
			<div style={styles.body}>
				<p>{props.body}</p>
			</div>
			<div className="progressBar" style={styles.progressBar} id={id}></div>
		</div>
	);
};