import React, {useState, FC, useEffect, ReactElement, useContext} from "react";
import CloseIcon from "@mui/icons-material/Close";
import Select from "../../components/Select/Select";
import Modal from "../../Modal";
import {Button} from "../../components/Common/Button/Button";
import {useStyles} from "./style";
import {TextInput} from "../../components/form/textInput/textInput";
import {ISectionNewRequest} from "../../types/ISection";
import {IAcademy} from "../../types/IAcademy";
import coreService from "../../services/core.service";
import {SectionNewRequestTemplate} from "../../types/templates/TSection";
import {useValidate} from "../../hooks/useValidate";
import {sectionCreateRequestValidationDoc} from "../../types/validationDocs/user/sectionCreateRequestValidationDoc";
import {NotifyContext} from "../../context/NotifyContext";
import {Severity} from "../../types/INotify";
import {publishedOptions} from "../../constants";

interface IProps {
	open: boolean,
	onClose: () => void,
	onChange: (section: any) => void,
	academy: IAcademy
}

export const AddSectionModal: FC<IProps> = ({open, onClose, onChange, academy}) => {
	const [formData, setFormData] = useState<ISectionNewRequest>(SectionNewRequestTemplate(academy?.slug ?? ""));
	const [validationResult, runValidate, hasErrors, clearValidation] = useValidate<ISectionNewRequest>(sectionCreateRequestValidationDoc, formData);
	const {notify} = useContext(NotifyContext);

	useEffect(() => {
		setFormData(SectionNewRequestTemplate(academy?.slug ?? ""));
		clearValidation();
	}, [academy, open])

	const handleAddSection = async (): Promise<void> => {
		if(!runValidate()) return;
		const result = await coreService.createSection(formData);
		const success = result.status >= 200 && result.status < 300;
		if(success) onChange(result.data);
		notify(
			success ? `Successfully added ${formData.name}` : "Something went wrong",
			"Curriculum Section",
			success ? Severity.success : Severity.error);
		onClose();
	};

	const inputText = (label: string, prop: keyof ISectionNewRequest, type = "text"): ReactElement => <TextInput
		label={label}
		type={type}
		error={validationResult[prop]}
		value={formData[prop] as string}
		onChange={e => setFormData(prev => ({...prev, [prop]: e.target.value}))}
		onValidate={() => runValidate(prop)}
	/>

	return (
		<Modal isOpen={open} onClose={() => onClose()}>
			<div className="add_academy_collection">
				<span className="text_30">Add Section</span>
				<button
					className="close_btn"
					style={{width: 40}}
					type="button"
					onClick={() => onClose()}
				>
					<CloseIcon />
				</button>

				{inputText("Title", "name")}

				<Select
					label="Status"
					name="Status"
					error={validationResult["status"]}
					onValidate={() => runValidate("status")}
					options={publishedOptions}
					style={{height: "40px"}}
					value={formData.status}
					onSelect={(option) => setFormData(prev => ({...prev, status: option.value}))}
				/>

				{inputText("Label", "label")}

				<div style={{textAlign: "center", marginTop: 35}}>
					<Button	onClick={() => handleAddSection()} disabled={hasErrors}>
						Add Section
					</Button>
				</div>
			</div>
		</Modal>
	);
}

export default AddSectionModal;
