import React, {FC} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {IModule} from "../../../../types/IModule";
import {ISection} from "../../../../types/ISection";
import {useStyles} from "./style";

interface IProps {
	section: ISection,
	modules: IModule[]
}

export const SideBarSection: FC<IProps> = ({section, modules}) => {
	const {module_slug} = useParams();
	const {webinar_slug} = useParams();
	const classes = useStyles();
	const navigate = useNavigate();

	const triggerEditModule = (module: IModule): void => {
		if(module.type === "module" || module.type === "webinar")
			navigate(`/${module.type}/${module.academy}/${section.slug}/${module.slug}`);
	};

	return (
		<section className="curriculum__sidebar__section">
			<header className="text_15 curriculum__sidebar__section__header">
				<span className={classes.section}>{section.name}</span>
			</header>

			<div className="curriculum__sidebar__section__container">
				{modules?.map((module, i) => {
					const isSelected = ((module_slug ?? "") === module.slug)
						|| (webinar_slug ?? "") === module.slug;
					return (
						<span
							className={classes.module}
							key={i}
							onClick={() => {
								if(isSelected) return;
								triggerEditModule(module)
							}}
							style={isSelected ? {fontWeight: 600} : {}}
						>
							{module.name}
						</span>
					);
				})}
			</div>
		</section>
	);
}


export default SideBarSection;
