import React, {createContext, useReducer, ReactElement, Reducer, Dispatch, useEffect} from "react";
import {IMessage, Severity} from "../types/INotify";

const initialState: {
	notifyState: NotifyContextState;
	notifyDispatch: Dispatch<NotifyContextAction>;
	notify: (body: string, title?: string, severity?: Severity, lifeTime?: number) => void
} = {notifyState: [], notifyDispatch: () => {}, notify: () => {}};
const NotifyContext = createContext(initialState);

type NotifyContextState = IMessage[]

interface NotifyContextAction {
    type?: "add" | "clear" | "remove";
    payload?: IMessage;
}

const NotifyProvider = (props: { children: ReactElement }): JSX.Element => {

	const [notifyState, notifyDispatch] = useReducer<Reducer<IMessage[], NotifyContextAction>>((prevState, action) => {
		switch (action.type) {
			case "clear":
				return [];
			case "remove":
				if(action?.payload?.id) prevState = prevState.filter(e => e.id !== action?.payload?.id);
				return [...prevState];
			case "add":
			default: {
				const id = window.performance.now() + prevState.length;
				const newMsg = {...action.payload, id} as IMessage;

				//remove after lifeTime if present
				if(action?.payload?.lifeTime){
					setTimeout(() => {
						notifyDispatch({type: "remove", payload: newMsg});
					}, action.payload.lifeTime);
				}
				return [...prevState, newMsg];
			}
		}
	}, initialState.notifyState);

	const notify = (body: string, title = "", severity: Severity = Severity.success, lifeTime = 5000): void =>
		notifyDispatch({payload: {title, body, severity, lifeTime}});

	return (
		<NotifyContext.Provider value={{notifyState, notifyDispatch, notify}}>
			{props.children}
		</NotifyContext.Provider>
	);
};

export {
	NotifyContext,
	NotifyProvider
};
