import React, {FC} from "react";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import {useNavigate} from "react-router-dom";
import OndemandVideoIcon from "@mui/icons-material/OndemandVideo";
import {ISection} from "../../../../../types/ISection";
import {IModule} from "../../../../../types/IModule";
import {IAcademy} from "../../../../../types/IAcademy";
import {IWebinar} from "../../../../../types/IWebinar";
import PendingActionsIcon from "@mui/icons-material/PendingActions";
import {useStyles} from "./style";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import {Draggable} from "react-beautiful-dnd";

const PencilPaperIcon = "/assets/users-edit.svg";
const BinIcon = "/assets/bin.svg";

interface IProps {
	index: number,
	section: ISection,
	module: IModule | IWebinar,
	academy: IAcademy,
	onDeleteAction: () => void
}

export const ModuleListItem: FC<IProps> = ({index, section, module, academy, onDeleteAction}) => {
	const navigate = useNavigate();
	const classes = useStyles();

	const triggerEditModule = (): void => {
		if(module.type === "module" || module.type === "webinar")
			navigate(`/${module.type}/${academy.slug}/${section.slug}/${module.slug}`);
	};

	const getStatus = (): string | undefined => {
		if(module.is_cache)
			return "Pending Revision"
		else if (module.status === "draft")
			return ""; //delete in future clean up if change back not requested
	}

	return (
		<Draggable draggableId={"drag_" + module.slug} key={"drag_" + module.slug} index={index}>
			{provided => (
				<li
					className="container_section__list_item"
					style={{opacity: module.status === "draft" ? .9 : 1}}
					ref={provided.innerRef}
					{...provided.draggableProps}
				>
					<div className="left_wrapper">
						<button className="drag_button" {...provided.dragHandleProps}>
							<DragIndicatorIcon />
						</button>

						<div
							className="text_wrapper"
							style={{cursor: "pointer"}}
							onClick={() => triggerEditModule()}
						>
							<h3>{module.name}</h3>
						</div>
						{getStatus() && <p className={classes.unCommitedChangesLabel}>
						({getStatus()})
						</p>}
					</div>

					<div className="right_wrapper">
						{/* {module.is_cache && <PendingActionsIcon/>} */}
						{module.type === "webinar" && <OndemandVideoIcon/>}
						{module.status === "draft" && <VisibilityOffIcon/>}
						<button onClick={() => triggerEditModule()}>
							<img src={PencilPaperIcon} alt="" />
						</button>

						<button onClick={() => onDeleteAction()}>
							<img src={BinIcon} alt="" />
						</button>
					</div>
				</li>
			)}
		</Draggable>
	);
}

export default ModuleListItem;
