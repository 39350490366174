import {createUseStyles} from "react-jss";

export const useStyles = createUseStyles({
	curriculumDashboard: {
		height: "calc(100% - 120px)",
		width: "calc(100% - 300px)",
		overflowY: "auto",
		overflowX: "hidden",
		position: "absolute",
		right: 0,
		boxSizing: "border-box",
		background: "url(assets/background-hero.svg) 100% 30px / auto no-repeat fixed rgb(241, 242, 242)"
	},
	noneToShow: {
		padding: 20
	},
	dashboardContainer: {
		width: "100%",
		height: "100%",
		maxWidth: 1350,
		margin: "0 auto"
	},
	curriculumHome: {
		height: "100%",
		padding: "5rem 30px",
		position: "relative",
		boxSizing: "border-box",
		"& header": {
			display: "flex",
			justifyContent: "space-between",
			alignItems: "center",
			"& h1": {
				display: "flex",
				"& > span": {
					opacity: "0.5",
					textTransform: "capitalize",
					marginLeft: "1rem"
				}
			}
		},
		"& button": {
			width: 160,
			height: 50,
			backgroundColor: "transparent",
			border: "1px solid teal",
			borderRadius: 100,
			display: "flex",
			justifyContent: "center",
			alignItems: "center",
			gap: "1rem"
		}

	},
	closeBtn: {
		width: 80
	}
})
