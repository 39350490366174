import React, {FC, useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {Button} from "../../components/Common/Button/Button";
import userService from "../../services/user.service";

const BackgroundHero = "/assets/background-hero.svg";

export const Login: FC = () => {
	const navigate = useNavigate();
	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");
	const [error, setError] = useState(false);

	useEffect(() => {
		if(userService.getUser()){
			navigate("/dashboard");
		}
	}, [])

	const backgroundImageStyle = {
		background: `url(${BackgroundHero}) no-repeat`,
		backgroundSize: "100%",
		backgroundPositionY: "30px"
	};

	const handleSubmit = async (): Promise<void> => {
		userService
			.login(email, password)
			.then((res) => {
				const data = res.data;
				localStorage.setItem("user", JSON.stringify(data));
				navigate("/dashboard");
			})
			.catch(() => {
				setError(true);
			});
	};

	return (
		<div className="login" style={backgroundImageStyle}>
			<div className="login__container_left">
				<img src={"/assets/login-hero.webp"} alt="" />
				<div className="overlay"></div>
			</div>
			<div className="login__container_right">
				<div>
					<h1>Admin Login</h1>

					{error ? (
						<h3 className="login_error">Incorrect email or password.</h3>
					) : null}

					<div className="email">
						<label htmlFor="">Email</label>
						<input type="email" onChange={(e) => setEmail(e.target.value)} />
					</div>
					<div className="password">
						<label htmlFor="">Password</label>
						<input
							type="password"
							onChange={(e) => setPassword(e.target.value)}
						/>
					</div>
					<Button style={{width: "100%", marginTop: 40}} onClick={() => handleSubmit()}>Login</Button>
				</div>
			</div>
		</div>
	);
}

export default Login;
