import {ISectionEditRequest} from "../../ISection";
import {ValidationDoc} from "../../IValidation";
import {required} from "../common";

export const sectionEditRequestValidationDoc: ValidationDoc<ISectionEditRequest> = {
	name: [
		required("name")
	],
	label: [
		required("label")
	]
}

