import {IModule, IModuleEditRequest, IModulesBySectionMap} from "../IModule";

export const moduleToEditModuleRequest= (module: IModule): IModuleEditRequest => ({
	name: module.name,
	subtitle: module.subtitle,
	status: module.status,
	cache: false,
	video_url: module.video_url,
	h5p_url: module.h5p_url,
	content: module.content,
	type: "module"
});

export const modulesToSectionMap = (modules: IModule[]): IModulesBySectionMap => {
	const newMap: IModulesBySectionMap = {};
	modules.forEach(e => {
		const sectionKey = newMap[e.section];
		if(sectionKey) sectionKey.push(e);
		else newMap[e.section] = [e];
	})
	return newMap;
}


// const distinct = (value: any, index: number, self: any[]) =>
// 	self.indexOf(value) === index;

// export const modulesToSectionMap = (modules: IModule[]): ModulesBySectionMap => {
// 	const sessions = modules.map(e => e.section).filter(distinct);
// 	return sessions.map(e => ({
// 		sectionSlug: e,
// 		modules: modules.filter(m => m.section === e)
// 	}))
// }
