import {createUseStyles} from "react-jss";

export const useStyles = createUseStyles({
	inputCheckbox: {
		width: "100%",
		margin: "20px 0"
	},
	errorMessage: {
		color: "#f30000",
		fontSize: 15,
		boxSizing: "border-box",
		maxHeight: 0,
		transition: "max-height 0.15s ease-out",
		"& p": {
			padding: 10
		}
	},
	checkContainer: {
		paddingTop: 5,
		display: "flex",
		float: "right"
	},
	checkboxBtn: {
		width: 24,
		height: 24,
		borderRadius: 5,
		border: "1px solid soft-teal",
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		backgroundColor: "transparent"
	},
	preText: {
		marginRight: 15
	},
	postText: {
		marginLeft: 15
	}
})
