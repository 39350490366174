import React, {useState, FC, useEffect, ReactElement, useContext} from "react";
import CloseIcon from "@mui/icons-material/Close";
import Select from "../../components/Select/Select";
import Modal from "../../Modal";
import {Button} from "../../components/Common/Button/Button";
import coreService from "../../services/core.service";
import {useValidate} from "../../hooks/useValidate";
import {NotifyContext} from "../../context/NotifyContext";
import {Severity} from "../../types/INotify";
import {IWebinar, IWebinarSession, IWebinarSessionNewRequest} from "../../types/IWebinar";
import {WebinarSessionNewRequestTemplate} from "../../types/templates/TWebinar";
import {webinarSessionNewRequestValidationDoc} from "../../types/validationDocs/webinar/webinarSessionNewRequestValidationDoc";
import {DateToStringAPI, DayJsDateToStringAPI} from "../../common/dateManipulation";
import InputDate from "../../components/InputDate/InputDate";
import InputTime from "../../components/InputTime/InputTime";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import {timezoneOptions} from "../../constants";
import {CircularProgress} from "@mui/material";

dayjs.extend(utc);
dayjs.extend(timezone);

interface IProps {
	open: boolean,
	webinar: IWebinar,
	onClose: () => void,
	onChange: (session: IWebinarSession) => void
}

export const AddWebinarSessionModal: FC<IProps> = ({open, onClose, onChange, webinar}) => {
	const [formData, setFormData] = useState<IWebinarSessionNewRequest>(WebinarSessionNewRequestTemplate(webinar.slug));
	const [validationResult, runValidate, hasErrors, clearValidation] = useValidate<IWebinarSessionNewRequest>(webinarSessionNewRequestValidationDoc, formData);
	const {notify} = useContext(NotifyContext);
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		setFormData(WebinarSessionNewRequestTemplate(webinar.slug));
		clearValidation();
	}, [webinar.slug, open])

	const handleAddSection = async (): Promise<void> => {
		if(!runValidate() || !formData.timezone) return;
		//covert date time to be the date time stated but in request the time zone 
		const convertedDate = dayjs.tz(DateToStringAPI(new Date(formData.date)), formData.timezone).tz("Etc/UTC");

		const submissionData = {...formData, date: DayJsDateToStringAPI(convertedDate)}
		setLoading(true);
		const result = await coreService.createWebinarSession(submissionData);
		setLoading(false);
		const success = result.status >= 200 && result.status < 300;
		if(success) onChange(result.data);
		notify(
			success ? "Successfully added session" : "Something went wrong",
			"Webinar Session",
			success ? Severity.success : Severity.error);
		onClose();
	};

	const inputDate = (label: string, prop: keyof IWebinarSessionNewRequest): ReactElement => <InputDate
		label={label}
		error={validationResult[prop]}
		value={dayjs(formData[prop])}
		onChange={e => setFormData(prev => ({...prev, [prop]: e?.toUTCString()}))}
		onValidate={() => runValidate(prop)}	/>

	const inputTime = (label: string, prop: keyof IWebinarSessionNewRequest): ReactElement => <InputTime
		label={label}
		error={validationResult[prop]}
		value={dayjs(formData[prop])}
		onChange={e => setFormData(prev => ({...prev, [prop]: e?.toUTCString()}))}
		onValidate={() => runValidate(prop)}	/>

	const inputSelect = (label: string, prop: keyof IWebinarSessionNewRequest, options: {label: string, value: string}[]): ReactElement => <Select
		label={label}
		error={validationResult[prop]}
		value={formData[prop] as string}
		options={options}
		onSelect={(option) => setFormData(prev => ({...prev, [prop]: option.value}))}
		onValidate={() => runValidate(prop)}	/>

	return (
		<Modal isOpen={open} onClose={() => onClose()}>
			<div className="add_academy_collection">
				<span className="text_30">Add Webinar Session</span>
				<br />
				<button
					className="close_btn"
					style={{width: 40}}
					type="button"
					onClick={() => onClose()}
				>
					<CloseIcon />
				</button>

				{inputDate("Date", "date")}
				{inputTime("Time", "date")}
				{inputSelect("TimeZone", "timezone", timezoneOptions)}

				<div style={{textAlign: "center", marginTop: 35}}>
					<Button	onClick={() => handleAddSection()} disabled={hasErrors || loading}>
						{loading ? <CircularProgress /> : "Add Session "}
					</Button>
				</div>
			</div>
		</Modal>
	);
}

export default AddWebinarSessionModal;
