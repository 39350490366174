import React, {FC} from "react";
import {roleOptions} from "../../../../constants";
import Select, {selectOption} from "../../../../components/Select/Select";

interface IProps {
	onSelect: (e: selectOption) => void,
	value: string
}

export const RoleSelect: FC<IProps> = ({onSelect, value}) => (
	<div className="role_drop_down">
		<Select
			options={roleOptions}
			value={value ?? roleOptions[0].value}
			onSelect={onSelect}
		/>
	</div>
)

export default RoleSelect;
