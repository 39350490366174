import React, {FC, useContext, useEffect, useState} from "react";
import CloseIcon from "@mui/icons-material/Close";
import coreService from "../../services/core.service";
import CertificateBackgroundButton from "../../components/CertificateBackgroundButton/CertificateBackgroundButton";
import Select from "../../components/Select/Select";
import {languages} from "../../constants/index";
import {NotifyContext} from "../../context/NotifyContext";
import {Severity} from "../../types/INotify";
import Modal from "../../Modal";
import {IAcademyCollection} from "../../types/IAcademyCollection";
import {IAcademy, IAcademyCreateRequest} from "../../types/IAcademy";
import {AcademyCreateRequestTemplate} from "../../types/templates/TAcademy";
import {useValidate} from "../../hooks/useValidate";
import {academyCreateRequestValidationDoc} from "../../types/validationDocs/academy/academyCreateRequestValidationDoc";
import {TextInput} from "../../components/form/textInput/textInput";

interface IProps {
	collection: IAcademyCollection,
	onChange: (e: IAcademy) => void,
	open: boolean,
	onClose: () => void
}

const AddAcademyModal: FC<IProps> = ({collection, onChange, open, onClose}) => {
	const {notify} = useContext(NotifyContext);
	const [formData, setFormData] = useState<IAcademyCreateRequest>(AcademyCreateRequestTemplate(collection.slug));
	const [validationResult, runValidate, hasErrors, resetErrors] = useValidate<IAcademyCreateRequest>(academyCreateRequestValidationDoc, formData);

	useEffect(() => {
		resetErrors();
		setFormData(AcademyCreateRequestTemplate(collection.slug))
	}, [open, collection.slug])

	const handleSubmit = async (): Promise<void> => {
		if(!runValidate()) return;
		const payload = new FormData();
		// Loop through the object
		for (const [key, val] of Object.entries(formData)) {
			payload.append(key, val);
		}
		await coreService
			.createAcademy(payload, {"Content-Type": "multipart/form-data"})
			.then((res) => {
				onChange(res.data);
				onClose();
				notify(`Successfully added ${formData.name}.`, "New Academy");
			})
			.catch(() => {
				notify(`Error adding ${formData.name}.`, "New Academy", Severity.error);
			});
	};

	return <>
		<Modal isOpen={open} onClose={() => onClose()}>
			<div
				className="add_academy_form"
				id={"academy-creation-form"}
			>
				<button
					type="button"
					className="close_panel"
					onClick={() => onClose()}
				>
					<CloseIcon />
				</button>

				<div className={"content"}>
					<header>
						<span className="text_30">Create Academy</span>
					</header>

					<TextInput
						label="Title"
						value={formData.name}
						error={validationResult["name"]}
						onValidate={() => runValidate("name")}
						onChange={e => setFormData(prev => ({...prev, name: e.target.value}))}
					/>

					<Select
						label="Language Code"
						className="custom"
						error={validationResult["language"]}
						onValidate={() => runValidate("language")}
						options={languages}
						name={"language"}
						placeholder={"Select Language"}
						onSelect={e => setFormData(prev => ({...prev, language: e.value}))}
						value={formData.language}
					/>

					<div className="certificate">
						<span>certificate background</span>
						<CertificateBackgroundButton
							open={open}
							name={"certificate"}
							onChange={(certificate_background) => setFormData(prev => ({...prev, certificate_background}))}
						/>
					</div>
					<div className="error-message" style={{maxHeight: validationResult["certificate_background"] ? 50 : 5}}>
						<p>
							{validationResult["certificate_background"]?.length === 0
								? "Please attach a certificate background"
								: validationResult["certificate_background"]}
						</p>
					</div>
				</div>

				<div className="submit_button">
					<div className="button_wrapper">
						<button disabled={hasErrors} onClick={() => handleSubmit()}>Save Changes</button>
					</div>
				</div>
			</div>,
		</Modal>
	</>;
}

export default AddAcademyModal;