import {IModuleEditRequest} from "../../IModule";
import {ValidationDoc} from "../../IValidation";
import {required} from "../common";

export const moduleEditRequestValidationDoc: ValidationDoc<IModuleEditRequest> = {
	name: [
		required("name")
	],
	video_url: [
		{
			errorMessage: "Please enter a valid Vimeo link",//substring
			validate: (e) => !e.video_url || e.video_url?.substr(0, 31) === "https://player.vimeo.com/video/" && e.video_url.length > 31
		}
	],
	h5p_url: [
		{
			errorMessage: "Please enter a valid HP5 link (public external link from nvisionu.h5p.com)",
			validate: (e) => !e.h5p_url || e.h5p_url?.substr(0, 25) === "https://nvisionu.h5p.com/" && e.h5p_url.length > 25 && !e.h5p_url.includes("embed")
		}
	]
}

