import React, {FC, useState, useContext, useEffect} from "react";
import CloseIcon from "@mui/icons-material/Close";
import coreService from "../../services/core.service";
import {NotifyContext} from "../../context/NotifyContext";
import {Severity} from "../../types/INotify";
import Modal from "../../Modal";
import {TextInput} from "../../components/form/textInput/textInput";
import {IAcademyCollection, IAcademyCollectionEditRequest} from "../../types/IAcademyCollection";
import {useValidate} from "../../hooks/useValidate";
import {academyCollectionToEditacademyCollectionRequest} from "../../types/mappers/academyCollection";
import {academyCollectionEditRequestValidationDoc} from "../../types/validationDocs/academyCollection/academyCollectionEditRequestValidationDoc";
import useDepLog from "../../hooks/useDepLog";

interface IProps {
	collection: IAcademyCollection,
	open: boolean,
	onClose: () => void,
	onChange: (e: IAcademyCollection) => void
}

export const EditAcademyCollectionModal: FC<IProps> = ({collection, open, onClose, onChange}) => {
	const [formData, setFormData] = useState<IAcademyCollectionEditRequest>(academyCollectionToEditacademyCollectionRequest(collection));
	const {notify} = useContext(NotifyContext);
	const [validationResult, runValidate, hasErrors, clearValidation] = useValidate<IAcademyCollectionEditRequest>(academyCollectionEditRequestValidationDoc, formData);

	useDepLog("formData", formData);

	useDepLog("validationResult", validationResult);

	useEffect(() => {
		if(!collection) return;
		clearValidation();
		setFormData(academyCollectionToEditacademyCollectionRequest(collection));
	}, [collection])

	const handleCollectionEdit = async (): Promise<void> => {
		if(!runValidate()) return;
		const result = await coreService.updateCollection(collection.slug, formData, {});
		const success = result.status === 200;

		notify(
			success ? "Successfully updated name" : "Something when wrong",
			"Collection",
			success ? Severity.success : Severity.error);

		onChange(result.data)
		onClose();
	};

	if(!collection) return <></>
	return (
		<Modal isOpen={open} onClose={() => onClose()}>
			<div className="add_academy_collection">
				<span className="text_30">Edit Academy Collection</span>
				<button
					className="close_btn"
					type="button"
					onClick={() => onClose()}
				>
					<CloseIcon />
				</button>
				<TextInput
					label="Title"
					value={formData.name}
					onValidate={() => runValidate("name")}
					error={validationResult?.name}
					onChange={(e) => setFormData(prev => ({...prev, name: e.target.value}))}
				/>
				<TextInput
					label="Direct Scale Service Id"
					value={formData.direct_scale_service_id}
					onValidate={() => runValidate("direct_scale_service_id")}
					type="number"
					error={validationResult?.direct_scale_service_id}
					onChange={(e) => setFormData(prev => ({...prev, direct_scale_service_id: Number(e.target.value)}))}
					style={{marginBottom: 100}}
				/>
				<footer className="add_academy_collection__footer">
					<div className="button_wrapper">
						<button
							disabled={hasErrors}
							type="submit"
							className="submit_button"
							onClick={() => handleCollectionEdit()}
						>
							Save
						</button>
					</div>
				</footer>
			</div>
		</Modal>
	);
}

export default EditAcademyCollectionModal;