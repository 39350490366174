//This component is seriously flawed and should be replaced
import {Done, KeyboardArrowDown} from "@mui/icons-material";
import React, {useRef, useState, useEffect, FC, ReactElement} from "react";
import useOnClickOutside from "../../hooks/useOnClickOutside";

export type selectOption = {value: string, label: string}

interface IProps {
	value: string,
	onSelect: (value: selectOption) => void,
	options: selectOption[],
	name?: string,
	placeholder?: string,
	style?: any,
	icon?: ReactElement,
	label?: string,
	className?: string,
	error?: string,
	onValidate?: () => void,
}

export const Select: FC<IProps> = ({className, value, onSelect, label, options, name, placeholder, style, icon, error, onValidate}) => {
	const valid = error === undefined;
	const validationRequired = useRef(false);

	const getOption = (v: string | selectOption): selectOption => {
		const option = options.find((e) => v && (e.value === v || e === v));
		if (option) return option;
		return {value: "", label: placeholder ?? ""};
	};

	const [selectedOption, setSelectedOption] = useState(getOption(value));
	const [dropdownOpen, setDropDownOpen] = useState(false);
	const ref = useRef<HTMLDivElement>(null);

	useOnClickOutside(ref, () => setDropDownOpen(false));

	const onClick = (option: selectOption): void => {
		setDropDownOpen(false);
		setSelectedOption(option);
		onSelect(option);
		const elem = document.getElementById("select-dropdown") as HTMLInputElement;
		elem.value = option.value;
		validationRequired.current = true;
	};

	useEffect(() => {
		if(value && validationRequired.current) {
			onValidate?.();
			validationRequired.current = false;
		}
	}, [value])

	useEffect(() => setSelectedOption(getOption(value)), [value]);

	const dropDownStyle = {
		display: dropdownOpen ? "" : "none",
		top: dropdownOpen ? "0" : "-100vh",
		opacity: dropdownOpen ? "1" : "0"
	};

	return (
		<>
			{label && <label htmlFor="">{label}</label>}
			<div
				className={"drop_down " + className}
				ref={ref}
				style={{...(style ?? {}), ...(valid ? {} : {border: "2px solid #f30000"})}}
			>
				<div className="current" onClick={() => setDropDownOpen(true)}>
					{icon && icon}
					{selectedOption.label}
					<KeyboardArrowDown />
				</div>

				<ul className="options" style={dropDownStyle}>
					{options.map((option) => (
						<li onClick={() => onClick(option)} key={option.label}>
							{option.label}
							<Done
								className="done_icon"
								style={
									selectedOption.value === option.value
										? {opacity: "1"}
										: {opacity: "0"}
								}
							/>
						</li>
					))}
				</ul>

				<select
					id={"select-dropdown"}
					defaultValue={selectedOption.value}
					name={name}
					style={{display: "none"}}
				>
					<option value={selectedOption.value}>{selectedOption.label}</option>
				</select>
			</div>
			<div className="error-message" style={{maxHeight: error ? 50 : 5}}>
				<p>
					{error ?? " "}
				</p>
			</div>
		</>
	);
}

export default Select;
