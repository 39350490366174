import {IAcademyCollectionEditRequest} from "../../IAcademyCollection";
import {ValidationDoc} from "../../IValidation";
import {required} from "../common";

export const academyCollectionEditRequestValidationDoc: ValidationDoc<IAcademyCollectionEditRequest> = {
	name: [
		required("name")
	],
	direct_scale_service_id: [
		required("direct_scale_service_id")
	]
}

