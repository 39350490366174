import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import {NotifyProvider} from "./context/NotifyContext";
import "./index.scss";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
	<NotifyProvider>
		<App />
	</NotifyProvider>
);

