import {useQuery} from "@tanstack/react-query";
import coreService from "../services/core.service";

export const useAcademyData = (slug) => {
	const fetchAcademy = async () => {
		return await coreService.getAcademy(slug, {}, {}).then((res) => res.data);
	};

	const {data, error, isFetching, refetch, isLoading} = useQuery(
		["academy"],
		fetchAcademy,
		{refetchOnWindowFocus: false}
	);

	return {
		data,
		error,
		isFetching,
		isLoading,
		refetch
	};
};
