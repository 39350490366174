import React, {useState} from "react";

function Footer({
	onAddAcademyAction
}) {
	return (
		<footer className="home_dashboard__dashboard_container__footer">
			<div className="button_wrapper">
				<button onClick={() => onAddAcademyAction()}>
					+ Academy Collection
				</button>
			</div>


		</footer>
	);
}

export default Footer;
