import {roleOptions} from "../../constants";
import {IUserCreateRequest, ISessionUserPasswordUpdateRequest, IUserPasswordUpdateRequest} from "../IUser";


//Session user = current logged in user
export const SessionUserPasswordUpdateRequestTemplate: ISessionUserPasswordUpdateRequest = ({
	confirm_password: "",
	current_password: "",
	new_password: ""
});

//any user (can only by done by admins). Current password info not needed
export const UserPasswordUpdateRequestTemplate: IUserPasswordUpdateRequest = ({
	confirm_password: "",
	new_password: ""
});


export const UserCreateRequestTemplate: IUserCreateRequest = ({
	first_name: "",
	last_name: "",
	role: roleOptions[0].value,
	email: ""
});
