import React, {FC, useContext} from "react";
import ConfirmationPopUp from "../../components/ConfirmationPopUp/ConfirmationPopUp";
import {NotifyContext} from "../../context/NotifyContext";
import Modal from "../../Modal";
import coreService from "../../services/core.service";
import {Severity} from "../../types/INotify";
import {IWebinarSession} from "../../types/IWebinar";

interface IProps {
	open: boolean,
	onClose: () => void,
	session: IWebinarSession,
	onChange: (section: IWebinarSession) => void
}

export const DeleteWebinarSessionModal: FC<IProps> = ({open, onClose, session, onChange}) => {
	const {notify} = useContext(NotifyContext);

	const handleSessionDelete = async (): Promise<void> => {
		await coreService
			.deleteWebinarSession(session.slug, {})
			.then(() => {
				onChange(session);
				onClose();
				notify(`${session.formatted_name} deleted.`, "Delete Session");
			})
			.catch((err) => {
				notify(err.response.data.message, "Delete Session", Severity.error);
			});
	};

	if(!session) return <></>
	return (
		<Modal isOpen={open} onClose={() => onClose()}>
			<ConfirmationPopUp
				message={`Are you sure you want to delete "${session.formatted_name}"?`}
				set_modal={() => onClose()}
				button_title="Delete Session"
				action={handleSessionDelete}
			/>
		</Modal>
	)
}

export default DeleteWebinarSessionModal;
