import {createUseStyles} from "react-jss";

export const useStyles = createUseStyles({
	button: {
		fontSize: "1rem",
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		height: 60,
		width: 220,
		borderRadius: 100,
		border: "none",
		color: "white",
		background: "linear-gradient(90deg, #262261 0%, #6BCAD2 100%)",
		"& svg": {
			paddingRight: 5
		}
	}
})
