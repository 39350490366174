import React, {FC} from "react";
import {IAcademy} from "../../../../types/IAcademy";
import {IAcademyCollection} from "../../../../types/IAcademyCollection";
import AcademyItem from "./components/AcademyItem/AcademyItem";
import AcadamyCollectionHeader from "./components/Header/AcadamyCollectionHeader";

interface IProps {
	collection: IAcademyCollection,
	onAcademyChange: (e: IAcademy) => void
	onEditAction: (e: IAcademyCollection) => void,
	onDeleteAction: () => void,
	onAddAcademyAction: () => void,
	onEditAcademyAction: (e: IAcademy) => void,
	onDeleteAcademyAction: (e: IAcademy) => void,
}

export const AcademyCollection: FC<IProps> = ({collection, onAcademyChange, ...propActions}) => {
	return (
		<div className="academy_collection">
			<AcadamyCollectionHeader
				collection={collection}
				onDeleteAction={propActions.onDeleteAction}
				onEditAction={propActions.onEditAction}
			/>

			<div className="sub_academy_collections">
				<ul>
					{collection.academies.map((academy, i) =>
						<AcademyItem
							key={i}
							academy={academy}
							collection={collection}
							language={academy.language}
							onEditAction={() => propActions.onEditAcademyAction(academy)}
							onDeleteAction={() => propActions.onDeleteAcademyAction(academy)}
							onChange={e => onAcademyChange(e)}
						/>
					)}
				</ul>
			</div>

			<button
				className="add_academy"
				onClick={() => propActions.onAddAcademyAction()}
			>
				+ Academy
			</button>
		</div>
	);
}

export default AcademyCollection;
