import {IAcademyCollectionCreateRequest} from "../../IAcademyCollection";
import {ValidationDoc} from "../../IValidation";
import {required} from "../common";

export const academyCollectionCreateRequestValidationDoc: ValidationDoc<IAcademyCollectionCreateRequest> = {
	name: [
		required("name")
	],
	direct_scale_service_id: [
		required("direct_scale_service_id")
	]
}

