import React, {FC, useContext, useEffect, useState} from "react";
import CloseIcon from "@mui/icons-material/Close";
import coreService from "../../services/core.service";
import {NotifyContext} from "../../context/NotifyContext";
import {Severity} from "../../types/INotify";
import Modal from "../../Modal";
import {TextInput} from "../../components/form/textInput/textInput";
import {useValidate} from "../../hooks/useValidate";
import {IAcademyCollectionCreateRequest} from "../../types/IAcademyCollection";
import {academyCollectionCreateRequestValidationDoc} from "../../types/validationDocs/academyCollection/academyCollectionCreateRequestValidationDoc";
import {AcademyCollectionCreateRequestTemplate} from "../../types/templates/TAcademyCollection";

interface IProps {
	onChange: (e: any) => void,
	open: boolean,
	onClose: () => void
}

export const AddAcademyCollectionModal: FC<IProps> = ({onChange, open, onClose}) => {
	const {notify} = useContext(NotifyContext);
	const [formData, setFormData] = useState<IAcademyCollectionCreateRequest>(AcademyCollectionCreateRequestTemplate);
	const [validationResult, runValidate, hasErrors, resetErrors] = useValidate<IAcademyCollectionCreateRequest>(academyCollectionCreateRequestValidationDoc, formData);

	const submitCollection = (): void => {
		if(!runValidate()) return;
		coreService
			.createCollection(formData, {})
			.then((res) => {
				onChange(res.data);
				notify("Added Successfully", "New Academy Collection");
				onClose();
			})
			.catch(() => {
				notify("An Error Occured", "New Academy Collection", Severity.error);
			});
	};

	useEffect(() => {
		setFormData({name: ""});
		resetErrors();
	}, [open])

	return (
		<Modal isOpen={open} onClose={() => onClose()}>
			<div className="add_academy_collection">
				<span className="text_30">Add Academy Collection</span>
				<button
					className="close_btn"
					onClick={() => onClose()}
					type="button"
				>
					<CloseIcon />
				</button>
				<h4></h4>
				<TextInput
					label="Title"
					value={formData.name}
					error={validationResult["name"]}
					onValidate={() => runValidate("name")}
					onChange={e => setFormData(prev => ({...prev, name: e.target.value}))}
				/>
				<TextInput
					label="Direct Scale Service Id"
					value={formData.direct_scale_service_id}
					onValidate={() => runValidate("direct_scale_service_id")}
					type="number"
					error={validationResult?.direct_scale_service_id}
					onChange={(e) => setFormData(prev => ({...prev, direct_scale_service_id: Number(e.target.value)}))}
					style={{marginBottom: 100}}
				/>
				<br /><br /><br /><br />
				<footer className="add_academy_collection__footer">
					<div className="button_wrapper">
						<button disabled={hasErrors} className="submit_button" onClick={() => submitCollection()}>
							Add Academy Collection
						</button>
					</div>
				</footer>
			</div>
		</Modal>
	);
}