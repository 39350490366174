import {IWebhook} from "../IWebhook";
import {ValidationDoc} from "../IValidation";
import {required} from "./common";

export const webhookValidationDoc: ValidationDoc<IWebhook> = {
	url: [
		required("url")
	]
}

