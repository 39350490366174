

import React, {FC} from "react";
import {useStyles} from "./style";
import InputSwitch from "../../../../components/InputSwitch/InputSwitch";

//const PreviewIcon = "/assets/external-icon.svg";

interface IProps {
	academyName: string,
	academyLanguage: string
}

export const CurriculumHeader: FC<IProps> = ({academyName, academyLanguage}) => {
	//const {notify} = useContext(NotifyContext);
	const classes = useStyles();

	// const handlePreview = (): void => {
	// 	notify("This feature is not yet availible", "Curriculum Preview", Severity.warning);
	// }

	return (
		<>
			<header className={classes.curriculumHeader}>
				<h1>
					{academyName}
					<span>({academyLanguage})</span>
				</h1>

				{/* <button onClick={() => handlePreview()} className="curriculum__home__header__button">
					<span>Preview</span>
					<img src={PreviewIcon} alt="" />
				</button> */}
			</header>
			{/* <InputSwitch
				className={classes.cacheSwitch}
				value={showCached}
				onChange={() => onShowCachedChange(!showCached)}
				text={"Show Staged Changes"}
			/> */}

		</>
	);
}


