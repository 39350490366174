import React, {FC, useState, ReactElement} from "react";
import {Button} from "../../../../components/Common/Button/Button";
import {Container} from "../../../../components/Common/Container/Container";
import {IWebinarSession} from "../../../../types/IWebinar";
import SessionListItem from "./SessionListItem/SessionListItem";
import {useStyles} from "./style";

interface IProps {
	sessions: IWebinarSession[],
	onEditRequest: (e: IWebinarSession) => void,
	onDeleteRequest: (e: IWebinarSession) => void,
	onAddRequest: () => void
}

export const Sessions: FC<IProps> = ({sessions, onEditRequest, onDeleteRequest, onAddRequest}) => {
	const classes = useStyles();
	const [historyFilter, setHistoryFilter] = useState<"Upcoming" | "Previous">("Upcoming")

	const historyToggle = (filter: "Upcoming" | "Previous"): ReactElement =>
		<h2
			className={classes.historyToggle}
			onClick= {() => setHistoryFilter(filter)}
			style={{opacity: filter === historyFilter ? 1 : 0.7}}
		>
			{filter}
		</h2>

	const sessionsFiltered = sessions.filter(e =>
		(historyFilter === "Upcoming" && new Date(e.date) > new Date()) ||
		(historyFilter === "Previous" && new Date(e.date) <= new Date())
	);

	return (
		<div className={classes.sessions}>
			{sessions.length > 0 && <>
				{historyToggle("Upcoming")}
				{historyToggle("Previous")}
			</>}
			{sessionsFiltered
				.map((e, i) => (
					<SessionListItem
						session={e}
						key={i}
						onEditRequest={() => onEditRequest(e)}
						onDeleteRequest={() => onDeleteRequest(e)}
					/>
				))}
			{sessionsFiltered.length === 0 && <Container style={{padding: "20px 20px 10px 20px"}}>No {historyFilter.toLocaleLowerCase()} sessions available<br/><br/></Container>}
			<Button className={classes.addSessionBtn} onClick={() => onAddRequest()}>
					Add Session
			</Button>
		</div>
	);
}

export default Sessions;
