import {createUseStyles} from "react-jss";

export const useStyles = createUseStyles({
	sessions: {
		marginTop: 30
	},
	historyToggle: {
		cursor: "pointer",
		display: "inline-block",
		marginRight: 30,
		fontSize: 22,
		marginBottom: 10
	},
	title: {
		marginBottom: 20
	},
	addSessionBtn: {
		marginTop: 40
	}
})
