import React, {FC, ReactElement, useCallback, useContext, useEffect, useState} from "react";
import {useEditUser} from "../../../../../api/useUsersData";
import {Button} from "../../../../../components/Common/Button/Button";
import ConfirmationPopUp from "../../../../../components/ConfirmationPopUp/ConfirmationPopUp";
import {TextInput} from "../../../../../components/form/textInput/textInput";
import {useValidate} from "../../../../../hooks/useValidate";
import Modal from "../../../../../Modal";
import {UserPasswordUpdateRequestTemplate} from "../../../../../types/templates/TUser";
import {IUser, IUserPasswordUpdateRequest} from "../../../../../types/IUser";
import {UserPasswordUpdateRequestValidationDoc} from "../../../../../types/validationDocs/user/userPasswordUpdateRequestValidationDoc";
import PasswordValidation from "../../../../Settings/PasswordValidation";
import {NotifyContext} from "../../../../../context/NotifyContext";

interface IProps {
    user: IUser
}

export const EditUserPasswordForm: FC<IProps> = ({user}) => {
	const [passwordInputFocus, setPasswordInputFocus] = useState(false);
	const [confirmModalOpen, setConfirmModalOpen] = useState<boolean>(false);
	const [formData, setFormData] = useState<IUserPasswordUpdateRequest>({...UserPasswordUpdateRequestTemplate});
	const [validationResult, runValidate, hasErrors, clearValidation] = useValidate<IUserPasswordUpdateRequest>(UserPasswordUpdateRequestValidationDoc, formData);
	const {mutate: editUser, isSuccess} = useEditUser(user.slug);
	const {notify} = useContext(NotifyContext);

	useEffect(() => {
		setFormData({...UserPasswordUpdateRequestTemplate});
		clearValidation();
	}, [user]);

	const handleChange = (prop: keyof IUserPasswordUpdateRequest, value: string): void =>
		setFormData(prev => ({
			...prev,
			[prop]: value
		}));

	const handleSubmit = useCallback(() => {
		setConfirmModalOpen(false);
		if(!runValidate()) return;
		editUser({password: formData.new_password});
	}, [formData, editUser]);

	useEffect(() => {
		if(isSuccess) notify("Password has been successfully changed.", "Settings")
	}, [isSuccess])

	const inputPassword = (label: string, prop: keyof IUserPasswordUpdateRequest, additionalProps?: any): ReactElement => <TextInput
		label={label}
		type="password"
		error={validationResult[prop]}
		value={formData[prop]}
		onChange={(e) => handleChange(prop, e.target.value)}
		onValidate={() => runValidate(prop)}
		{...additionalProps}
	/>

	return (
		<>
			{inputPassword("New Password", "new_password", {onFocus: () => setPasswordInputFocus(true)})}

			<div style={{transition: "max-height 1.5s ease-out", overflow: "hidden", maxHeight: passwordInputFocus ? 400 : 0}}>
				<PasswordValidation password={formData.new_password} />
			</div>

			{inputPassword("Confirm Password", "confirm_password")}


			<div style={{textAlign: "right", marginTop: 35}}>
				<Button
					disabled={hasErrors || formData.confirm_password === ""}
					onClick={() => setConfirmModalOpen(true)}
				>
                    Submit
				</Button>
			</div>

			<Modal isOpen={confirmModalOpen} onClose={() => setConfirmModalOpen(false)}>
				<ConfirmationPopUp
					set_modal={setConfirmModalOpen}
					message="Are you sure you want to change the password?"
					button_title="Confirm"
					action={() => { handleSubmit() }}
				/>
			</Modal>
		</>
	);
};
