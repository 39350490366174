import React, {useState, useCallback, FC, useEffect} from "react";
import {Editor, EditorState, RichUtils} from "draft-js";
import {stateToHTML} from "draft-js-export-html";
import {stateFromHTML} from "draft-js-import-html";
import useDebounce from "../../../../hooks/useDebounce";

const BoldIcon = "/assets/bold-icon.svg";
const ItalicsIcon = "/assets/italics-icon.svg";
const UnderlineIcon = "/assets/underline-icon.svg";

interface IProps {
	value: string,
	onChange: (e: string) => void
}

export const TextEditor: FC<IProps> = ({value, onChange}) => {
	const [editor, setEditor] = useState(EditorState.createWithContent(stateFromHTML(value)));
	const debouncedEditor = useDebounce(editor, 300);

	const handleKeyCommand = useCallback((command: any, editor: any) => {
		const newState = RichUtils.handleKeyCommand(editor, command);
		if (newState) {
			setEditor(newState);

			return "handled";
		}
		return "not-handled";
	}, []);

	useEffect(() => {
		onChange(stateToHTML(debouncedEditor.getCurrentContent()));
	}, [debouncedEditor]);

	useEffect(() => {
		setEditor(prev => {
			const content = stateToHTML(prev.getCurrentContent());
			if(content !== value)
				return EditorState.createWithContent(stateFromHTML(value))
			else
				return prev;
		});
	}, [value]);

	return (
		<React.Fragment>
			<div className="text_editor">
				<div className="text_editor__editor">
					<Editor
						editorState={editor}
						handleKeyCommand={handleKeyCommand}
						onChange={setEditor}
					/>
				</div>

				<div className="text_editor__button_wrapper">
					<button onClick={() => setEditor(RichUtils.toggleInlineStyle(editor, "BOLD"))}>
						<img src={BoldIcon} alt="" />
					</button>
					<button onClick={() => setEditor(RichUtils.toggleInlineStyle(editor, "ITALIC"))}>
						<img src={ItalicsIcon} alt="" />
					</button>
					<button onClick={() => setEditor(RichUtils.toggleInlineStyle(editor, "UNDERLINE"))}>
						<img src={UnderlineIcon} alt="" />
					</button>
				</div>
			</div>
		</React.Fragment>
	);
}

export default TextEditor;
