import React, {FC, useEffect, ReactElement} from "react";

interface IPropsCustom {
	className?: string,
    error?: string,
    label?: string,
	onValidate?: () => void,
	appendIcon?: ReactElement
}

type IProps = React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> & IPropsCustom;

export const TextInput: FC<IProps> = ({className, error, label, appendIcon, onValidate, value, ...props}) => {
	const valid = error === undefined;

	useEffect(() => {
		if(error !== undefined){
			onValidate?.();
		}
	}, [value])

	return (
		<div className={"TextInput " + (className ?? "")}>
			{label && <label htmlFor="">{label}</label>}
			<input
				{...props}
				value={value ?? ""}
				style={{...(props.style ?? {}), ...(valid ? {} : {border: "2px solid #f30000"})}}
				type={props.type}
				name={props.name ?? label?.toLocaleLowerCase()?.replace(" ", "_") ?? ""}
				onBlur={e => {
					props.onBlur?.(e);
					onValidate?.();
				}}
			/>
			{appendIcon && <div className="TextInputAppendIcon">{appendIcon}</div>}
			<div className="error-message" style={{maxHeight: error ? 50 : 5}}>
				<p>
					{error ?? " "}
				</p>
			</div>
		</div>
	);
};