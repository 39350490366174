import React, {FC} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import userService from "../../services/user.service";

const HomeIcon = "/assets/home-logo.svg";
const UsersIcon = "/assets/users-logo.svg";
const SettingsIcon = "/assets/settings-logo.svg";
const WebhookIcon = "/assets/publish-icon.svg";
const Logo = "/assets/logo.svg";
const LogoutIcon = "/assets/logout-logo.svg";

export const NavSidebar: FC = () => {
	const isAdmin = userService.isAdmin();
	const navigate = useNavigate();
	const location = useLocation();

	return (
		<div className="home__sidebar">
			<img src={Logo} alt="" className="logo" />
			<nav>
				<li
					onClick={() => navigate("/dashboard")}
					className={location.pathname === "/dashboard" ? "active" : ""}
				>
					<div className="icon_wrapper">
						<img src={HomeIcon} alt="" />
					</div>
					<h2>Dashboard</h2>
				</li>
				{isAdmin && (
					<li
						onClick={() => navigate("/user")}
						className={location.pathname === "/user" ? "active" : ""}
					>
						<div className="icon_wrapper">
							<img src={UsersIcon} alt="" />
						</div>
						<h2>User</h2>
					</li>
				)}
				<li
					onClick={() => navigate("/settings")}
					className={location.pathname === "/settings" ? "active" : ""}
				>
					<div className="icon_wrapper">
						<img src={SettingsIcon} alt="" />
					</div>
					<h2>Settings</h2>
				</li>
				<li
					onClick={() => navigate("/webhook")}
					className={location.pathname === "/webhook" ? "active" : ""}
				>
					<div className="icon_wrapper">
						<img src={WebhookIcon} alt="" />
					</div>
					<h2>Webhook</h2>
				</li>
				<li
					onClick={() => userService.logout()}
					className={location.pathname === "/logout" ? "active" : ""}
				>
					<div className="icon_wrapper">
						<img src={LogoutIcon} alt="" />
					</div>
					<h2>Logout</h2>
				</li>
			</nav>
		</div>
	);
}

export default NavSidebar;
