import {TextField} from "@mui/material";
import {DesktopDatePicker, DesktopDatePickerProps} from "@mui/x-date-pickers";
import {Dayjs} from "dayjs";
import React, {FC, useEffect, useRef} from "react";
import {useStyles} from "./style";


interface IPropsCustom {
	className?: string,
    error?: string,
    label?: string,
	onValidate?: () => void,
	style?: Object
}

type IProps = Omit<DesktopDatePickerProps<Dayjs, Date>, "renderInput"> & IPropsCustom;

export const InputDate: FC<IProps> = ({className, error, label, onValidate, onChange, ...props}) => {
	const classes = useStyles();
	const valid = error === undefined;
	const validationRequired = useRef(false);

	const handleOnChange = (e: Dayjs): void => {
		onChange(e ? e.toDate() : null);
		validationRequired.current = true;
	}

	useEffect(() => {
		if(props.value && validationRequired.current) {
			onValidate?.();
			validationRequired.current = false;
		}
	}, [props.value])

	return (
		<div className={classes.inputDate}>
			{label && <label htmlFor="">{label}</label>}
			<div className={""}>
				<DesktopDatePicker
					{...props}
					onChange={e => handleOnChange(e as unknown as Dayjs)}
					inputFormat="DD/MM/YYYY"
					renderInput={(params) => (
						<TextField
							{...params}
							sx={{...(props.style ?? {}), ...(valid ? {} : {border: "2px solid #f30000"})}}
						/>
					)}
				/>
			</div>
			<div className={classes.errorMessage} style={{maxHeight: error ? 50 : 5}}>
				<p>
					{error ?? " "}
				</p>
			</div>
		</div>
	);
}

export default InputDate;
