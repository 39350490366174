import React, {useContext, FC} from "react";
import {useNavigate} from "react-router-dom";
import ManagersDropDown from "../ManagersDropDown/ManagersDropDown";
import Select from "../../../../../../components/Select/Select";
import coreService from "../../../../../../services/core.service";
import {NotifyContext} from "../../../../../../context/NotifyContext";
import {Severity} from "../../../../../../types/INotify";
import {publishedOptions} from "../../../../../../constants";
import {IAcademyCollection} from "../../../../../../types/IAcademyCollection";
import {IAcademy} from "../../../../../../types/IAcademy";

const PenPaperIcon = "/assets/users-edit.svg";
const BinIcon = "/assets/bin.svg";

interface IProps {
	academy: IAcademy,
	language: string,
	collection: IAcademyCollection,
	onEditAction: () => void,
	onDeleteAction: () => void,
	onChange: (e: IAcademy) => void
}

export const AcademyItem: FC<IProps> = ({academy, language, collection, onEditAction, onDeleteAction, onChange}) => {
	const navigate = useNavigate();
	const {notify} = useContext(NotifyContext);

	const handleAcademyStatusEdit = async (status: string): Promise<void> => {
		const result = await coreService.updateAcademy(academy.slug, {status: status}, {});
		const success = result.status >= 200 && result.status < 300;

		notifyChange(success, result?.data);
	};

	const notifyChange = (success: boolean, newAcademy: IAcademy): void => {
		const body = success ? `Successfully modified ${newAcademy.name}` : "Something when wrong";
		notify(body, "Academy", success ? Severity.success : Severity.error);
		if(success) onChange(newAcademy);
	}

	return (
		<li className="sub_academy">
			<div className="lesson_heading">
				<h4 onClick={() => navigate(`/curriculum/${academy.slug}`)}>
					{academy.name}
				</h4>
			</div>
			<label style={{opacity: collection.master_academy === academy.slug ? 1 : 0}}>
				Master
			</label>

			<span className="language text_14">{language.toUpperCase()}</span>

			<ManagersDropDown
				className="academy_item_manager_drop_down"
				academy={academy}
				onChange={e => notifyChange(true, e)}
			/>

			<Select
				className="academy_item_status_drop_down"
				options={publishedOptions}
				style={{height: "40px", minWidth: "200px"}}
				value={academy.status}
				onSelect={(option) => handleAcademyStatusEdit(option.value)}
			/>

			<div className="button_wrapper">
				<button onClick={() => onEditAction()}>
					<img src={PenPaperIcon} alt="" />
				</button>
				<button onClick={() => onDeleteAction()}>
					<img src={BinIcon} alt="" />
				</button>
			</div>
		</li>
	);
}

export default AcademyItem;
