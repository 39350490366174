import React, {FC, ReactNode, useEffect, useRef, useState} from "react";
import {createUseStyles} from "react-jss";

const useStyles = createUseStyles({
	overlay: {
		position: "fixed",
		top: "0",
		left: "0",
		right: "0",
		bottom: "0",
		backgroundColor: "#00000030",
		zIndex: "-1",
		width: "100%"
	},
	modal: {
		position: "fixed",
		top: "0",
		left: "0",
		right: "0",
		bottom: "0",
		maxHeight: "100%",
		overflowY: "auto",
		zIndex: "25",
		alignItems: "center"
	}
})

interface IProps {
	isOpen: boolean,
	onClose: () => void,
	children: ReactNode
}

export const Modal: FC<IProps> = ({isOpen, onClose, children}) => {
	const isFirstRun = useRef(true);

	useEffect(() => {
		isFirstRun.current = false;
	}, []);

	const classes = useStyles();
	//Once requested, should say in dom with display none. 
	if(isFirstRun.current && !isOpen) return <></>

	return (
		<div className={classes.modal} style={{display: isOpen ? "block" : "none"}}>
			{children}
			<div className={classes.overlay} onClick={() => onClose()}></div>
		</div>
	);
}

export default Modal;
