import {validatePassword} from "../../../helper/validate";
import {IUserPasswordUpdateRequest} from "../../IUser";
import {ValidationDoc} from "../../IValidation";
import {required} from "../common";

export const UserPasswordUpdateRequestValidationDoc: ValidationDoc<IUserPasswordUpdateRequest> = {
	new_password: [
		required("new_password"),
		{
			errorMessage: "password doesn't meet the security requirements",
			validate: (e) => Object.keys(validatePassword(e.new_password)).length === 0
		}
	],
	confirm_password: [
		required("confirm_password"),
		{
			errorMessage: "passwords do not match",
			validate: (e) => e.confirm_password === e.new_password
		}
	]
}
