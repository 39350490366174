
export interface IMessage {
	title: string;
    body: string | JSX.Element;
    severity: Severity;
    lifeTime?: number;
    id?: number;
}

export enum Severity {
    success = 0,
    neutral = 1,
    warning = 2,
    error = 3
}