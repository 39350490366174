import {IWebinar, IWebinarEditRequest, IWebinarSession, IWebinarSessionEditRequest} from "../IWebinar";
import * as dayjs from "dayjs"
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import {DayJsDateToStringAPI} from "../../common/dateManipulation";

dayjs.extend(utc);
dayjs.extend(timezone);

export const webinarToEditWebinarRequest= (module: IWebinar): IWebinarEditRequest => ({
	name: module.name,
	subtitle: module.subtitle,
	status: module.status,
	cache: false,
	content: module.content,
	type: "webinar",
	instructors: []
});

export const webinarSessionToEditSessionRequest = (session: IWebinarSession): IWebinarSessionEditRequest => {

	const utcDate = dayjs.tz(session.date, "Etc/UTC").tz(session.timezone ?? "America/Cancun");

	return {
		webinar: session.webinar.slug,
		readable_slug: session.readable_slug,
		date: DayJsDateToStringAPI(utcDate),
		status: "published",
		timezone: session.timezone
	};
}
