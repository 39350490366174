import React, {useState, useEffect, FC, ReactElement, useContext} from "react";
import TextEditor from "../../Curriculum/components/TextEditor/TextEditor";
import {useNavigate, useParams} from "react-router-dom";
import {IWebinar, IWebinarEditRequest, IWebinarSession} from "../../../types/IWebinar";
import {TextInput} from "../../../components/form/textInput/textInput";
import {useValidate} from "../../../hooks/useValidate";
import {webinarEditRequestValidationDoc} from "../../../types/validationDocs/webinar/webinarEditRequestValidationDoc";
import {webinarToEditWebinarRequest} from "../../../types/mappers/webinar";
import coreService from "../../../services/core.service";
import {Severity} from "../../../types/INotify";
import {NotifyContext} from "../../../context/NotifyContext";
import {WebinarEditRequestTemplate} from "../../../types/templates/TWebinar";
import {Button} from "../../../components/Common/Button/Button";
import SaveIcon from "@mui/icons-material/Save";
import PublishIcon from "@mui/icons-material/Publish";
import CloseIcon from "@mui/icons-material/Close";
import Sessions from "./Sessions/Sessions";
import AddWebinarSessionModal from "../../../features/AddWebinarSessionModal/AddWebinarSessionModal";
import EditWebinarSessionModal from "../../../features/EditWebinarSessionModal/EditWebinarSessionModal";
import DeleteWebinarSessionModal from "../../../features/DeleteWebinarSessionModal/DeleteWebinarSessionModal";
import WebinarFormHeader from "./WebinarHeader/WebinarHeader";
import {Tab, Tabs} from "../../../components/Common/Tabs/Tabs";
import OndemandVideoIcon from "@mui/icons-material/OndemandVideo";
import InfoIcon from "@mui/icons-material/Info";
import {Select} from "../../../components/Select/Select";
import {publishedOptions} from "../../../constants";
import LiveCacheToggle from "../../../features/LiveCacheToggle/LiveCacheToggle";
import {ModuleFileUpload} from "../../../components/ModuleFileUpload/ModuleFileUpload";
import {IAcademy} from "../../../types/IAcademy";
import FilePresentIcon from "@mui/icons-material/FilePresent";
import InputCheckbox from "../../../components/InputCheckbox/InputCheckbox";

const BackgroundHero = "/assets/background-hero.svg";

interface IProps {
	webinarSlug?: string,
	academy?: IAcademy,
	sectionSlug: string
}

type tab = "general" | "sessions" | "files";

const tabs: Tab<tab>[] = [
	{value: "general", label: "General", icon: <InfoIcon/>},
	{value: "sessions", label: "Sessions", icon: <OndemandVideoIcon/>},
	{value: "files", label: "Files", icon: <FilePresentIcon/>}
]

const initTab = (): tab => {
	if(window.location.href.includes("files")) return "files"
	if(window.location.href.includes("sessions")) return "sessions"
	return "general";
}

export const WebinarForm: FC<IProps> = ({webinarSlug, academy, sectionSlug}) => {
	const [webinar, setWebinar] = useState<IWebinar>();
	const edit = !!webinar?.slug;
	const [cacheToggle, setCacheToggle] = useState(true);
	const [activeSession, setActiveSession] = useState<IWebinarSession>();
	const [tabSelected, setTabSelected] = useState<tab>(initTab());

	const [newSessionModalOpen, setNewSessionModalOpen] = useState<boolean>(false);
	const [editSessionModalOpen, setEditSessionModalOpen] = useState<boolean>(false);
	const [deleteSessionModalOpen, setDeleteSessionModalOpen] = useState<boolean>(false);

	const [formData, setFormData] = useState<IWebinarEditRequest>(webinar ? webinarToEditWebinarRequest(webinar) : WebinarEditRequestTemplate);
	const [sessions, setSessions] = useState<IWebinarSession[]>();
	const navigate = useNavigate();
	const [validationResult, runValidate, hasErrors, clearValidation] = useValidate<IWebinarEditRequest>(webinarEditRequestValidationDoc, formData);
	const {notify} = useContext(NotifyContext);
	const {academy_slug} = useParams();

	useEffect(() => {
		webinarSlug && webinarSlug !== "null" && coreService.getModule(webinarSlug, {}, {cache: cacheToggle}).then((res) => {
			setWebinar(res.data);
		});
	}, [webinarSlug, cacheToggle]);

	useEffect(() => {
		setFormData(webinar ? webinarToEditWebinarRequest(webinar) : WebinarEditRequestTemplate);
		clearValidation();

		if(!webinar) return;
		coreService.getWebinarSessions({webinar: webinar.slug}).then((res) => {
			setSessions(res.data.records);
		});
	}, [webinar]);

	const handleNavigateBack = (): void =>
		navigate(`/curriculum/${academy_slug}`);

	const handleNewWebinar = async (publish: boolean): Promise<void> => {
		if(!runValidate()) return;
		const submissionData = {...formData, status: publish ? "published" : "draft", section: sectionSlug}
		const result = await coreService.createModule(submissionData);
		const success = result.status >= 200 && result.status < 300;
		const message = success ? `Successfully added ${formData.name}` : "Something went wrong";
		notify(message, "Webinar", success ? Severity.success : Severity.error);

		if(!success) return;
		if(publish){
			setWebinar(result.data);
			setTabSelected("sessions")
			navigate(`/webinar/${academy_slug}/${sectionSlug}/${result.data.slug}/sessions`, {replace: false});
		}
		else
			handleNavigateBack();
	};

	const handleEditWebinar = async (): Promise<void> => {
		if(!runValidate() || !webinar?.slug) return;
		const result = await coreService.updateModule(webinar.slug, formData);
		const success = result.status >= 200 && result.status < 300;
		const message = success ? `Successfully updated ${formData.name}` : "Something went wrong";
		notify(message, "Webinar", success ? Severity.success : Severity.error);
		if(success) handleNavigateBack();
	};

	const handleAddSession = (session: IWebinarSession): void => {
		setSessions(prev => {
			if(!prev || prev.find(e => e.slug === session.slug)) return prev;
			return [...prev, session];
		});
	}

	const handleEditSession = (editSession: IWebinarSession): void => {
		setSessions(prev => {
			const session = prev?.find(e => e.slug === editSession.slug);
			if(!session) return prev;
			Object.assign(session, editSession);
			return [...prev ?? []];
		})
	}

	const handleDeleteSession = (deleteSession: IWebinarSession): void => {
		setSessions(prev => {
			const index = prev?.findIndex(e => e.slug === deleteSession.slug);
			if (!index || index === -1) return prev;
			prev?.splice(index, 1);
			return [...prev ?? []];
		})
	}

	const inputText = (placeholder: string, prop: keyof IWebinarEditRequest, style: Object = {}): ReactElement => <TextInput
		style={style}
		placeholder={placeholder}
		error={validationResult[prop]}
		value={formData[prop] as string}
		onChange={e => setFormData(prev => ({...prev, [prop]: e.target.value}))}
		onValidate={() => runValidate(prop)}
	/>

	const openModal = (stateFtn: Function, session: IWebinarSession): void => {
		stateFtn(true);
		setActiveSession(session);
	}

	const handleSetTab = (e: tab): void => {
		if(e === "sessions" && !edit)
			notify("Please save before adding sessions", "New Webinar", Severity.neutral);
		else if(e === "files" && !edit)
			notify("Please save before adding files", "New Webinar", Severity.neutral);
		else setTabSelected(e);
	}

	const statusText = (): string => {
		if(webinar?.is_cache && formData.cache)
			return "Your revision will be updated with the changes given"
		if(webinar?.is_cache && !formData.cache)
			return "Your revision will be deleted and the current changes will be made permanent"
		if(!webinar?.is_cache && formData.cache)
			return "A revision will be created and changes will not be deployed"
		if(!webinar?.is_cache && !formData.cache){
			if(formData.status === "draft"){
				return "Changes will be made " + (webinar?.status !== formData.status ? "and the module will be drafted" : "to the draft")
			}
			return "Changes will be published";
		}
		return "";
	}

	if(!academy || !formData) return <></>;
	return(
		<div className="new_module" style={{background: `url(${BackgroundHero}) no-repeat`}}>
			<div className="curriculum__new_module">
				<WebinarFormHeader academy={academy} />
				{edit && tabSelected === "general" && <div className={"curriculum_status"}>
					<Select
						name="Status"
						error={validationResult["status"]}
						onValidate={() => runValidate("status")}
						options={publishedOptions}
						style={{width: 250}}
						value={formData.status}
						onSelect={(option) => setFormData(prev => ({...prev, status: option.value}))}
					/>
				</div>}
				<br />
				<Tabs<tab>
					tabs={tabs}
					value={tabSelected}
					onChange={e => handleSetTab(e)}
				/>
				{tabSelected === "general" && <>
					<div className="curriculum__new_module__form">
						<div className="curriculum__new_module__form__top_container">
							<div>
								{inputText("Webinar title goes here", "name", {fontWeight: 600, width: 300, fontSize: 24, background: "white", padding: 0, borderBottom: "1px dashed #d1d1d1", borderRadius: 0})}
							</div>
							<div>
								{webinar?.is_cache && <LiveCacheToggle isCache={cacheToggle} onChange={() => setCacheToggle(prev => !prev)} />}
							</div>
						</div>
						{inputText("Subtitle goes here", "subtitle", {background: "white", padding: 0, width: 250, borderBottom: "1px dashed #d1d1d1", borderRadius: 0})}
						<label>Content</label>
						<TextEditor onChange={e => setFormData(prev => ({...prev, content: e}))} value={formData.content} />
						{/* TODO - parked for first release */}
						{/* <Instructors onChange={() => {}} instructors={webinar?.instructors ?? []}/> */}
						<br />
					</div>

					{edit && <div className="curriculum_stage_change_toggle">
						<div className="curriculum_stage_change_toggle_container">
							<InputCheckbox
								preText={(webinar.is_cache ? "Update" : "Create") + " Revision"}
								value={formData.cache}
								onChange={e => setFormData(prev => ({...prev, cache: e}))}
							/>
							<p className="curriculum_status_text">{statusText()}</p>
						</div>
					</div>}

					<div className="primary_button_wrapper">
						<Button onClick={() => handleNavigateBack()}><CloseIcon style={{paddingRight: 5}}/> Cancel</Button>
						{edit && <Button disabled={hasErrors} onClick={() => handleEditWebinar()}><SaveIcon style={{paddingRight: 5}}/> Save</Button>}
						{!edit && <Button disabled={hasErrors} onClick={() => handleNewWebinar(false)}><SaveIcon style={{paddingRight: 5}}/> Save Draft</Button>}
						{!edit && <Button disabled={hasErrors} onClick={() => handleNewWebinar(true)}><PublishIcon style={{paddingRight: 5}}/> Save & Publish</Button>}
					</div>
					<br/><br/>
				</>}

				{tabSelected === "sessions" && sessions !== undefined && <Sessions
					sessions={sessions ?? []}
					onEditRequest={session => openModal(setEditSessionModalOpen, session)}
					onDeleteRequest={session => openModal(setDeleteSessionModalOpen, session)}
					onAddRequest={() => setNewSessionModalOpen(true)}
				/>}

				{tabSelected === "files" && <ModuleFileUpload remoteFiles={webinar?.files ?? []} moduleSlug={webinar?.slug ?? ""}/>}
			</div>
			{webinar && <AddWebinarSessionModal
				open={newSessionModalOpen}
				onClose={() => setNewSessionModalOpen(false)}
				webinar={webinar}
				onChange={session => handleAddSession(session)}
			/>}
			{activeSession && <EditWebinarSessionModal
				open={editSessionModalOpen}
				onClose={() => setEditSessionModalOpen(false)}
				session={activeSession}
				onChange={session => handleEditSession(session)}
			/>}
			{activeSession && <DeleteWebinarSessionModal
				open={deleteSessionModalOpen}
				onClose={() => setDeleteSessionModalOpen(false)}
				session={activeSession}
				onChange={session => handleDeleteSession(session)}
			/>}
		</div>
	);
}

export default WebinarForm;
