import React, {FC} from "react";

interface IProps {
	academy: any
}

export const WebinarFormHeader: FC<IProps> = ({academy}) => (
	<header className="curriculum__home__header">
		<h1>
			{academy.name}
			<span
				style={{
					opacity: "0.5",
					marginLeft: "0.5rem",
					textTransform: "capitalize"
				}}
			>{`(${academy.language})`}</span>
		</h1>
	</header>
);

export default WebinarFormHeader;
