import {useQuery, useMutation, useQueryClient} from "@tanstack/react-query";
import coreService from "../services/core.service";

// exports user data
export const useUsersData = (params) => {
	const fetchUsers = async () => {
		return await coreService
			.getUsers(params, {}, {})
			.then((res) => res.data.records);
	};

	const {data, isFetching, error, refetch} = useQuery(
		["users", params],
		fetchUsers,
		{refetchOnWindowFocus: false}
	);

	return {
		data,
		isFetching,
		error,
		refetch
	};
};

// add a user
export const useAddUser = () => {
	const queryClient = useQueryClient();
	const addUser = (data) => coreService.createUser(data, {});

	return useMutation(addUser, {
		onSuccess: () => {
			queryClient.invalidateQueries(["users"]);
		}
	});
};

// edit a user
export const useEditUser = (slug) => {
	const queryClient = useQueryClient();

	const editUser = (data) => coreService.updateUser(slug, data, {});

	return useMutation(editUser, {
		onSuccess: () => {
			queryClient.invalidateQueries(["users"]);
		}
	});
};

// delete a user
export const useDeleteUser = (slug) => {
	const queryClient = useQueryClient();

	const deleteUser = () => {
		return coreService.deleteUser(slug, {});
	};

	return useMutation(deleteUser, {
		onSuccess: () => {
			queryClient.invalidateQueries(["users"]);
		}
	});
};
