

import React, {FC} from "react";
import {useStyles} from "./style";

interface IProps {
	onAddSectionRequest: () => void
}

export const CuriculumFooter: FC<IProps> = ({onAddSectionRequest}) => {
	const classes = useStyles();

	return (
		<footer className={classes.curiculumFooter}>
			<div className={classes.footerContainer}>
				<div className="left_container">
					<button onClick={() => onAddSectionRequest()}>+ Section</button>
				</div>
			</div>
		</footer>
	);
}


