import {IFile, IModule, IModuleEditRequest} from "../IModule";

export const ModuleEditRequestTemplate: IModuleEditRequest = ({
	name: "",
	subtitle: "",
	status: "draft",
	content: "",
	cache: false,
	type: "module",
	video_url: "",
	h5p_url: ""
});
