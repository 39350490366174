import React, {FC, ReactElement, useContext, useState} from "react";
import userService from "../../../services/user.service";
import {TextInput} from "../../../components/form/textInput/textInput";
import ConfirmationPopUp from "../../../components/ConfirmationPopUp/ConfirmationPopUp";
import {ISessionUserPasswordUpdateRequest} from "../../../types/IUser";
import Modal from "../../../Modal";
import {Button} from "../../../components/Common/Button/Button";
import {SessionUserPasswordUpdateRequestTemplate} from "../../../types/templates/TUser";
import PasswordValidation from "../PasswordValidation";
import {useValidate} from "../../../hooks/useValidate";
import {SessionUserPasswordUpdateRequestValidationDoc} from "../../../types/validationDocs/user/sessionUserPasswordUpdateRequestValidationDoc";
import {Severity} from "../../../types/INotify";
import {NotifyContext} from "../../../context/NotifyContext";

export const ChangeSessionPasswordForm: FC = () => {
	const [passwordInputFocus, setPasswordInputFocus] = useState(false);
	const [confirmModalOpen, setConfirmModalOpen] = useState<boolean>(false);
	const [errorModalOpen, setErrorModalOpen] = useState(false);
	const [formData, setFormData] = useState<ISessionUserPasswordUpdateRequest>({...SessionUserPasswordUpdateRequestTemplate});
	const [validationResult, runValidate, hasErrors] = useValidate<ISessionUserPasswordUpdateRequest>(SessionUserPasswordUpdateRequestValidationDoc, formData);
	const {notify} = useContext(NotifyContext);

	const handleChange = (prop: keyof ISessionUserPasswordUpdateRequest, value: string): void =>
		setFormData(prev => ({
			...prev,
			[prop]: value
		}));

	const sendData = (): void => {
		setConfirmModalOpen(false);
		if(!runValidate()) return;

		userService.updatePassword(
			formData.current_password,
			formData.new_password
		).then((_passwordResponse: any) => {
			setPasswordInputFocus(false);
			setFormData({...SessionUserPasswordUpdateRequestTemplate});
			notify("Your password has been successfully changed.", "Settings");
		}).catch(
			(res: any) => {
				setErrorModalOpen(true);
			}
		);
	};

	const inputPassword = (label: string, prop: keyof ISessionUserPasswordUpdateRequest, additionalProps?: any): ReactElement => <TextInput
		label={label}
		type="password"
		error={validationResult[prop]}
		value={formData[prop]}
		onChange={(e) => handleChange(prop, e.target.value)}
		onValidate={() => runValidate(prop)}
		{...additionalProps}
	/>

	return (
		<>
			{inputPassword("Current", "current_password")}
			{inputPassword("New Password", "new_password", {onFocus: () => setPasswordInputFocus(true)})}

			<div style={{transition: "max-height 1.5s ease-out", overflow: "hidden", maxHeight: passwordInputFocus ? 400 : 0}}>
				<PasswordValidation password={formData.new_password} />
			</div>

			{inputPassword("Confirm Password", "confirm_password")}


			<div style={{textAlign: "right", marginTop: 35}}>
				<Button
					disabled={hasErrors || formData.confirm_password === ""}
					onClick={() => sendData()}
				>
                    Submit
				</Button>
			</div>

			<Modal isOpen={confirmModalOpen} onClose={() => setConfirmModalOpen(false)}>
				<ConfirmationPopUp
					set_modal={setConfirmModalOpen}
					message="Are you sure you want to change the password?"
					button_title="Confirm"
					action={() => { setConfirmModalOpen(false); }}
				/>
			</Modal>

			<Modal isOpen={errorModalOpen} onClose={() => setErrorModalOpen(false)}>
				<ConfirmationPopUp
					set_modal={setErrorModalOpen}
					message="Please ensure your current password is correct"
					button_title="Try again"
					action={() => setErrorModalOpen(false)}
				/>
			</Modal>
		</>
	);
};
