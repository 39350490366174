import React, {FC, useState, useCallback, useEffect, ReactElement, useContext} from "react";
import {TextInput} from "../form/textInput/textInput";
import {IWebhook} from "../../types/IWebhook";
import Modal from "../../Modal";
import {Button} from "../Common/Button/Button";
import {useValidate} from "../../hooks/useValidate";
import {webhookValidationDoc} from "../../types/validationDocs/webhookValidationDoc";
import {NotifyContext} from "../../context/NotifyContext";
import ConfirmationPopUp from "../ConfirmationPopUp/ConfirmationPopUp";
import coreService from "../../services/core.service";
import {Severity} from "../../types/INotify";

interface IProps {
    webhook: IWebhook,
    onSuccess?: (webhook: IWebhook) => void,
}

export const EditWebhookForm: FC<IProps> = ({webhook, onSuccess}) => {
	const [formData, setFormData] = useState<IWebhook>(webhook);
	const [confirmModalOpen, setConfirmModalOpen] = useState<boolean>(false);
	const [validationResult, runValidate, hasErrors, clearValidation] = useValidate<IWebhook>(webhookValidationDoc, formData);
	const {notify} = useContext(NotifyContext);

	useEffect(() => {
		setFormData(webhook);
		clearValidation();
	}, [webhook]);

	const handleSubmit = useCallback(() => {
		setConfirmModalOpen(false);
		if(!runValidate()) return;
		coreService.updateWebhook(formData).then((data) => {
			onSuccess && onSuccess(data?.data as unknown as IWebhook );
			notify("Your webhook configuration has been successfully changed", "Webhook");
		}).catch((e) => {
			notify("There was an error while saving the webhook configuration. Please make sure to provide a valid URL and try again.", "Webhook", Severity.error);
		});
	}, [formData]);


	return (
		<>
			<TextInput
				label="URL"
				type="url"
				error={validationResult["url"]}
				value={formData["url"]}
				onChange={e => setFormData(prev => ({...prev, url: e.target.value}))}
				onValidate={() => runValidate("url")}
			/>

			<div style={{textAlign: "right", marginTop: 35}}>
				<Button
					onClick={() => setConfirmModalOpen(true)}
					disabled={hasErrors}
				>
                    Submit
				</Button>
			</div>

			<Modal isOpen={confirmModalOpen} onClose={() => setConfirmModalOpen(false)}>
				<ConfirmationPopUp
					set_modal={setConfirmModalOpen}
					message="Are you sure you want to change the webhook configuration?"
					button_title="Confirm"
					action={handleSubmit}
				/>
			</Modal>
		</>

	);
};
