import React, {useEffect, useState, FC, ReactElement} from "react"
import {Close} from "@mui/icons-material"
import RoleSelect from "../RoleSelect/roleSelect"
import {useAddUser} from "../../../../api/useUsersData";
import {useValidate} from "../../../../hooks/useValidate";
import {IUserCreateRequest} from "../../../../types/IUser";
import {TextInput} from "../../../../components/form/textInput/textInput";
import {UserCreateRequestTemplate} from "../../../../types/templates/TUser";
import {ValidationResult} from "../../../../types/IValidation";
import {Button} from "../../../../components/Common/Button/Button"
import Modal from "../../../../Modal";
import ConfirmationPopUp from "../../../../components/ConfirmationPopUp/ConfirmationPopUp";
import {UserCreateRequestValidationDoc} from "../../../../types/validationDocs/user/userCreateRequestValidationDoc";

interface IProps {
    open: boolean,
	onClose: () => void
}

export const NewUserModal: FC<IProps> = ({open, onClose}) => {
	const [formData, setFormData] = useState<IUserCreateRequest>(UserCreateRequestTemplate);
	const {mutate: addUser, error, data} = useAddUser()
	const [validationResult, runValidate, hasErrors, clearValidation] = useValidate<IUserCreateRequest>(UserCreateRequestValidationDoc, formData);
	const [postRequestValidation, setPostRequestValidation] = useState<ValidationResult<IUserCreateRequest>>({});
	const [successModalOpen, setSuccessModalOpen] = useState(false);

	useEffect(() => {
		setFormData(UserCreateRequestTemplate);
		clearValidation();
	}, [open])

	const handleClose = (): void => {
		onClose();
		setSuccessModalOpen(false);
	}

	const handleAddUser = (): void => {
		if(!runValidate()) return;
		setPostRequestValidation({});
		addUser(formData)
	}

	useEffect(() => {
		if((error as any)?.response?.status === 400){
			setPostRequestValidation(prev => ({...prev, email: "This email is already taken!"}));
		}
	}, [error]);

	useEffect(() => {
		if(data && (data as any)?.data?.password){
			setPostRequestValidation({});
			setSuccessModalOpen(true);
		}
		//to do - when notifications built  - "something has gone wrong"
	}, [data]);

	const inputText = (label: string, prop: keyof IUserCreateRequest, type = "text"): ReactElement => <TextInput
		label={label}
		type={type}
		error={validationResult[prop] ?? postRequestValidation[prop] }
		value={formData[prop]}
		onChange={e => setFormData(prev => ({...prev, [prop]: e.target.value}))}
		onValidate={() => runValidate(prop)}
	/>

	return (
		<Modal isOpen={open} onClose={() => handleClose()}>

			<div className="main_user__new_user" >
				<header>
					<span className="text_30">Add User</span>
					<button
						className="close_btn"
						onClick={handleClose}
					>
						<Close />
					</button>
				</header>

				{inputText("First Name", "first_name")}
				{inputText("Last Name", "last_name")}
				{inputText("Email", "email", "email")}

				<label htmlFor="">Role</label>
				<RoleSelect value={formData.role} onSelect={(e: any) => setFormData(prev => ({...prev, role: e.value}))}/>
				<br/><br/>
				<Button
					disabled={hasErrors}
					onClick={() => handleAddUser()}
					style={{float: "right"}}
				>
                Add User
				</Button>

				<Modal isOpen={successModalOpen} onClose={() => setSuccessModalOpen(false)}>
					<ConfirmationPopUp
						set_modal={setSuccessModalOpen}
						message={<>
                        User has been successfully added. <br/><br/>
                        Password: <span style={{color: "grey"}}>{(data as any)?.data?.password}</span>
						</>}
						button_title="Close"
						action={() => handleClose()}
					/>
				</Modal>
			</div>
		</Modal>
	)
}

export default NewUserModal
