import React, {FC, useState, useContext, useEffect} from "react";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import AddIcon from "@mui/icons-material/Add";
import CertificateBackgroundButton from "../../components/CertificateBackgroundButton/CertificateBackgroundButton";
import Select from "../../components/Select/Select";
import {languages} from "../../constants";
import coreService from "../../services/core.service";
import Modal from "../../Modal";
import {NotifyContext} from "../../context/NotifyContext";
import {Severity} from "../../types/INotify";
import {TextInput} from "../../components/form/textInput/textInput";
import {IAcademy, IAcademyEditRequest} from "../../types/IAcademy";
import {IAcademyCollection} from "../../types/IAcademyCollection";
import {academyToEditacademyRequest} from "../../types/mappers/academy";
import {IUser} from "../../types/IUser";
import {academyEditRequestValidationDoc} from "../../types/validationDocs/academy/academyEditRequestValidationDoc";
import {useValidate} from "../../hooks/useValidate";

interface IProps {
	academy: IAcademy,
	collection: IAcademyCollection,
	open: boolean,
	onClose: () => void,
	onChange: (academy: IAcademy) => void,
	onMasterSelectChange: (isMaster: boolean) => void
}

const EditAcademyModal: FC<IProps> = ({academy, collection, open, onClose,	onChange, onMasterSelectChange}) => {
	const isOriginalMaster = (): boolean => collection && academy && collection?.master_academy === academy?.slug;
	const [availableManagers, setAvailableManagers] = useState<IUser[]>([]);
	const [assignedManagers, setAssignedManagers] = useState<IUser[]>([]);
	const [isMasterAcademy, setIsMasterAcademy] = useState(isOriginalMaster());
	const {notify} = useContext(NotifyContext);
	const [formData, setFormData] = useState<IAcademyEditRequest>(academyToEditacademyRequest(academy));
	const [validationResult, runValidate, hasErrors, resetErrors] = useValidate<IAcademyEditRequest>(academyEditRequestValidationDoc, formData);

	useEffect(() => {
		resetErrors();
		if(academy) {
			setAssignedManagers(academy.managers)
			setAvailableManagers(academy.available_managers);
			setFormData(academyToEditacademyRequest(academy));
		}
	}, [academy, open])

	useEffect(() => setFormData(prev => ({...prev, managers: assignedManagers.map(e => e.slug)})), [assignedManagers])

	useEffect(() => setIsMasterAcademy(isOriginalMaster()), [collection, academy])

	const handleSubmit = async (): Promise<void> => {
		const submissionData = {...formData, managers: formData.managers.join()}; //> api requires comma separated list of slugs
		const payload = new FormData();
		// Loop through the object
		for (const [key, val] of Object.entries(submissionData)) {
			if(val && (key !== "certificate_background" || typeof val !== "string")) payload.append(key, val);
		}

		const result = await coreService.updateAcademy(academy.slug, payload, {
			"Content-Type": "multipart/form-data"
		});

		let success = result.status >= 200 && result.status < 300;
		if(success) {
			onChange(result.data);
			if(isOriginalMaster() !== isMasterAcademy)
				success = await updateAcademyAsMaster();
		}

		const message = success ? `Successfully modified ${formData.name}` : "Something when wrong";
		notify(message, "Academy", success ? Severity.success : Severity.error);
		if(success) onMasterSelectChange(isMasterAcademy);
		onClose();
	};

	const updateAcademyAsMaster = async (): Promise<boolean> => {
		const master_academy = isMasterAcademy ? academy.slug : null;
		const result = await coreService.updateCollection(collection.slug, {master_academy}, {});
		return result.status >= 200 && result.status < 300;
	};

	const addManager = (manager: IUser): void => {
		// Remove from managers array
		setAvailableManagers(prev => {
			const managersIndex = prev.findIndex(e => e.slug === manager.slug);
			if (managersIndex === -1)
				return prev;
			else {
				prev.splice(managersIndex, 1);
				return [...prev];
			}
		});

		// Add to available managers array
		setAssignedManagers(prev => {
			if(prev.find(e => e.slug === manager.slug))
				return prev;
			else
				return [...prev, manager]
		});

	};

	const removeManager = (manager: IUser): void => {
		// Remove from managers array
		setAssignedManagers(prev => {
			const managersIndex = prev.findIndex(e => e.slug === manager.slug);
			if (managersIndex === -1)
				return prev;
			else {
				prev.splice(managersIndex, 1);
				return [...prev];
			}
		});

		// Add to available managers array
		setAvailableManagers(prev => {
			if(prev.find(e => e.slug === manager.slug))
				return prev;
			else
				return [...prev, manager]
		});

	};

	if(!formData) return <></>;
	return (
		<Modal isOpen={open} onClose={() => onClose()}>
			<div
				className="add_academy_form"
				id={"academy-form"}
			>
				<button
					type="button"
					className="close_panel"
					onClick={() => onClose()}
				>
					<CloseIcon />
				</button>

				<div className={"content"}>
					<header>
						<span className="text_30">Edit Academy</span>
					</header>

					<div className="master_wrapper">
						<button
							type="button"
							className="master_checkbox"
							onClick={() => setIsMasterAcademy(prev => !prev)}
						>
							{isMasterAcademy && <CheckIcon
								style={{width: "16px", height: "16px", color: "#6AC7D0"}}
							/>}
						</button>
						<span>Master Academy</span>
					</div>

					<TextInput
						label={"Title"}
						type={"text"}
						value={formData.name}
						onChange={e => setFormData(prev => ({...prev, name: e.target.value}))}
						onValidate={() => runValidate("name")}
						error={validationResult?.name}
					/>

					<label htmlFor="">Language Code</label>
					<Select
						className="custom"
						options={languages}
						error={validationResult["language"]}
						onValidate={() => runValidate("language")}
						name={"language"}
						onSelect={e => setFormData(prev => ({...prev, language: e.value}))}
						value={academy.language}
					/>

					<label htmlFor="">Manager</label>
					<div className="drop_down">
						<div className="tags_container minor">
							{assignedManagers.map((manager) =>
								<div
									key={manager.slug}
									className="tag"
									onClick={() => {
										removeManager(manager);
									}}
								>
									<span className="text_14">{manager.name}</span>
									<CloseIcon style={{fontSize: "1rem"}} />
								</div>
							)}
						</div>
					</div>

					<div className="tags_container">
						{availableManagers.map((manager) =>
							<div
								key={manager.slug}
								className="tag"
								onClick={() => {
									addManager(manager);
								}}
							>
								<span className="text_14">{manager.name}</span>
								<AddIcon style={{fontSize: "1rem"}} />
							</div>
						)}
					</div>
					<div className="certificate">
						<span>certificate background</span>
						<CertificateBackgroundButton
							open={open}
							onChange={(certificate_background) => setFormData(prev => ({...prev, certificate_background}))}
							name={"certificate_background"}
							uploadedUrl={academy.certificate_background_url}
						/>
					</div>
				</div>

				<div className="submit_button" style={{opacity: hasErrors ? .7 : 1}}>
					<div className="button_wrapper">
						<button disabled={hasErrors} onClick={() => handleSubmit()}>Save Changes</button>
					</div>
				</div>
			</div>
		</Modal>
	);
}

export default EditAcademyModal;
