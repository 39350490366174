import {createUseStyles} from "react-jss";

export const useStyles = createUseStyles({
	sessionListItem: {
		paddingRight: 20
	},
	rightContainer: {
		textAlign: "right",
		display: "inline-block",
		width: 400
	},
	leftContainer: {
		textAlign: "left",
		display: "inline-block",
		boxSizing: "border-box",
		width: "calc(100% - 400px)"
	},
	deleteBtn: {
		padding: 10,
		border: "none",
		borderRadius: 3,
		marginLeft: 20
	},
	timezone: {
		color: "grey",
		display: "inline",
		marginLeft: 10
	},
	upcommingInfoContainer: {
		width: "100%",
		boxSizing: "border-box",
		display: "block",
		"& span": {
			fontWeight: 600
		}
	}
})
