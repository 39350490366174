import React, {FC, useEffect, useState, useContext} from "react";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import {ISection} from "../../../../../types/ISection";
import {DraggableProvidedDragHandleProps} from "react-beautiful-dnd";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import useDebounce from "../../../../../hooks/useDebounce";
import coreService from "../../../../../services/core.service";
import {Severity} from "../../../../../types/INotify";
import {NotifyContext} from "../../../../../context/NotifyContext";

const PencilPaperIcon = "/assets/users-edit.svg";
const BinIcon = "/assets/bin.svg";

interface IProps {
	section: ISection,
	onEditAction: () => void,
	onDeleteAction: () => void,
	dragHandleProps?: DraggableProvidedDragHandleProps | null,
	onChange: (e: ISection) => void
}

export const SectionListItem: FC<IProps> = ({section, onEditAction, onDeleteAction, dragHandleProps, onChange}) => {
	const [releaseIn, setReleaseIn] = useState<number>(section.drip);
	const releaseInDebounced = useDebounce(releaseIn, 500);
	const {notify} = useContext(NotifyContext);

	useEffect(() => {
		if(releaseInDebounced !== undefined && releaseInDebounced !== section.drip) {
			coreService.updateSection(section.slug, {drip: releaseIn}).then(result => {
				const success = result.status >= 200 && result.status < 300;
				if(success) onChange(result.data);

				const message = success ? `Successfully modified ${result.data.name}` : "Something when wrong";
				notify(message, "Section", success ? Severity.success : Severity.error);
			})
		}
	}, [releaseInDebounced])

	return (
		<li className="container_section__list_item">
			<div className="left_wrapper">
				<button className="drag_button" {...dragHandleProps}>
					<DragIndicatorIcon />
				</button>

				<div
					className="text_wrapper"
					style={{cursor: "pointer"}}
					onClick={() => onEditAction()}
				>
					<h3>{section.name}</h3>
				</div>
			</div>

			<div className="center_wrapper container_section__list_item_release_in">
				Release in
				<input
					value={releaseIn}
					onChange={e => setReleaseIn(parseInt(e.target.value, 10))}
					type="number"
					min={0}
					style={{margin: "0 0.5rem"}}
				/>
				Days
			</div>

			<div className="right_wrapper">
				{section.status === "draft" && <VisibilityOffIcon/>}
				<button onClick={() => onEditAction()}>
					<img src={PencilPaperIcon} alt="" />
				</button>

				<button onClick={() => onDeleteAction()}>
					<img src={BinIcon} alt="" />
				</button>
			</div>
		</li>
	);
}

export default SectionListItem;
