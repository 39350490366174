import {createUseStyles} from "react-jss";

export const useStyles = createUseStyles({
	sideBarSection: {

	},
	module: {
		cursor: "pointer",
		maxWidth: 210,
		overflow: "hidden",
		whiteSpace: "nowrap",
		textOverflow: "ellipsis"
	},
	section: {
		display: "block",
		cursor: "pointer",
		maxWidth: 220,
		overflow: "hidden",
		whiteSpace: "nowrap",
		textOverflow: "ellipsis"
	}
})