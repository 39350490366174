import {createUseStyles} from "react-jss";

export const useStyles = createUseStyles({
	inputDate: {
		width: "100%",
		margin: "20px 0",
		"& .MuiInputBase-root, .MuiFormControl-root": {
			backgroundColor: "#F1F2F2",
			border: "none",
			width: "100%",
			borderRadius: 5
		},
		"& input": {
			marginBottom: 0
		},
		"& fieldset": {
			border: "none",
			width: "100%"
		}
	},
	errorMessage: {
		color: "#f30000",
		fontSize: 15,
		boxSizing: "border-box",
		maxHeight: 0,
		transition: "max-height 0.15s ease-out",
		"& p": {
			padding: 10
		}
	}
})
