import React, {FC} from "react";

const styleCustom = {
	backgroundColor: "white",
	padding: "3.5rem 5rem",
	borderRadius: "0.5rem",
	margin: "1.6rem 0"
}

type IProps = React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>;

export const Container: FC<IProps> = ({style, ...props}) => (
	<div className="container" style={{...styleCustom, ...style}} {...props} />
);
