import React, {FC, ReactElement} from "react";
import {useStyles} from "./style";


interface IPropsCustom {
	icon?: ReactElement
}

type IProps = React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> & IPropsCustom

export const Button: FC<IProps> = ({className, children, icon, style, ...props}) => {
	const classes = useStyles();
	return (
		<button
			className={ (className ?? "") + " " + classes.button}
			{...props}
			style={{...(style ?? {}), ...(props?.disabled ? {opacity: 0.7} : {})}}
		>
			{icon && icon}
			{children}
		</button>
	);
}
