import CloseIcon from "@mui/icons-material/Close";
import React, {FC, ReactElement} from "react";

interface IProps {
	message: string | ReactElement,
	button_title: string,
	action: any,
	set_modal: any
}

export const ConfirmationPopUp: FC<IProps> = ({message, button_title, action, set_modal}) => {
	const popUpStyles: React.CSSProperties = {
		position: "absolute",
		top: "50%",
		left: "50%",
		transform: "translate(-50%, -50%)",
		padding: "4rem 2rem",
		backgroundColor: "#fff",
		borderRadius: "0.5rem",
		textAlign: "center"
	};

	const buttonStyles: React.CSSProperties = {
		height: "50px",
		padding: "0 4rem",
		marginTop: "2rem",
		borderRadius: "100px",
		border: "none",
		background: "linear-gradient(90deg, #262261 0%, #6BCAD2 100%)",
		color: "#fff"
	};

	return (
		<div style={popUpStyles}>
			<h2 style={{color: "#21273c", textTransform: "capitalize"}}>
				{message}
			</h2>
			<CloseIcon
				onClick={() => set_modal(false)}
				style={{
					color: "#21273c",
					position: "absolute",
					top: "1rem",
					right: "1rem",
					cursor: "pointer"
				}}
			/>
			<button style={buttonStyles} onClick={() => action()}>
				{button_title}
			</button>
		</div>
	);
}

export default ConfirmationPopUp;
