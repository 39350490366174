import React, {useState, FC, useEffect, ReactElement, useContext} from "react";
import CloseIcon from "@mui/icons-material/Close";
import Select from "../../components/Select/Select";
import Modal from "../../Modal";
import {Button} from "../../components/Common/Button/Button";
import coreService from "../../services/core.service";
import {useValidate} from "../../hooks/useValidate";
import {NotifyContext} from "../../context/NotifyContext";
import {Severity} from "../../types/INotify";
import {IWebinarSession, IWebinarSessionEditRequest} from "../../types/IWebinar";
import {webinarSessionNewRequestValidationDoc} from "../../types/validationDocs/webinar/webinarSessionNewRequestValidationDoc";
import {webinarSessionToEditSessionRequest} from "../../types/mappers/webinar";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import InputDate from "../../components/InputDate/InputDate";
import InputTime from "../../components/InputTime/InputTime";
import {DateToStringAPI} from "../../common/dateManipulation";
import {publishedOptions, timezoneOptions} from "../../constants";

dayjs.extend(utc);
dayjs.extend(timezone);

interface IProps {
	open: boolean,
	session: IWebinarSession,
	onClose: () => void,
	onChange: (session: IWebinarSession) => void
}

export const EditWebinarSessionModal: FC<IProps> = ({open, onClose, onChange, session}) => {
	const [formData, setFormData] = useState<IWebinarSessionEditRequest>(webinarSessionToEditSessionRequest(session));
	const [validationResult, runValidate, hasErrors, clearValidation] = useValidate<IWebinarSessionEditRequest>(webinarSessionNewRequestValidationDoc, formData);
	const {notify} = useContext(NotifyContext);

	useEffect(() => {
		setFormData(webinarSessionToEditSessionRequest(session));
		clearValidation();
	}, [session, open])

	const handleAddSection = async (): Promise<void> => {
		if(!runValidate() || !formData.timezone) return;
		const convertedDate = dayjs.tz(DateToStringAPI(new Date(formData.date)), formData.timezone).tz("Etc/UTC");

		const submissionData = {...formData, date: DateToStringAPI(convertedDate.toDate())}
		const result = await coreService.updateWebinarSession(session.slug, submissionData);
		const success = result.status >= 200 && result.status < 300;
		if(success) onChange(result.data);
		notify(
			success ? "Successfully edited session" : "Something went wrong",
			"Webinar Session",
			success ? Severity.success : Severity.error);
		onClose();
	};

	const inputDate = (label: string, prop: keyof IWebinarSessionEditRequest): ReactElement => <InputDate
		label={label}
		error={validationResult[prop]}
		value={dayjs(formData[prop])}
		onChange={e => setFormData(prev => ({...prev, [prop]: e?.toUTCString()}))}
		onValidate={() => runValidate(prop)}	/>

	const inputTime = (label: string, prop: keyof IWebinarSessionEditRequest): ReactElement => <InputTime
		label={label}
		error={validationResult[prop]}
		value={dayjs(formData[prop])}
		onChange={e => setFormData(prev => ({...prev, [prop]: e?.toUTCString()}))}
		onValidate={() => runValidate(prop)}	/>

	const inputSelect = (label: string, prop: keyof IWebinarSessionEditRequest, options: {label: string, value: string}[]): ReactElement => <Select
		label={label}
		error={validationResult[prop]}
		value={formData[prop] as string}
		options={options}
		onSelect={(option) => setFormData(prev => ({...prev, [prop]: option.value}))}
		onValidate={() => runValidate(prop)}	/>


	return (
		<Modal isOpen={open} onClose={() => onClose()}>
			<div className="add_academy_collection">
				<span className="text_30">Edit Webinar Session</span>
				<br />
				<button
					className="close_btn"
					style={{width: 40}}
					type="button"
					onClick={() => onClose()}
				>
					<CloseIcon />
				</button>

				{/* {inputSelect("Status", "status", publishedOptions)} */}
				{inputDate("Date", "date")}
				{inputTime("Time", "date")}
				{inputSelect("TimeZone", "timezone", timezoneOptions)}

				<div style={{textAlign: "center", marginTop: 35}}>
					<Button	onClick={() => handleAddSection()} disabled={hasErrors}>
						Edit Session
					</Button>
				</div>
			</div>
		</Modal>
	);
}

export default EditWebinarSessionModal;
