import React, {useState, useEffect, FC} from "react";
import WebinarForm from "./WebinarForm/WebinarForm";
import {useParams} from "react-router-dom";
import coreService from "../../services/core.service";
import {IWebinar} from "../../types/IWebinar";
import {ISection} from "../../types/ISection";
import {useModulesData} from "../../api/useModulesData";
import {modulesToSectionMap} from "../../types/mappers/module";
import {IModule, IModulesBySectionMap} from "../../types/IModule";
import CurriculumSidebar from "../Curriculum/components/CurriculumSidebar/CurriculumSidebar";
import {useAcademyData} from "../../api/useAcademyData";

export const Webinar: FC = () => {
	const {section_slug} = useParams();
	const {academy_slug} = useParams();
	const [webinar, setWebinar] = useState<IWebinar>();
	const {webinar_slug} = useParams();
	const [sections, setSections] = useState<ISection[]>();
	const [modulesBySection, setModulesBySection] = useState<IModulesBySectionMap>({});
	const [modules, setModules] = useState<IModule[]>();
	const {data: academy} = useAcademyData(academy_slug);

	useEffect(() => {
		if(modules) setModulesBySection(modulesToSectionMap(modules))
	}, [modules]);

	useEffect(() => {
		coreService.getModules({academy: academy_slug, limit: 1000}, {} ).then((res) => {
			setModules(res.data.records);
		});

		coreService.getSections({academy: academy_slug}).then((res) => {
			setSections(res.data.records);
		});

	}, [academy_slug]);

	return (
		<div className="">
			<CurriculumSidebar sections={sections} IModulesBySectionMap={modulesBySection} />
			<WebinarForm academy={academy} sectionSlug={section_slug ?? ""} webinarSlug={webinar_slug} />
		</div>
	);
}

export default Webinar;
