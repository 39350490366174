import React, {FC, useEffect, useRef} from "react";
import {switchStyle, useStyles} from "./style";
import Switch, {SwitchProps} from "@mui/material/Switch";
import {styled} from "@mui/material/styles";
import {FormControlLabel, FormGroup} from "@mui/material";

interface IProps {
	className?: string,
    error?: string,
    label?: string,
	onValidate?: () => void,
	style?: Object,
	value: boolean,
	onChange: (e: boolean) => void,
	text?: string,
	labelPlacement?: "bottom" | "start" | "top" | "end"
}

const StyledSwitch = styled((props: SwitchProps) => (
	<Switch {...props} />
))(() => (switchStyle));

export const InputSwitch: FC<IProps> = ({labelPlacement, className, error, label, onValidate, onChange, ...props}) => {
	const classes = useStyles();
	const valid = error === undefined;
	const validationRequired = useRef(false);

	const handleOnChange = (e: boolean): void => {
		onChange(e);
		validationRequired.current = true;
	}

	useEffect(() => {
		if(props.value && validationRequired.current) {
			onValidate?.();
			validationRequired.current = false;
		}
	}, [props.value])

	return (
		<div className={classes.inputSwitch}>
			{label && <label htmlFor="">{label}</label>}
			<div className={className + " " + classes.switchBtn}>
				<FormGroup>
					<FormControlLabel
						control={
							<StyledSwitch
								checked={props.value}
								sx={valid ? {} : {"& .MuiSwitch-track": {backgroundColor: "red !important"}}}
								onChange={(e: { target: { checked: boolean; }; }) => handleOnChange(e.target.checked)}
							/>
						}
						label={<span>{props.text}</span>}
						labelPlacement={labelPlacement ?? "end"}
					/>
				</FormGroup>
			</div>
			<div className={classes.errorMessage} style={{maxHeight: error ? 50 : 5}}>
				<p>
					{error ?? " "}
				</p>
			</div>
		</div>
	);
}

export default InputSwitch;
