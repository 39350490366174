import React, {FC} from "react";
import {roleOptions} from "../../../../constants";
import {IUser} from "../../../../types/IUser";
import PasswordIcon from "@mui/icons-material/Password";
const PencilPaperIcon = "/assets/users-edit.svg";
const BinIcon = "/assets/bin.svg";

interface IProps {
    onDeleteUserAction: () => void,
	onEditUserInfoAction: () => void,
	onEditUserPasswordAction: () => void,
    user: IUser
}

const UserItem: FC<IProps> = ({onDeleteUserAction, onEditUserInfoAction, onEditUserPasswordAction, user}) => {
	return (
		<ul className="user">
			<li>
				<h4>{user.first_name} {user.last_name}</h4>
				<p className="email">{user.email}</p>
				<p>{roleOptions.find(e => e.value === user.role)?.label}</p>

				<div className="button_wrapper">
					<button onClick={() => onEditUserPasswordAction()}>
						<PasswordIcon />
					</button>
					<button onClick={() => onEditUserInfoAction()}>
						<img src={PencilPaperIcon} alt="" />
					</button>
					<button onClick={() => onDeleteUserAction()}>
						<img src={BinIcon} alt="" />
					</button>
				</div>
			</li>
		</ul>
	);
}

export default UserItem;
