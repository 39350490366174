import React, {FC, useContext, useRef, useState, useEffect} from "react";
import {IFile} from "../../types/IModule";
import CloseIcon from "@mui/icons-material/Close";
import coreService from "../../services/core.service";
import {Severity} from "../../types/INotify";
import {NotifyContext} from "../../context/NotifyContext";
import {useStyles} from "./style";
import {Button} from "../Common/Button/Button";
import UploadIcon from "@mui/icons-material/Upload";
import {Container} from "../Common/Container/Container";

const FileIcon = "/assets/file-icon.svg";

interface IProps {
	moduleSlug: string,
	remoteFiles: IFile[],
	className?: string,
}

export const ModuleFileUpload: FC<IProps> = ({className, moduleSlug, remoteFiles}) => {
	const classes = useStyles();
	const {notify} = useContext(NotifyContext);
	const [loading, setLoading] = useState<boolean>(false);
	const [files, setFiles] = useState<IFile[]>(remoteFiles);
	const inputRef = useRef<HTMLInputElement>(null);

	useEffect(() => setFiles(remoteFiles), [remoteFiles])

	const handleModuleFileUpload = (e: any): void => {
		const files = e.target.files;
		if (!files) return;
		setLoading(true);

		for (let i = 0; i < files.length; i++) {
			const file = files[i];
			const payload = new FormData();

			payload.append("file", file);
			payload.append("module", moduleSlug);

			const headers = {
				"Content-Type": "multipart/form-data"
			};

			coreService.createFile(payload, headers).then((res) => {
				const file = res.data;
				setFiles(prev => [...prev, file]);notify(
					"Successfully uploaded",
					"File Upload");
			}).catch((err) => {
				notify(
					"Something went wrong",
					"File Upload",
					Severity.error);
			}).finally(() => setLoading(false));
		}
	};

	const handleFileDelete = (file: any): Promise<void> =>
		coreService.deleteFile(file.slug).then(() => {
			setFiles(prev => prev.filter(f => f.slug !== file.slug));
			notify("Success", "File Deletion");
		}).catch(() => {
			notify("Something went wrong", "File Upload", Severity.error);
		});

	return (
		<div className={classes.moduleFileUpload + " " + (className ?? "")}>
			<Container style={{paddingTop: 30, paddingBottom: 30}}>
				<ul className={classes.uploadedFiles}>
					{files.map((file, i) =>
						<li key={"file-" + i}>
							<div className="left_container">
								<img src={FileIcon} alt="" />
								<a href={file.file_url} target={"_blank"} rel="noreferrer">{file.file_url}</a>
							</div>
							<div className={classes.closeIcon} onClick={(e) => handleFileDelete(file)}>
								<CloseIcon />
							</div>
						</li>
					)}
				</ul>
				<br />

				{files.length === 0 && <div>Add using the button below<br/><br/></div>}
			</Container>
			<Button
				className={classes.uploadButton + (loading ? ` ${classes.loading}` : "")}
				onClick={() => inputRef?.current?.click()}
			>
				<UploadIcon />
				{loading ? "Loading..." : "Upload files"}
				<input
					type={"file"}
					accept={"image/*"}
					ref={inputRef}
					onChange={(e) => handleModuleFileUpload(e)}
				/>
			</Button>
		</div>
	);
};