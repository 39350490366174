import {fontSize} from "@mui/system";
import {createUseStyles} from "react-jss";

export const useStyles = createUseStyles({
	moduleListItem: {

	},
	unCommitedChangesLabel: {
		color: "#d1d1d1",
		fontSize: 12,
		"& svg": {
			paddingTop: 5,
			position: "relative",
			top: 5,
			marginLeft: 10
		}
	}
})
