import axiosClient from "../helper/axios";
import {endpoints} from "./endpoints";

class CoreService {
	baseUrl = process.env.REACT_APP_API_URL;
	client = axiosClient;

	/* get the current value of the webhook */
	getWebhook(headers = {}, params = {}) {
		const url = `/${endpoints.webhook}`;
		return this.client.get(url, {headers, params});
	}

	updateWebhook( data = {}, headers = {}) {
		const url = `/${endpoints.webhook}`;
		return this.client.post(url, data, {headers});
	}

	/* Returns a list of collections */
	getCollections(params = {}, headers = {}) {
		const url = `${this.baseUrl}/${endpoints.collections}`;
		return this.client.get(url, {params, headers});
	}

	/* Create a collection */
	createCollection(data = {}, headers = {}) {
		const url = `${this.baseUrl}/${endpoints.collections}`;
		return this.client.post(url, data, {headers});
	}

	/* Update a collection */
	updateCollection(slug, data = {}, headers = {}) {
		const url = `${this.baseUrl}/${endpoints.collection.replace(
			":slug",
			slug
		)}`;
		return this.client.patch(url, data, {headers});
	}

	/* Delete a collection */
	deleteCollection(slug, headers = {}) {
		const url = `${this.baseUrl}/${endpoints.collection.replace(
			":slug",
			slug
		)}`;
		return this.client.delete(url, {headers});
	}

	/* Returns a list of academies */
	getAcademies(params = {}, headers = {}) {
		const url = `${this.baseUrl}/${endpoints.academies}`;
		return this.client.get(url, {params, headers});
	}

	/* Create a academy */
	createAcademy(data = {}, headers = {}) {
		const url = `${this.baseUrl}/${endpoints.academies}`;
		return this.client.post(url, data, {headers});
	}

	/* Retrieve a academy */
	getAcademy(slug, params = {}, headers = {}) {
		const url = `${this.baseUrl}/${endpoints.academy.replace(":slug", slug)}`;
		return this.client.get(url, {params, headers});
	}

	/* Retrieve an academy overview */
	getAcademyOverview(slug, headers = {}, params = {}) {
		const url = `${this.baseUrl}/${endpoints.academyOverview.replace(
			":slug",
			slug
		)}`;
		return this.client.get(url, {params, headers});
	}

	/* Update a academy */
	updateAcademy(slug, data = {}, headers = {}) {
		const url = `${this.baseUrl}/${endpoints.academy.replace(":slug", slug)}`;
		return this.client.patch(url, data, {headers});
	}

	/* Delete a academy */
	deleteAcademy(slug, headers = {}) {
		const url = `${this.baseUrl}/${endpoints.academy.replace(":slug", slug)}`;
		return this.client.delete(url, {headers});
	}

	/* Publish an academy */
	publishAcademy(slug, data = {}, headers = {}) {
		const url = `${this.baseUrl}/${endpoints.academyPublish.replace(":slug", slug)}`;
		return this.client.post(url, data, {headers});
	}

	/* Publish an academy */
	reorderAcademy(slug, data = {}, headers = {}) {
		const url = `${this.baseUrl}/${endpoints.academyReorder.replace(":slug", slug)}`;
		return this.client.post(url, data, {headers});
	}


	/* Returns a list of sections */
	getSections(params = {}, headers = {}) {
		const url = `${this.baseUrl}/${endpoints.sections}`;
		return this.client.get(url, {params, headers});
	}

	/* Create a section */
	createSection(data = {}, headers = {}) {
		const url = `${this.baseUrl}/${endpoints.sections}`;
		return this.client.post(url, data, {headers});
	}

	/* Update a section */
	updateSection(slug, data = {}, headers = {}) {
		const url = `${this.baseUrl}/${endpoints.section.replace(":slug", slug)}`;
		return this.client.patch(url, data, {headers});
	}

	/* Delete a section */
	deleteSection(slug, headers = {}) {
		const url = `${this.baseUrl}/${endpoints.section.replace(":slug", slug)}`;
		return this.client.delete(url, {headers});
	}

	/* Returns a list of modules */
	getModules(params = {}, headers = {}) {
		const url = `${this.baseUrl}/${endpoints.modules}`;
		return this.client.get(url, {params, headers});
	}

	/* Update a module */
	getModule(slug, headers = {}, params = {}) {
		const url = `${this.baseUrl}/${endpoints.module.replace(":slug", slug)}`;
		return this.client.get(url, {params, headers});
	}
	/* Create a module */
	createModule(data = {}, headers = {}) {
		const url = `${this.baseUrl}/${endpoints.modules}`;
		return this.client.post(url, data, {headers});
	}

	/* Update a module */
	updateModule(slug, data = {}, headers = {}) {
		const url = `${this.baseUrl}/${endpoints.module.replace(":slug", slug)}`;
		return this.client.patch(url, data, {headers});
	}

	markModuleAsComplete(slug, data = {}) {
		const url = `${this.baseUrl}/${endpoints.moduleCompletion.replace(
			":slug",
			slug
		)}`;
		return this.client.post(url, data);
	}


	/* Delete a module */
	deleteModule(slug, headers = {}) {
		const url = `${this.baseUrl}/${endpoints.module.replace(":slug", slug)}`;
		return this.client.delete(url, {headers});
	}

	/* Returns a list of webinars */
	getWebinars(params = {}, headers = {}) {
		const url = `${this.baseUrl}/${endpoints.webinars}`;
		return this.client.get(url, {params, headers});
	}

	/* Create a webinar */
	createWebinar(data = {}, headers = {}) {
		const url = `${this.baseUrl}/${endpoints.webinars}`;
		return this.client.post(url, data, {headers});
	}

	/* Update a webinar */
	updateWebinar(slug, data = {}, headers = {}) {
		const url = `${this.baseUrl}/${endpoints.webinar.replace(":slug", slug)}`;
		return this.client.patch(url, data, {headers});
	}

	/* Delete a webinar */
	deleteWebinar(slug, headers = {}) {
		const url = `${this.baseUrl}/${endpoints.webinar.replace(":slug", slug)}`;
		return this.client.delete(url, {headers});
	}

	/* Returns a list of webinar sessions */
	getWebinarSessions(params = {}, headers = {}) {
		const url = `${this.baseUrl}/${endpoints.webinarSessions}`;
		return this.client.get(url, {params, headers});
	}

	/* Create a webinar session */
	createWebinarSession(data = {}, headers = {}) {
		const url = `${this.baseUrl}/${endpoints.webinarSessions}`;
		return this.client.post(url, data, {headers});
	}

	/* Update a webinar session */
	updateWebinarSession(slug, data = {}, headers = {}) {
		const url = `${this.baseUrl}/${endpoints.webinarSession.replace(":slug", slug)}`;
		return this.client.patch(url, data, {headers});
	}

	/* Delete a webinar session */
	deleteWebinarSession(slug, headers = {}) {
		const url = `${this.baseUrl}/${endpoints.webinarSession.replace(":slug", slug)}`;
		return this.client.delete(url, {headers});
	}

	/* Returns a list of quizzes */
	getQuizzes(params = {}, headers = {}) {
		const url = `${this.baseUrl}/${endpoints.quizzes}`;
		return this.client.get(url, {params, headers});
	}

	/* Create a quiz */
	createQuiz(data = {}, headers = {}) {
		const url = `${this.baseUrl}/${endpoints.quizzes}`;
		return this.client.post(url, data, {headers});
	}

	/* Update a quiz */
	updateQuiz(data = {}, headers = {}) {
		const url = `${this.baseUrl}/${endpoints.quiz}`;
		return this.client.patch(url, data, {headers});
	}

	/* Delete a quiz */
	deleteQuiz(headers = {}) {
		const url = `${this.baseUrl}/${endpoints.quiz}`;
		return this.client.delete(url, {headers});
	}

	/* Returns a list of responses */
	getResponses(params = {}, headers = {}) {
		const url = `${this.baseUrl}/${endpoints.responses}`;
		return this.client.get(url, {params, headers});
	}

	/* Create a response */
	createResponse(data = {}, headers = {}) {
		const url = `${this.baseUrl}/${endpoints.responses}`;
		return this.client.post(url, data, {headers});
	}

	/* Update a response */
	updateResponse(data = {}, headers = {}) {
		const url = `${this.baseUrl}/${endpoints.response}`;
		return this.client.patch(url, data, {headers});
	}

	/* Delete a response */
	deleteResponse(headers = {}) {
		const url = `${this.baseUrl}/${endpoints.response}`;
		return this.client.delete(url, {headers});
	}

	/* Returns a list of files */
	getFiles(params = {}, headers = {}) {
		const url = `${this.baseUrl}/${endpoints.files}`;
		return this.client.get(url, {params, headers});
	}

	/* Create a file */
	createFile(data = {}, headers = {}) {
		const url = `${this.baseUrl}/${endpoints.files}`;
		return this.client.post(url, data, {headers});
	}

	/* Update a file */
	updateFile(slug, data = {}, headers = {}) {
		const url = `${this.baseUrl}/${endpoints.file}`.replace(":slug", slug);
		return this.client.patch(url, data, {headers});
	}

	/* Delete a file */
	deleteFile(slug, headers = {}) {
		const url = `${this.baseUrl}/${endpoints.file}`.replace(":slug", slug);
		return this.client.delete(url, {headers});
	}

	/* Returns a list of certificates */
	getCertificates(params = {}, headers = {}) {
		const url = `${this.baseUrl}/${endpoints.certificates}`;
		return this.client.get(url, {params, headers});
	}

	/* Create a certificate */
	createCertificate(data = {}, headers = {}) {
		const url = `${this.baseUrl}/${endpoints.certificates}`;
		return this.client.post(url, data, {headers});
	}

	/* Update a certificate */
	updateCertificate(data = {}, headers = {}) {
		const url = `${this.baseUrl}/${endpoints.certificate}`;
		return this.client.patch(url, data, {headers});
	}

	/* Delete a certificate */
	deleteCertificate(headers = {}) {
		const url = `${this.baseUrl}/${endpoints.certificate}`;
		return this.client.delete(url, {headers});
	}

	/* Returns a list of achievements */
	getAchievements(params = {}, headers = {}) {
		const url = `${this.baseUrl}/${endpoints.achievements}`;
		return this.client.get(url, {params, headers});
	}

	/* Create a achievement */
	createAchievement(data = {}, headers = {}) {
		const url = `${this.baseUrl}/${endpoints.achievements}`;
		return this.client.post(url, data, {headers});
	}

	/* Update a achievement */
	updateAchievement(data = {}, headers = {}) {
		const url = `${this.baseUrl}/${endpoints.achievement}`;
		return this.client.patch(url, data, {headers});
	}

	/* Delete a achievement */
	deleteAchievement(headers = {}) {
		const url = `${this.baseUrl}/${endpoints.achievement}`;
		return this.client.delete(url, {headers});
	}

	/* Returns a list of users */
	getUsers(params = {}, headers = {}) {
		const url = `${this.baseUrl}/${endpoints.usersList}`;
		return this.client.get(url, {params, headers});
	}

	/* Create a user */
	createUser(data = {}, headers = {}) {
		const url = `${this.baseUrl}/${endpoints.usersList}`;
		return this.client.post(url, data, {headers});
	}

	/* Update a user */
	updateUser(slug, data = {}, headers = {}) {
		const url = `${this.baseUrl}/${endpoints.userUpdate.replace(
			":slug",
			slug
		)}`;
		return this.client.patch(url, data, {headers});
	}

	/* Delete a user */
	deleteUser(slug, headers = {}) {
		const url = `${this.baseUrl}/${endpoints.userUpdate.replace(
			":slug",
			slug
		)}`;
		return this.client.delete(url, {headers});
	}
}

const coreService = new CoreService();
export default coreService;
