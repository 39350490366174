import React, {useEffect, useState} from "react";
import {validate, ValidationType} from "../../helper/validate";

const TickIcon = "/assets/tick.svg";

const PasswordValidation = ({password}) => {
	const [validationResult, setValidationResult] = useState({});

	useEffect(() => {
		setValidationResult(validate(password ?? "", ValidationType.password));
	}, [password]);

	const listItemStyle ={
		display: "flex",
		alignItems: "center",
		gap: "5px"
	};

	return (
		<div style={{paddingTop: 25, paddingLeft: 10}}>
			<p className="">Password Must contain at least</p>
			<div style={{}}>
				<div style={listItemStyle}>
					<span style={{marginRight: "5px"}}>
						{validationResult.passwordLengthValidation && validationResult.passwordLengthValidation ? (
							<>-{" "}</>
						) : (
							<img src={TickIcon} alt="tick" />
						)}
					</span>
					<div style={{color: validationResult.passwordLengthValidation? "#f30000": "black"}}>
						8 characters
					</div>
				</div>
				<div style={listItemStyle}>
					<span style={{marginRight: "5px"}}>
						{validationResult.passwordLowercaseValidation && validationResult.passwordLowercaseValidation ? (
							<>-{" "}</>
						) : (
							<img src={TickIcon} alt="tick" />
						)}
					</span>
					<div style={{color: validationResult.passwordLowercaseValidation? "#f30000": "black"}}>
						1 lower case character
					</div>
				</div>
				<div style={listItemStyle}>
					<span style={{marginRight: "5px"}}>
						{validationResult.passwordUppercaseValidation && validationResult.passwordUppercaseValidation ? (
							<>-{" "}</>
						) : (
							<img src={TickIcon} alt="tick" />
						)}
					</span>
					<div style={{color: validationResult.passwordUppercaseValidation? "#f30000": "black"}}>
						1 upper case character
					</div>
				</div>
				<div style={listItemStyle}>
					<span style={{marginRight: "5px"}}>
						{validationResult.passwordNumberValidation && validationResult.passwordNumberValidation ? (
							<>-{" "}</>
						) : (
							<img src={TickIcon} alt="tick" />
						)}
					</span>
					<div style={{color: validationResult.passwordNumberValidation? "#f30000": "black"}}>
						1 number
					</div>
				</div>
				<div style={listItemStyle}>
					<span style={{marginRight: "5px"}}>
						{validationResult.passwordSymbolValidation && validationResult.passwordSymbolValidation ? (
							<>-{" "}</>
						) : (
							<img src={TickIcon} alt="tick" />
						)}
					</span>
					<div style={{color: validationResult.passwordSymbolValidation? "#f30000": "black"}}>
						1 special character
					</div>
				</div>
			</div>
		</div>
	);
};

export default PasswordValidation;
