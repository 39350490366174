/* eslint-disable no-console */
import {useEffect} from "react";

//Hook to be used to log dependency changes - debugging tool
export default function useDepLog(tag: string, dep: unknown, level?: "warn" | "error"): void {
	useEffect(() => {
		const descriptive = `---> Dependency change \t${tag}\t`;
		const value = typeof dep == "function" ? "function" : dep;

		if(level && level === "warn") console.warn(descriptive, value);
		if(level && level === "error") console.error(descriptive, value);
		else console.log(descriptive, value);
	}, [tag, dep, level]);
}
