import React, {FC} from "react";
import CloseIcon from "@mui/icons-material/Close";
import {useDeleteUser} from "../../../../api/useUsersData";
import {Button} from "../../../../components/Common/Button/Button";
import {IUser} from "../../../../types/IUser";
import Modal from "../../../../Modal";

interface IProps {
	open: boolean,
	onClose: () => void,
	user?: IUser
}

export const DeleteUserModal: FC<IProps> = ({user, open, onClose}) => {
	const {mutate: mutateUser} = useDeleteUser(user?.slug);

	const handleDelete = (): void => {
		if(!user) return;
		mutateUser({email: user.email} as any);
		onClose();
	};

	if(!user) return <></>;
	return (
		<Modal isOpen={open} onClose={onClose}>
			<div
				className="add_academy_collection"
				style={{borderColor: "#6AC7D0", borderWidth: "thick"}}
			>
				<span className="text_30" style={{fontSize: "1rem", textAlign: "center"}}>
					Are You Sure You Want To Delete User?
				</span>
				<button
					className="close_btn"
					onClick={() => onClose()}
				>
					<CloseIcon />
				</button>
				<footer
					style={{backgroundColor: "#ffffff"}}
				>
					<br /><br />
					<Button style={{margin: 10}} onClick={() => handleDelete()}>Delete</Button>
					<Button style={{margin: 10}} onClick={() => onClose()}>Cancel</Button>
				</footer>
			</div>
		</Modal>
	);
}

export default DeleteUserModal;
