import {createUseStyles} from "react-jss";

export const useStyles = createUseStyles({
	footerContainer: {
		width: "100%",
		paddingLeft: 50,
		margin: "0 auto",
		display: "flex",
		justifyContent: "space-between",
		alignItems: "center"
	},
	curiculumFooter: {
		display: "flex",
		justifyContent: "space-between",
		alignItems: "center",
		height: "120px",
		position: "fixed",
		left: 300,
		bottom: "0",
		right: "0",
		borderRadius: "0 0 0 5rem",
		overflow: "hidden",
		backgroundColor: "#f1f2f2",

		borderTop: "1px solid #21273D10",
		"& button": {
			height: 60,
			padding: "0 2rem",
			borderRadius: 100,
			border: "none",
			color: "white"
		},
		"& .right_container": {
			display: "flex",
			paddingRight: 30,
			gap: "1rem",
			"& .button_wrapper": {
				display: "flex",
				flexDirection: "column",
				position: "relative",
				"& span": {
					position: "absolute",
					top: "120%",
					left: "50%",
					transform: "translateX(-50%)",
					fontSize: 14,
					lineHeight: 35,
					fontWeight: 500
				}
			}
		},
		"& .left_container > button": {
			width: 180,
			background: "linear-gradient(90deg, #262261 0%, #6BCAD2 100%)"
		}
	}
})
