import * as dayjs from "dayjs"
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";

dayjs.extend(utc);
dayjs.extend(timezone);

export const getTimeZoneUI = (timezone?: string | null): string => {
	if(!timezone) return "";

	switch(timezone){
		case "America/Cancun":
			return "EST";
		case "Etc/UTC":
			return "UTC";
		case "Europe/London":
			return "GMT";
		default:
			return "";
	}
}


export const TimezoneDateToStringUI = (date: Date, timezone?: string | null): string =>{
	const utcDate = dayjs.tz(date, "Etc/UTC").tz(timezone ?? "America/Cancun");
	const unZonedDate = new Date(DayJsDateToStringAPI(utcDate).replace(/-/g, "/"));
	return DateToStringUI(unZonedDate);
}

export const TimezoneTimeToStringUI = (date: Date, timezone?: string | null): string =>{
	const utcDate = dayjs.tz(date, "Etc/UTC").tz(timezone ?? "America/Cancun");
	const unZonedDate = new Date(DayJsDateToStringAPI(utcDate).replace(/-/g, "/"));
	return TimeToStringUI(unZonedDate);
}

export const DateToStringUI = (date: Date): string => {
	const day = date.getDate();
	const month = date.getMonth() + 1;
	const year = date.getFullYear().toString().substring(2);

	const dayFormatted = day < 10 ? "0" + day : day;
	const monthFormatted = month < 10 ? "0" + month : month;
	return `${dayFormatted}.${monthFormatted}.${year}`;
}

export const TimeToStringUI = (date: Date): string => {
	let hours = date.getHours();
	const minutes = date.getMinutes();
	const ampm = hours >= 12 ? "PM" : "AM";
	hours = hours % 12;
	hours = hours ? hours : 12; // the hour '0' should be '12'
	const hoursFormatted = hours < 10 ? "0" + hours : hours;
	const minutesFormatted = minutes < 10 ? "0" + minutes : minutes;
	return `${hoursFormatted}:${minutesFormatted} ${ampm}`;
}

export const DateToStringAPI = (date: Date): string => {
	return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()} ${date.getHours()}:${date.getMinutes()}`;
}

export const DayJsDateToStringAPI = (date: dayjs.Dayjs): string => {
	return `${date.year()}-${date.month() + 1}-${date.date()} ${date.hour()}:${date.minute()}`;
}