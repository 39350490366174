import React, {FC, useEffect, useState} from "react";
import coreService from "../../services/core.service";
import {PageBase} from "../../components/PageBase/PageBase";
import {Container} from "../../components/Common/Container/Container";
import CircularProgress from "@mui/material/CircularProgress";
import {EditWebhookForm} from "../../components/EditWebhookForm/EditWebhookForm";
import {IWebhook} from "../../types/IWebhook";

export const Webhook: FC = () => {
	const [isLoading, setIsLoading] = useState(true);
	const [webhookData, setWebhookData] = useState<IWebhook>({url: ""});
	useEffect(() => {
		coreService.getWebhook().then((res) => {
			setWebhookData(res.data);
			setIsLoading(false);
		});
	}, []);

	return (
		<PageBase>
			<section className="webhook__webhook_container">
				<header>
					<h1>Webhook configuration</h1>
					{isLoading && <CircularProgress style={{color: "#21273c"}} />}
				</header>
				<Container style={{width: 600}}>
					{!isLoading && <EditWebhookForm webhook={webhookData} onSuccess={u => setWebhookData(prev => ({...prev, ...u}))} />}
				</Container>
			</section>
		</PageBase>
	)
}

export default Webhook;