import React, {FC, useEffect, useRef, useState} from "react";

interface IProps {
  name: string,
  open: boolean,// will wipe local on change
  uploadedUrl?: string,
  onChange: (certificate_background: File | undefined) => void,
}

//stagedDeletion: delete requested, uploadedUrl still present but will be cleared on submission
//fileName: local filename on upload to portal (pre api submission)
//uploadedUrl: the url of the uploaded image.
const CertificateBackgroundButton: FC<IProps> = ({open, name, uploadedUrl, onChange}) => {
	const [fileName, setFileName] = useState<string>();
	const [fileImageData, setFileImageData] = useState<any>();
	const [stagedDeletion, setStagedDeletion] = useState(false);
	const inputRef = useRef<HTMLInputElement>(null);

	useEffect(() => {
		setFileImageData(undefined);
		setFileName(undefined);
	}, [open])

	const onHandleInputChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
		if(!e?.target?.files || e?.target?.files?.length === 0) return;
		setFileName(e.target.files[0].name);
		onChange(e.target.files[0]);

		const reader = new FileReader();
		reader.addEventListener("load", () => {
			setFileImageData(reader.result);
		});
		reader.readAsDataURL(e.target.files[0]);
	};

	const removeFile = (): void => {
		/* Reset file input */
		if (inputRef.current) {
			inputRef.current.value = "";
		}
		setFileName(undefined);
		setStagedDeletion(true);
		setFileImageData(undefined);
	}

	const handleRemoveFile = (): void => {
		removeFile();
		onChange(undefined);
	};

	//wipe local on response 
	useEffect(() => {
		removeFile();
		setStagedDeletion(false);
	}, [uploadedUrl]);

	const uploadAvailible = !stagedDeletion && (fileName || uploadedUrl);
	return (uploadAvailible) ? (
		<>
			{!fileName && uploadedUrl && <img src={uploadedUrl} height={50}/>}
			{fileName && <div className={"file_upload_text"}>{fileName}</div>}
			{fileImageData && <img height={50} src={fileImageData} />}
			<div className={"file_upload_button"} onClick={() => handleRemoveFile()}>x</div>
		</>
	) : (<>
		<button className="files_button" onClick={() => inputRef?.current?.click()}>
			{fileName ? fileName : "Upload"}
		</button>
		<input
			style={{display: "none"}}
			type={"file"}
			accept={"image/*"}
			ref={inputRef}
			name={name}
			onChange={(e) => onHandleInputChange(e)} />
	</>);
};

export default CertificateBackgroundButton;
