import * as dayjs from "dayjs"

export const ValidationType = {
	none: "none",
	password: "password",
	email: "email",
	name: "name"
};

export const validate = (text, validationType) => {
	switch (validationType) {
		case ValidationType.none:
			return {};
		case ValidationType.password:
			return validatePassword(text);
		case ValidationType.email:
			return validateEmail(text);
		case ValidationType.name:
			return validateName(text);
		default:
	}
};

const validateName = (text) => {
	const result = {};

	if (text === null || text.match(/^ *$/) !== null) {
		result.nameNullValidation = true;
	}

	if (!/^([^0-9]*)$/.test(text)) {
		result.nameValidation = true;
	}

	return result;
};

export const validateEmail = (text) => {
	const result = {};

	if (text === null || text.match(/^ *$/) !== null) {
		result.emailNullValidation = true;
	}

	if (!/^\S+@\S+\.\S+$/.test(text)) {
		result.emailValidation = true;
	}

	return result;
};

export const validatePassword = (text) => {
	const result = {};
	if (text === null || text.match(/^ *$/) !== null) {
		result.passwordNullValidation = true;
	}

	if (text.length < 8) {
		result.passwordLengthValidation = true;
	}

	if (!/^(.*\W.*)$/.test(text)) {
		result.passwordSymbolValidation = true;
	}

	if (!/^(.*[a-z].*)$/.test(text)) {
		result.passwordLowercaseValidation = true;
	}

	if (!/^(.*[A-Z].*)$/.test(text)) {
		result.passwordUppercaseValidation = true;
	}

	if (!/^(.*\d.*)$/.test(text)) {
		result.passwordNumberValidation = true;
	}
	return result;
};

export const validateURL = (url) =>
	/^(ftp|http|https):\/\/[^ "]+$/.test(url)

export const validateDate = (date) =>
	dayjs(date, "YYYY/MM/DD").isValid()

