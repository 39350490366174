import React from "react";

const PencilIcon = "/assets/edit.svg";
const BinIcon = "/assets/bin.svg";

function AcadamyCollectionHeader({
	collection,
	onDeleteAction,
	onEditAction
}) {
	return (
		<div className="header_card">
			<div className="header_card__wrapper">
				<span className="text_20" style={{color: "#fff"}}>
					{collection.name}
				</span>
				<button
					className="icon_wrapper"
					onClick={() => onEditAction()}
				>
					<img src={PencilIcon} alt="" />
				</button>
			</div>

			<div
				className="delete_acedamy"
				style={{marginLeft: "auto", padding: "auto", size: "auto"}}
			>
				<button
					className="delete_academy_button"
					style={{padding: "5px"}}
					onClick={() => onDeleteAction()}
				>
					<img src={BinIcon} alt="" />
				</button>
			</div>
		</div>
	);
}

export default AcadamyCollectionHeader;
