import {IAcademyCreateRequest} from "../../IAcademy";
import {ValidationDoc} from "../../IValidation";
import {required} from "../common";

export const academyCreateRequestValidationDoc: ValidationDoc<IAcademyCreateRequest> = {
	name: [
		required("name")
	],
	language: [
		required("language")
	],
	certificate_background: [
		required("certificate_background")
	]
}

