import React, {FC} from "react";
import InputSwitch from "../../components/InputSwitch/InputSwitch";
import {useStyles} from "./style";


interface IProps {
	isCache: boolean,
	onChange: () => void
}

export const LiveCacheToggle: FC<IProps> = ({isCache, onChange}) => {
	const classes = useStyles();
	const infoMessage = "Please use the toggle to flip between versions";

	return (
		<div className={classes.liveCacheToggle} title={infoMessage}>
			<InputSwitch
				labelPlacement="start"
				value={isCache}
				onChange={() => onChange()}
				text={"Showing the " + (isCache ? "revised" : "live") + " version"}
			/>
		</div>
	);
}

export default LiveCacheToggle;
