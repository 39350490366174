import React, {FC, useState, useRef, useEffect} from "react";
import {Done} from "@mui/icons-material";
import KeyboardArrowUp from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import coreService from "../../../../../../services/core.service";
import useOnClickOutside from "../../../../../../hooks/useOnClickOutside";
import {IAcademy} from "../../../../../../types/IAcademy";
import {IUser} from "../../../../../../types/IUser";

interface IProps {
	className: string,
    academy: IAcademy,
    onChange: (e: IAcademy) => void
}

export const ManagersDropDown: FC<IProps> = ({className, academy, onChange}) => {
	const [managersDropDown, setManagersDropDown] = useState(false);
	const [managerList, setManagers] = useState<IUser[]>([]);
	const ref = useRef(null);

	useOnClickOutside(ref, () => setManagersDropDown(false));

	const dropdownStyle = {
		overflow: managersDropDown ? "" : "none"
	};

	const managersToggleStyle = {
		top: managersDropDown ? "0" : "-500vh",
		opacity: managersDropDown ? "1" : "0"
	};


	useEffect(() => {
		setManagers([
			...academy.managers,
			...academy.available_managers
		]);
	}, [academy]);

	const update = (manager: IUser): void => {
		const managersIndex = academy.managers.findIndex(
			(m) => m.slug === manager.slug
		);
		// If it is in the managers list, remove it
		if (managersIndex > -1) {
			academy.managers.splice(managersIndex, 1);
		} else {
			// If it is not in the managers list, add it
			academy.managers.push(manager);
		}
		const managers = academy.managers.map((m) => m.slug).join();

		coreService.updateAcademy(academy.slug, {managers}, {}).then((res) => {
			onChange(res.data);
		});
	};

	return (
		<div className={(className ?? "") + " managers_drop_down"} style={dropdownStyle} ref={ref}>
			<div
				className="current_wrapper"
				onClick={() => setManagersDropDown(!managersDropDown)}
			>
				<div className="active_managers">
					{academy.managers.map((manager, i) => {
						const initials = manager.name.split("")[0];
						return (
							<div key={i} className="active_manager">
								{initials}
							</div>
						);
					})}
				</div>
				<KeyboardArrowDownIcon />
			</div>

			<div className="managers_toggle" style={managersToggleStyle}>
				<div
					className="wrapper"
					onClick={() => setManagersDropDown(!managersDropDown)}
				>
					<span>Managers</span>
					<KeyboardArrowUp />
				</div>
				<ul className="managers_list">
					{managerList.map((manager, i) => {
						return (
							<li
								key={i}
								onClick={() => {
									update(manager);
								}}
							>
								<span className="full_name">{`${manager.name}`}</span>
								<Done
									className="done_icon"
									style={
										academy.managers.find((x) => {
											return x.slug === manager.slug;
										})
											? {opacity: "1", color: "#6AC7D0", fontSize: "16px"}
											: {opacity: "0"}
									}
								/>
							</li>
						);
					})}
				</ul>
			</div>
		</div>
	);
}

export default ManagersDropDown;
