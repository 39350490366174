import React, {FC, ReactNode} from "react";

interface IProps {
    children: ReactNode,
    className?: string
}

export const Footer: FC<IProps> = ({children, className}) => (
	<>
		<div className="pagebase_footerspacer"/>
		<footer className={"pagebase_footer " + (className ?? "")}>
			{children}
		</footer>
	</>
);
