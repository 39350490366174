import React, {FC} from "react";
import Login from "./routes/Login/Login";
import {HashRouter as Router, Routes, Route} from "react-router-dom";
import Dashboard from "./pages/Dashboard/Dashboard";
import Curriculum from "./pages/Curriculum/Curriculum";
import Settings from "./pages/Settings/Settings";
import Webhook from "./pages/Webhook/Webhook";
import Users from "./pages/Users/Users";
import Module from "./pages/Module/Module";
import {QueryClient, QueryClientProvider} from "@tanstack/react-query";
import {ReactQueryDevtools} from "@tanstack/react-query-devtools";
import {Notifications} from "./components/PageBase/Notifications/Notifications";
import Webinar from "./pages/Webinar/Webinar";
import {LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";

const client = new QueryClient();

export const App: FC = () => (
	<LocalizationProvider dateAdapter={AdapterDayjs}>
		<Notifications />
		<Router>
			<div className="App">
				<QueryClientProvider client={client}>
					<ReactQueryDevtools initialIsOpen={false} />
					<Routes>
						<Route path="/" element={<Login />} />
						<Route
							path="/dashboard"
							element={<Dashboard />}
						/>
						<Route path="/user" element={<Users />} />
						<Route path="/settings" element={<Settings />} />
						<Route path="/webhook" element={<Webhook />} />
						<Route path="/curriculum/:slug" element={<Curriculum />} />
						<Route path="/module/:academy_slug/:section_slug">
							<Route path=":module_slug" element={<Module/>} />
							<Route path=":module_slug/sessions" element={<Module/>} />
							<Route path=":module_slug/files" element={<Module/>} />
							<Route path="" element={<Module/>} />
						</Route>
						<Route path="/webinar/:academy_slug/:section_slug">
							<Route path=":webinar_slug" element={<Webinar/>} />
							<Route path=":webinar_slug/sessions" element={<Webinar/>} />
							<Route path=":webinar_slug/files" element={<Webinar/>} />
							<Route path="" element={<Webinar/>} />
						</Route>
					</Routes>
				</QueryClientProvider>
			</div>
		</Router>
	</LocalizationProvider>
);

export default App;
