import React, {useState, FC} from "react";
import {Button} from "../../../../components/Common/Button/Button";
import {Footer} from "../../../../components/PageBase/Footer/Footer";
import {NewUserModal} from "../NewUserModal/NewUserModal";

export const UserFooter: FC = () => {
	const [newUserModalOpen, setNewUserModalOpen] = useState(false);

	return (
		<Footer>
			<div className="new_user">
				<Button onClick={() => setNewUserModalOpen(true)}>+ New User</Button>
			</div>

			<NewUserModal open={newUserModalOpen} onClose={() => setNewUserModalOpen(false)} />
		</Footer>
	);
}

export default UserFooter;
