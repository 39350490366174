import {IUserInfoUpdateRequest} from "../../IUser";
import {ValidationDoc} from "../../IValidation";
import {required} from "../common";

export const userInfoUpdateRequestDoc: ValidationDoc<IUserInfoUpdateRequest> = {
	first_name: [
		required("first_name")
	],
	last_name: [
		required("last_name")
	],
	role: [
		required("role")
	]
}

