import React, {FC, ReactNode} from "react";
import NavSidebar from "../../components/NavSidebar/NavSidebar";

interface IProps {
    children: ReactNode
}

export const PageBase: FC<IProps> = ({children}) => (
	<div className="pagebase">
		<NavSidebar />
		<section className="pagebase_container" style={{background: "url(assets/background-hero.svg) no-repeat #F1F2F2"}}>
			{children}
		</section>
	</div>
);
