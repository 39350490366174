import {useEffect, useState} from "react";
import {ValidationDoc, ValidationResult} from "../types/IValidation";

//input: the validation doc detailing tests and error response
//output: Array [result object, run validate function, boolean status hasErrors]
export const useValidate = <T,>(doc: ValidationDoc<T>, data: T): [ValidationResult<T>, (propFilter?: keyof T) => boolean, boolean, () => void] => {
	const [validationResult, setValidationResult] = useState<ValidationResult<T>>({});
	const [hasErrors, setHasErrors] = useState<boolean>(false);

	const runValidate = (propFilter?: keyof T): boolean => {
		const result: ValidationResult<T> = {};
		const keys = Object.keys(doc);
		keys.filter(prop => !propFilter || (propFilter === prop))
			.forEach((prop) => {
				for(const test of (doc[prop as keyof T] ?? [])){
					if(result[prop as keyof T] === undefined && !test.validate(data))
						result[prop as keyof T] = test.errorMessage;
				}
			});

		setValidationResult(prev => {
			if(propFilter){
				delete prev[propFilter];
			}
			return {...prev, ...result};
		});

		return Object.keys(result).length === 0;
	};

	const resetErrors = (): void => {
		setHasErrors(false);
		setValidationResult({});
	};

	useEffect(() => {
		setHasErrors(Object.keys(validationResult).length > 0);
	}, [validationResult]);

	return [validationResult, runValidate, hasErrors, resetErrors];
};