import React, {useContext} from "react";
import {NotifyContext} from "../../../context/NotifyContext";
import {NotificationMessage} from "./NotificationMessage/NotificationMessage";
export const ProgressBar = require("progressbar.js");

const style:any = {
	notifications: {
		zIndex: 99,
		position: "fixed",
		right: 20,
		top: 0,
		width: 0.1,
		height: "calc(100% - 60px)"
	}
};

export const Notifications: React.FC = () => {
	const {notifyState} = useContext(NotifyContext);

	return (
		<div style={style.notifications}>
			{notifyState.map((n, i) => <NotificationMessage key={"notification-" + i} {...n}/>)}
		</div>
	);
};