import {IAcademyCreateRequest, IAcademyEditRequest} from "../../IAcademy";
import {ValidationDoc} from "../../IValidation";
import {required} from "../common";

export const academyEditRequestValidationDoc: ValidationDoc<IAcademyEditRequest> = {
	name: [
		required("name")
	]
}

