
export const dateEqual = function(d1: Date, d2: Date): boolean {
	if(!d1 || !d2){
		return !d1 && !d2;
	}

	return d1.getTime() === d2.getTime();
};

function isObject(object: any): boolean {
	return object != null && typeof object === "object";
}

function isDate(d: any): boolean {
	return d instanceof Date;
}

export function deepEqual(object1: any, object2: any): boolean {
	if(!object1 || !object2){
		return object1 === object2;
	}

	const keys1 = Object.keys(object1);
	const keys2 = Object.keys(object2);

	if (keys1.length !== keys2.length) {
		return false;
	}

	for (const key of keys1) {
		const val1 = object1[key];
		const val2 = object2[key];
		const areDates = isDate(val1) && isDate(val2);
		const areObjects = isObject(val1) && isObject(val2);
		if (
			(areDates && !dateEqual(val1, val2)) ||
        (areObjects && !deepEqual(val1, val2)) ||
        (!areObjects && val1 !== val2)
		) {
			return false;
		}
	}

	return true;
}