import React, {useState, useEffect, FC, ReactElement, useContext} from "react";
import TextEditor from "../../Curriculum/components/TextEditor/TextEditor";
import {useNavigate, useParams} from "react-router-dom";
import {IModule, IModuleEditRequest} from "../../../types/IModule";
import {TextInput} from "../../../components/form/textInput/textInput";
import {useValidate} from "../../../hooks/useValidate";
import {moduleEditRequestValidationDoc} from "../../../types/validationDocs/module/moduleEditRequestValidationDoc";
import {moduleToEditModuleRequest} from "../../../types/mappers/module";
import coreService from "../../../services/core.service";
import {Severity} from "../../../types/INotify";
import {NotifyContext} from "../../../context/NotifyContext";
import {ModuleEditRequestTemplate} from "../../../types/templates/TModule";
import {Button} from "../../../components/Common/Button/Button";
import SaveIcon from "@mui/icons-material/Save";
import PublishIcon from "@mui/icons-material/Publish";
import CloseIcon from "@mui/icons-material/Close";
import {publishedOptions} from "../../../constants";
import Select from "../../../components/Select/Select";
import InputCheckbox from "../../../components/InputCheckbox/InputCheckbox";
import LiveCacheToggle from "../../../features/LiveCacheToggle/LiveCacheToggle";
import {ModuleFileUpload} from "../../../components/ModuleFileUpload/ModuleFileUpload";
import {IAcademy} from "../../../types/IAcademy";
import ModuleFormHeader from "./ModuleHeader/WebinarHeader";
import InfoIcon from "@mui/icons-material/Info";
import FilePresentIcon from "@mui/icons-material/FilePresent";
import {Tab, Tabs} from "../../../components/Common/Tabs/Tabs";

const BackgroundHero = "/assets/background-hero.svg";

interface IProps {
	academy?: IAcademy,
	sectionSlug: string,
	moduleSlug?: string
}

type tab = "general" | "files";

const tabs: Tab<tab>[] = [
	{value: "general", label: "General", icon: <InfoIcon/>},
	{value: "files", label: "Files", icon: <FilePresentIcon/>}
]

const initTab = (): tab => {
	if(window.location.href.includes("files")) return "files"
	return "general";
}

export const ModuleForm: FC<IProps> = ({academy, sectionSlug, moduleSlug}) => {
	const [module, setModule] = useState<IModule>();
	const edit = !!module?.slug;
	const [tabSelected, setTabSelected] = useState<tab>(initTab());
	const [cacheToggle, setCacheToggle] = useState(true);
	const [formData, setFormData] = useState<IModuleEditRequest>(module ? moduleToEditModuleRequest(module) : ModuleEditRequestTemplate);
	const navigate = useNavigate();
	const [validationResult, runValidate, hasErrors, clearValidation] = useValidate<IModuleEditRequest>(moduleEditRequestValidationDoc, formData);
	const {notify} = useContext(NotifyContext);
	const {academy_slug} = useParams();

	useEffect(() => {
		moduleSlug && moduleSlug !== "null" && coreService.getModule(moduleSlug, {}, {cache: cacheToggle}).then((res) => {
			setModule(res.data);
		});
	}, [moduleSlug, cacheToggle]);

	useEffect(() => {
		setFormData(module ? moduleToEditModuleRequest(module) : ModuleEditRequestTemplate);
		clearValidation();
	}, [module]);

	const handleNavigateBack = (): void => {
		navigate(`/curriculum/${academy_slug}`);
	};

	const handleNewModule = async (publish: boolean): Promise<void> => {
		if (!runValidate()) return;
		const submissionData = {...formData, status: publish ? "published" : "draft", section: sectionSlug};
		const result = await coreService.createModule(submissionData);
		const success = result.status >= 200 && result.status < 300;
		const message = success ? `Successfully added ${formData.name}` : "Something went wrong";
		notify(message, "Module", success ? Severity.success : Severity.error);
		if(!success) return;
		if(publish){
			setModule(result.data);
			setTabSelected("files")
			navigate(`/module/${academy_slug}/${sectionSlug}/${result.data.slug}/files`, {replace: false});
		}
		else
			handleNavigateBack();
	};

	const handleEditModule = async (): Promise<void> => {
		if(!runValidate() || !module?.slug) return;
		const payload = {...formData};
		const result = await coreService.updateModule(module.slug, payload);
		const success = result.status >= 200 && result.status < 300;
		const message = success ? `Successfully updated ${payload.name}` : "Something went wrong";
		notify(message, "Module", success ? Severity.success : Severity.error);
		if(success) handleNavigateBack();
	};

	const handleSetTab = (e: tab): void => {
		if((e === "files") && !edit){
			notify("Please save before adding files", "New Module", Severity.neutral);
		}
		else setTabSelected(e);
	}

	const inputText = (label: string, placeholder: string, prop: keyof IModuleEditRequest, style: Object = {}): ReactElement =>
		<TextInput
			label={label}
			placeholder={placeholder}
			style={style}
			error={validationResult[prop]}
			value={formData[prop] as string}
			onChange={e => setFormData(prev => ({...prev, [prop]: e.target.value}))}
			onValidate={() => runValidate(prop)}
		/>;

	const statusText = (): string => {
		if(module?.is_cache && formData.cache)
			return "Your revision will be updated with the changes given"
		if(module?.is_cache && !formData.cache)
			return "Your revision will be deleted and the current changes will be made permanent"
		if(!module?.is_cache && formData.cache)
			return "A revision will be created and changes will not be deployed"
		if(!module?.is_cache && !formData.cache){
			if(formData.status === "draft"){
				return "Changes will be made " + (module?.status !== formData.status ? "and the module will be drafted" : "to the draft")
			}
			return "Changes will be published";
		}
		return "";
	}

	if (!academy || !formData) return <></>;
	return (
		<div className="new_module" style={{background: `url(${BackgroundHero}) no-repeat`}}>
			<div className="curriculum__new_module">
				<ModuleFormHeader academy={academy}/>
				{edit && tabSelected === "general"&& <div className={"curriculum_status"}>
					<Select
						name="Status"
						error={validationResult["status"]}
						onValidate={() => runValidate("status")}
						options={publishedOptions}
						style={{width: 250}}
						value={formData.status}
						onSelect={(option) => setFormData(prev => ({...prev, status: option.value}))}
					/>
				</div>}
				<br />
				<Tabs<tab>
					tabs={tabs}
					value={tabSelected}
					onChange={e => handleSetTab(e)}
				/>
				{tabSelected === "general" && <>
					<div className="curriculum__new_module__form">
						<div className="curriculum__new_module__form__top_container">
							<div>
								{inputText("", "Module title goes here", "name", {fontWeight: 600, width: 300, fontSize: 24, background: "white", padding: 0, borderBottom: "1px dashed #d1d1d1", borderRadius: 0})}
							</div>
							<div>
								{module?.is_cache && <LiveCacheToggle isCache={cacheToggle} onChange={() => setCacheToggle(prev => !prev)} />}
							</div>
						</div>
						{inputText("", "Subtitle goes here", "subtitle", {background: "white", padding: 0, width: 250, borderBottom: "1px dashed #d1d1d1", borderRadius: 0})}
						{inputText("Video Link", "", "video_url")}
						{inputText("H5P Link", "", "h5p_url")}

						<label>Content</label>
						<TextEditor onChange={e => setFormData(prev => ({...prev, content: e}))} value={formData.content} />
					</div>

					{edit && <div className="curriculum_stage_change_toggle">
						<div className="curriculum_stage_change_toggle_container">
							<InputCheckbox
								preText={(module.is_cache ? "Update" : "Create") + " Revision"}
								value={formData.cache}
								onChange={e => setFormData(prev => ({...prev, cache: e}))}
							/>
							<p className="curriculum_status_text">{statusText()}</p>
						</div>
					</div>}

					<div className="primary_button_wrapper">
						<Button icon={<CloseIcon />} onClick={() => handleNavigateBack()}>Cancel</Button>
						{edit && <Button icon={<SaveIcon/>} disabled={hasErrors} onClick={() => handleEditModule()}>Save</Button>}
						{!edit && <Button icon={<SaveIcon/>} disabled={hasErrors} onClick={() => handleNewModule(false)}>Save Draft</Button>}
						{!edit && <Button icon={<PublishIcon/>} disabled={hasErrors} onClick={() => handleNewModule(true)}>Save & Publish</Button>}
					</div>
					<br /><br />
				</>}

				{tabSelected === "files" && <ModuleFileUpload remoteFiles={module?.files ?? []} moduleSlug={module?.slug ?? ""}/>}
			</div>
		</div>
	);
};

export default ModuleForm;
