import {IWebinarEditRequest, IWebinarSessionNewRequest} from "../IWebinar";

export const WebinarEditRequestTemplate: IWebinarEditRequest = ({
	name: "",
	subtitle: "",
	status: "draft",
	content: "",
	cache: false,
	type: "webinar",
	instructors: []
});


export const WebinarSessionNewRequestTemplate = (webinarSlug: string): IWebinarSessionNewRequest => ({
	webinar: webinarSlug,
	date: new Date().toDateString(),
	status: "published",
	timezone: "America/Cancun"
});