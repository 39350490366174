import React, {FC, useEffect, useRef} from "react";
import {useStyles} from "./style";
import CheckIcon from "@mui/icons-material/Check";

interface IProps {
	className?: string,
    error?: string,
    label?: string,
	onValidate?: () => void,
	style?: Object,
	value: boolean,
	onChange: (e: boolean) => void,
	text?: string,
	preText?: string
}

export const InputCheckbox: FC<IProps> = ({className, error, label, onValidate, onChange, ...props}) => {
	const classes = useStyles();
	const valid = error === undefined;
	const validationRequired = useRef(false);

	const handleOnChange = (e: boolean): void => {
		onChange(e);
		validationRequired.current = true;
	}

	useEffect(() => {
		if(props.value && validationRequired.current) {
			onValidate?.();
			validationRequired.current = false;
		}
	}, [props.value])

	return (
		<div id="input-checkbox" className={classes.inputCheckbox}>
			{label && <label htmlFor="">{label}</label>}
			<div className={classes.checkContainer}>
				{props.preText && <span className={classes.preText}>{props.preText}</span>}
				<button
					style={{borderColor: valid ? "black" : "red"}}
					type="button"
					className={classes.checkboxBtn}
					onClick={() => handleOnChange(!props.value)}
				>
					{props.value && <CheckIcon
						style={{width: "16px", height: "16px", color: "#6AC7D0"}}
					/>}
				</button>
				{props.text && <span className={classes.postText}>{props.text}</span>}
			</div>
			<div className={classes.errorMessage} style={{maxHeight: error ? 50 : 5}}>
				<p>
					{error ?? " "}
				</p>
			</div>
		</div>
	);
}

export default InputCheckbox;
