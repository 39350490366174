import {createUseStyles} from "react-jss";

export const useStyles = createUseStyles({
	inputSwitch: {
		"& label": {
			margin: 0,
			padding: 0
		},
		width: "100%",
		margin: "20px 0"
	},
	errorMessage: {
		color: "#f30000",
		fontSize: 15,
		boxSizing: "border-box",
		maxHeight: 0,
		transition: "max-height 0.15s ease-out",
		"& p": {
			padding: 0
		}
	},
	switchBtn: {}
})

export const switchStyle = {
	"& .MuiSwitch-switchBase": {
		"&.Mui-checked": {
			color: "#fff",
			"& + .MuiSwitch-track": {
				backgroundColor: "#6bcad2",
				opacity: 1
			}
		}
	},
	"& .MuiSwitch-thumb": {
		border: "1px solid #d1d1d1",
		boxSizing: "border-box",
		backgroundColor: "white"
	},
	"& .MuiSwitch-track": {
		backgroundColor: "#21273d30",
		opacity: 1
	}
}